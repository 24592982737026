import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

class LogoutButton extends Component {
    state = {
        displayFAQ: false,
        displayLogin: false
    }
    displayFAQ = (e) => {
        e.preventDefault();
        this.setState({
            displayFAQ: true,
        })
    }
    displayLogin = (e) => {
        e.preventDefault();
        localStorage.clear();
        this.setState({
            displayLogin: true,
        })
    }
    render() { 
        if(this.state.displayFAQ) {
            return <Redirect to="/faq" />
        }
        if(this.state.displayLogin) {
            return <Redirect to="/" />
        }
        return ( 
            <React.Fragment>
                <p className="regular-font-size font-bold" onClick={this.displayFAQ.bind(this)}>
                    {"FAQ"}  
                </p>
                <p className="regular-font-size font-bold" onClick={this.displayLogin.bind(this)}>
                    {"Log Out "}  
                    <span>
                        <img src={process.env.PUBLIC_URL + '/images/dashboard-logout.png'} alt="logout"/>
                    </span>
                </p>
            </React.Fragment>
        );
    }
}
 
export default LogoutButton;