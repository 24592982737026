import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom'

import AdminControl from './admin/admin-login';
import AdminDashboard from './admin/adminControl';
import SignInAccountNumber from './signInAccountNumber';
import Signup from './signup';
import Login from './login';
import Dashboard from './dashboard';
import PayNow from './paynow';
import TermsAndConditions from './termsAndConditions';
import PrivacyPolicy from './privacyPolicy';
import PaymentResult from './paymentResult';
import FAQ from './faq';
import PopupMessage from '../components/popupMessage';
import Maintenance from './maintenance';
import PaymentArrangementInfo from './PaymentArrangement/info';
import UseDeposit from './PaymentArrangement/useDeposit';
import PayToday from './PaymentArrangement/payToday';
import ApplyForPaymentArrangement from './PaymentArrangement/apply';
import PrintSignSend from './PaymentArrangement/printSignSend';
import PaymentSuccess from './PaymentArrangement/paymentSuccess';
import CaseForm from './PaymentArrangement/caseForm';

class Body extends Component {
    componentDidMount(){
        // localStorage.clear()
    }
    render() { 
        return (  
            <BrowserRouter>
                <PopupMessage />
                <React.Fragment>
                    <Route exact path="/" component={Login} />
                        <Route exact path="/sign-up" component={Signup} />
                        <Route exact path="/dashboard" component={Dashboard} />
                        <Route exact path="/quickpay" component={PayNow} />
                        <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
                        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                        <Route exact path="/gpaadmin" component={AdminControl} />
                        <Route exact path="/admin-dashboard" component={AdminDashboard} />
                        <Route exact path="/faq" component={FAQ} />
                        <Route path="/payment-result" component={PaymentResult} />
                        <Route path="/maintenance" component={Maintenance} />
                        <Route exact path="/payment-arrangement" component={PaymentArrangementInfo} />
                        <Route exact path="/payment-arrangement/use-deposit" component={UseDeposit} />
                        <Route exact path="/payment-arrangement/pay-today" component={PayToday} />
                        <Route exact path="/payment-arrangement/apply-for-payment-arrangement" component={ApplyForPaymentArrangement} />
                        <Route exact path="/payment-arrangement/print-sign-send" component={PrintSignSend} />
                        <Route exact path="/payment-arrangement/payment/thank-you/:account_id" component={PaymentSuccess} />
                        <Route exact path="/payment-arrangement/case-form" component={CaseForm} />
                </React.Fragment>
            </BrowserRouter>
        );
    }
}
 
export default Body;