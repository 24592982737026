import React, { Component } from 'react';
import LogoutButton from './logoutButton';

class News extends Component {
    render() { 
        return (  
        <React.Fragment>
            <div className="row pb-4">
                <div className="col-lg-8">
                    <h3 className="primary-font-color">News</h3>
                </div>
                <div className="col-lg-4">
                    <LogoutButton />
                </div>
            </div>
            <div className="row pt-2">
                <div className="col-lg-12 survey_wrap">
                    <div className="featured_image">
                        <img className="account_logo" src={process.env.PUBLIC_URL + '/images/survey-featured-image.png'} alt="featured"></img>
                    </div>
                    <div className="content p-3">
                        <h5 className="primary-font-color">Loremm ipsum dolor sit amet</h5>
                        <p>Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                        <a href="/" className="secondary-font-color">Read More</a>
                    </div>
                </div>
                <div className="col-lg-12 survey_wrap">
                    <div className="featured_image">
                        <img className="account_logo" src={process.env.PUBLIC_URL + '/images/survey-featured-image.png'} alt="featured"></img>
                    </div>
                    <div className="content p-3">
                        <h5 className="primary-font-color">Lorem ipsum dolor sit amet</h5>
                        <p>Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                        <a href="/" className="secondary-font-color">Read More</a>
                    </div>
                </div>
                <div className="col-lg-12 survey_wrap">
                    <div className="featured_image">
                        <img className="account_logo" src={process.env.PUBLIC_URL + '/images/survey-featured-image.png'} alt="featured"></img>
                    </div>
                    <div className="content p-3">
                        <h5 className="primary-font-color">Lorem ipsum dolor sit amet</h5>
                        <p>Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                        <a href="/" className="secondary-font-color">Read More</a>
                    </div>
                </div>
                
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <hr />
                </div>
            </div>
        </React.Fragment>
        );
    }
}

export default News;