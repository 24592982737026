import React, { Component } from 'react';

class Footer extends Component {
    render() { 
        return (  
            <footer id="footer-main">
                <div id="footer-top" className="primary-back-color">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="footer-content-title light-font-color">
                                    <h5 className="font-extra-bold">Features</h5>
                                </div>
                                <div className="footer-content-body light-font-color mt-3">
                                    <p>Stay informed and in control of your electric account by registering with PayGPA.</p>
                                </div>
                                <div>
                                    <ul className="fa-ul light-font-color">
                                    
                                    
                                        <li><i className="fa-li fa fa-check"></i>Secure Online Payments</li>
                                        <li><i className="fa-li fa fa-check"></i>View Your Energy Statements</li>
                                        <li><i className="fa-li fa fa-check"></i>View Your Energy Consumption</li>
                                        <li><i className="fa-li fa fa-check"></i>View Your Payment History</li>
                                        <li><i className="fa-li fa fa-check"></i>Update Your Contact Information</li>
                                        <li><i className="fa-li fa fa-check"></i>Go Paperless</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="footer-content-title light-font-color">
                                    <h5 className="font-extra-bold">Help & Support</h5>
                                </div>
                                <div className="footer-content-body light-font-color mt-3">
                                    <p>Having trouble? Our Customer Service representatives are eager to assist you.</p>
                                    
                                </div>
                                <div id="contacts" className="light-font-color no-line-height mt-4">
                                    <p><strong>Customer Service Division:</strong></p><br/>
                                    <p className="ml-5 mb-4 mt-2">Phone: (671) 647-5787/8/9</p><br/>
                                    <p><strong>For account assistance:</strong></p><br/>
                                    <p className="ml-5 mb-4 mt-2">Email: customersfirst@gpagwa.com</p><br/>
                                    <p><strong>For PayGPA assistance:</strong></p><br/>
                                    <p className="ml-5 mb-4 mt-2">Email: paygpainfo@gpagwa.com</p><br/>
                                    <p><strong>For billing inquiries:</strong></p><br/>
                                    <p className="ml-5 mb-4 mt-2">Email: billinquiries@gpagwa.com</p><br/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="footer-bottom-content">
                                <div className="dark-font-color footer-bottom-text">
                                    <p>All Rights Reserved © 2020 Guam Power Authority</p>
                                </div>
                                <div className="dark-font-color footer-bottom-icons">
                                    <a href="https://www.guampowerauthority.com/" target="_blank"><img src={process.env.PUBLIC_URL + '/images/guam-logo-small.png'} alt="guam-logo-small"></img></a>
                                    <a href="http://guamwaterworks.org/" target="_blank"><img src={process.env.PUBLIC_URL + '/images/guam-waterworks.png'} alt="guam-waterworks"></img></a>
                                    <a href="https://www.myenergyguam.com/" target="_blank"><img src={process.env.PUBLIC_URL + '/images/guam-ccu.png'} alt="guam-ccu"></img></a>
                                    <a href="http://guamccu.org/" target="_blank"><img src={process.env.PUBLIC_URL + '/images/guam-me-energy.png'} alt="guam-me-energy"></img></a>
                                    <a href="https://www.guamsolidwasteauthority.com/" target="_blank"><img src={process.env.PUBLIC_URL + '/images/GSWA.PNG'} alt="GSWA.png" width="42"></img></a>   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
 
export default Footer;