import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { 
    searchString
} from '../actions/dashboardActions';
import $ from 'jquery';
import { Redirect } from 'react-router-dom';
class PaymentSuccess extends Component {
    state = { 
        displayLogin: false
    }
    showLogin = () => {
        this.setState({
            displayLogin: true
        })
    }
    getFieldValue = (str, result) => {
        return this.props.searchString(str, result)
    }
    downloadBillPDF = (e) => {
        e.preventDefault();
        const jsPDF = require('jspdf');
        const result = this.props.result;
        const receiptNum = this.getFieldValue("TRANS. REF.", result);
        var pdf = new jsPDF('p','px','a4');
        var source = $("#receipt")[0];
        pdf.addHTML(source,function() {
            pdf.save('PayGPA_'+receiptNum+'.pdf')
            // const screenWidth = window.screen.width
            // if(screenWidth < 1200){
            //     document.getElementById("btn-my-bills").click();
                
            // }
        });
    }
    getCardType = (code) => {
        if(code === "CCM"){
            return "Mastercard"
        }
        else if(code === "CCV"){
            return "Visa"
        }
        else if(code === "CCD"){
            return "Discover"
        }
        else{
            return "NO CARD FOUND!"
        }
    }
    render() { 
        const result = this.props.result
        let today = new Date();
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        let date = today.getDate()+' '+months[today.getMonth()]+' '+today.getFullYear();
        let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        let dateTime = date+' '+time;
        const priceSplitter = (number) => (number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
        if(this.state.displayLogin) {
            return <Redirect to="/" />
        }
        return ( 
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="text-center">
                            <h3><span style={{color: 'rgb(8, 196, 132)'}}><i class="fas fa-check-circle"></i></span> Approved - Thank you</h3>
                        </div>
                    </div>
                </div>
                <div 
                    id="receipt" 
                    className="pt-5">
                    <div className="row mb-4">
                        <div className="col-lg-12">
                            <div className="bordered">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div>
                                                <label><strong>CARD & AMOUNT:</strong> {this.getCardType(result.payment_source) + " $ " + priceSplitter(parseFloat(Math.round(result.dollar_amount * 100) / 100).toFixed(2)) + " " + result.currency}</label>
                                                <label><strong>CARDHOLDER NAME:</strong> {result.card_holders_name}</label>
                                                <label><strong>CARD NUMBER:</strong> {result.card_number}</label>
                                                <label><strong>DATE/TIME:</strong>{" "+dateTime}</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="left-auto">
                                                <label><strong>REFERENCE #:</strong>{this.getFieldValue("REFERENCE #", result)}</label>
                                                <label><strong>AUTHORIZATION #:</strong>{this.getFieldValue("AUTHOR. #", result)}</label>
                                                <label><strong>PAYMENT ID: </strong>{result.paymentId}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-lg-12">
                            <div className="text-center">
                                <h5>Accounts Paid:</h5>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-lg-12">
                            <div className="bordered p-0">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-lg-6 p-0">
                                            <div className="text-center header-left">
                                                <label><strong>ACCOUNT ID:</strong></label>
                                            </div>
                                            <div className="text-center content-left">
                                                <label>{this.props.accountSummary.accountId}</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 p-0">
                                            <div className="text-center header-right">
                                                <label><strong>AMOUNT:</strong></label>
                                            </div>
                                            <div className="text-center content-right">
                                                {/* <label>{"$ "+parseInt(this.props.accountSummary.amount)}</label> */}
                                                <label>{"$ "+priceSplitter(parseFloat(Math.round(this.props.accountSummary.amount * 100) / 100).toFixed(2))}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="row mb-5">
                    <div className="col-lg-6 text-right">
                        <button id="btn-download" type="submit" class="btn regular-font-size font-bold btn-left" onClick={this.downloadBillPDF.bind(this)}>Save this as PDF file</button>
                    </div>
                    <div className="col-lg-6 text-left">
                        <button id="btn-member-dashboard" type="submit" class="btn regular-font-size font-bold btn-right" onClick={this.showLogin.bind(this)}>Close This Window</button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    dashboard: state.dashboard
});

export default connect(mapStateToProps, { searchString })(PaymentSuccess);