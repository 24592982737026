import React, { Component } from "react";
import Header from "../../partials/header";
import Footer from "../../partials/less-content-footer";
import { connect } from "react-redux";
import {
  saveAccountId,
  savePremiseAddress,
  fetchMultipleAddOpptyRequest,
  fetchMultipleLatestBill,
  saveOrderData,
  generateFpHash,
} from "../../actions/dashboardActions";
import { showMessage } from "../../actions/popupMessageActions";
import {
  Tabs,
  Tab,
  Modal,
  Table,
  Col,
  Container,
  Row,
  Form,
  Spinner,
  Button,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import axios from "axios";
import $ from "jquery";
import _ from "lodash";
import PayNowModal from "../paynowModal";
import NumberFormat from "react-number-format";

import * as moment from "moment";

const dashboardBaseURL = process.env.REACT_APP_LOGIN_SIGNUP_API_URL;

class AdminPhonedIn extends Component {
  constructor() {
    super();
    this.state = {
      step1: true,
      step2: false,
      step3: false,
      step4: false,
      accounts: [],
      defaultId: "",
      values: [],
      buttonDisabled: false,
      showModalDetails: false,
      showModalDetailsHistory: false,
      showModalAdminPaynow: false,
      viewDetails: [],
      paymentHistory: [],
      paymentHistoryComplete: [],
      paymentAccountId: "",
      shoppingCartData: [],
      acctIdsMultple: [],
      subTotalArr: [],
      selectAll: false,
      accountSummary: [],
      subtotal: 0,
      isLoading: false,
      isCheckedAll: false,
      username: localStorage.getItem("adminusername"),
      emailAddress: "",
      paymentFormData: {
        accountId: "",
        x_login: "",
        x_fp_sequence: "",
        x_fp_timestamp: "",
        x_amount: "",
        x_fp_hash: "",
        x_invoice_num: "",
        x_po_num: "",
        x_show_form: "PAYMENT_FORM",
        x_relay_response: "TRUE",
        Checkout: "Pay Now",
      },
      paymentProcessing: false,
    };
  }

  componentWillMount() {
    window.setInterval(() => {
      this.setState({
        isLoading: false,
      });
    }, 50);
  }

  //coded by ian
  formatDate = (date) => {
    return moment(date).format("MM/DD/YYYY");
  };

  saveOrderData = () => {
    const postData = {
      subTotal: this.state.subtotal,
      accountSummary: this.state.accountSummary,
      isHasInvalid: false,
    };
    this.props.saveOrderData(postData);
  };

  updateSubTotal = () => {
    let subtotal = 0;
    const arrAccountSummary = this.state.accountSummary;
    for (let count = 0; count < arrAccountSummary.length; count++) {
      subtotal = subtotal + parseFloat(arrAccountSummary[count].amountToBePaid);
    }

    this.setState(
      {
        subtotal: subtotal,
      },
      () => {
        this.saveOrderData();
      }
    );
  };
  formatAmount = (value) => {
    value = value.replace("$ ", ""); //removes '$ '
    value = value.replace(/\,/g, ""); //removes all ','
    return (value = parseFloat(Math.round(value * 100) / 100).toFixed(2));
  };
  amountToBePaidOnChange = (e) => {
    let id = e.target.id,
      value = e.target.value;
    value = this.formatAmount(value);
    const arrAccountSummary = this.state.accountSummary;
    let selectedIndex = "";
    for (let count = 0; count < arrAccountSummary.length; count++) {
      if (arrAccountSummary[count].accID === id) {
        selectedIndex = count;
      }
    }
    arrAccountSummary[selectedIndex].amountToBePaid = value === "" ? 0 : value;
    this.setState(
      {
        ...this.state,
        accountSummary: arrAccountSummary,
      },
      () => {
        this.updateSubTotal();
      }
    );
  };
  checkBoxOnChange = (e) => {
    const id = e.target.id,
      value = e.target.value;
    const arrAccountSummary = this.state.accountSummary;
    let selectedIndex = "",
      isCheckedAll = false;
    for (let count = 0; count < arrAccountSummary.length; count++) {
      if (arrAccountSummary[count].accID === id) {
        selectedIndex = count;
      }
    }
    if (e.target.checked) {
      arrAccountSummary[selectedIndex].checked = true;
    } else {
      isCheckedAll = false;
      arrAccountSummary[selectedIndex].checked = false;
    }
    this.setState(
      {
        ...this.state,
        isCheckedAll: isCheckedAll,
        accountSummary: arrAccountSummary,
      },
      () => {
        this.updateSubTotal();
      }
    );
  };
  checkAll = (e) => {
    let subtotal = 0;
    const arrAccountSummary = this.state.accountSummary;
    if (e.target.checked) {
      for (let count = 0; count < arrAccountSummary.length; count++) {
        arrAccountSummary[count].checked = true;
        subtotal =
          subtotal + parseFloat(arrAccountSummary[count].amountToBePaid);
      }
      this.setState(
        {
          isCheckedAll: true,
          subtotal: subtotal,
        },
        () => {
          this.saveOrderData();
        }
      );
    } else {
      for (let count = 0; count < arrAccountSummary.length; count++) {
        arrAccountSummary[count].checked = false;
      }
      this.setState(
        {
          isCheckedAll: false,
          subtotal: 0,
        },
        () => {
          this.saveOrderData();
        }
      );
    }
  };
  handleGoStep1 = () => {
    this.setState({
      step1: true,
      step2: false,
      step3: false,
      step4: false,
    });
  };

  handleGoStep2 = () => {
    const postAccIDs = [];
    console.log(this.state.accounts);
    for (let count = 0; count < this.state.accounts.length; count++) {
      postAccIDs.push([
        this.state.accounts[count].arrears.details.AccountID,
        "account prem address",
        "account type",
      ]);
    }
    this.props
      .fetchMultipleLatestBill(postAccIDs, this.state.accounts[0].type)
      .then((result) => {
        this.setState(
          {
            accountSummary: this.props.dashboard.orderData.accountSummary,
            subtotal: this.props.dashboard.orderData.subTotal,
          },
          () => {
            let details = [];
            let accounts = this.state.accounts;
            let acctIdsMultple = this.state.acctIdsMultple;

            for (let x = 0; x < accounts.length; x++) {
              for (let y = 0; y < acctIdsMultple.length; y++) {
                if (accounts[x].accountId == acctIdsMultple[y]) {
                  let objTest = {};
                  details.push();
                }
              }
            }
          }
        );
        this.setState(
          {
            step1: false,
            step2: true,
            step3: false,
            step4: false,
          },
          () => {
            console.log(
              "stopped",
              this.props.dashboard.orderData.accountSummary
            );
          }
        );
      });
  };

  createUI() {
    return this.state.values.map((el, i) => (
      <div key={i} className="top-cont">
        <div className="input-left-search">
          <input
            type="text"
            value={el || ""}
            onChange={this.handleChange.bind(this, i)}
          />
        </div>
        <div className="add-left">
          <img
            onClick={this.removeClick.bind(this, i)}
            src={process.env.PUBLIC_URL + "/images/icon-del.png"}
          />
        </div>
      </div>
    ));
  }

  handleChangeDefault(e) {
    this.setState({ defaultId: e.target.value });
  }

  handleChange(i, event) {
    let values = [...this.state.values];
    values[i] = event.target.value;
    this.setState({ values });
  }

  addClick() {
    this.setState((prevState) => ({ values: [...prevState.values, ""] }));
  }

  removeClick(i) {
    let values = [...this.state.values];
    values.splice(i, 1);
    this.setState({ values });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ accounts: [], buttonDisabled: true });
    let accountIds = [...this.state.values, this.state.defaultId];

    accountIds.map((itemId, i) => {
      axios
        .post(
          dashboardBaseURL + "/api/v1/user-latest-bill-phoned",
          {
            accountId: itemId,
            division: "GPA",
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.data.result.status === "Failed") {
            this.setState(
              {
                buttonDisabled: false,
              },
              () => {
                this.props.showMessage(
                  true,
                  response.data.result.description + ` (${itemId})`
                );
              }
            );
          } else {
            this.setState({
              accounts: [...this.state.accounts, response.data.result],
              buttonDisabled: false,
              emailAddress: response.data.result.email,
            });
          }
        })
        .catch((error) => {
          this.setState({
            buttonDisabled: false,
          });
          this.props.showMessage(true, "Server Error!");
        });
    });
  }

  handleCloseModalIndividual = () => {
    this.setState({
      showModalDetails: false,
      showModalDetailsHistory: false,
      showModalAdminPaynow: false,
    });
  };

  getDataDetails = (e) => {
    let accountId = e.currentTarget.getAttribute("accountId");
    let details = [];
    this.state.accounts.map((item, i) => {
      if (item.arrears.details.AccountID == accountId) {
        details.push(item);
      }
    });
    this.setState({ viewDetails: details, showModalDetails: true });
  };

  getDataDetailsPayment = (e) => {
    let accountId = e.currentTarget.getAttribute("accountId");
    axios
      .post(
        "/api/v1/user-payment-history",
        {
          accountId: accountId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        this.setState({
          paymentHistory: response.data,
          showModalDetailsHistory: true,
          paymentAccountId: accountId,
        });
      })
      .catch((error) => {});
    axios
      .post(
        "/api/v1/user-payment-history-ccb",
        {
          accountId: accountId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        this.setState({ paymentHistoryComplete: response.data.result });
      })
      .catch((error) => {});
  };

  handleCheckAll = (e) => {
    let accounts = this.state.accounts;
    let acctIdsMultple = [];

    this.setState({ selectAll: !this.state.selectAll }, () => {
      if (this.state.selectAll == true) {
        //true or false
        for (let x = 0; x < accounts.length; x++) {
          acctIdsMultple.push(accounts[x].arrears.details.AccountID);
        }
        this.setState({
          acctIdsMultple,
        });
      } else {
        this.setState({
          acctIdsMultple,
        });
      }
    });
  };

  checkInput = (item, index) => {
    console.log("itemitemitemitemitem", this.state.accounts);
    if (this.state.acctIdsMultple.includes(item.arrears.details.AccountID)) {
      //if true, it is in checked users -> user will uncheck
      let copyOfacctIdsMultple = this.state.acctIdsMultple;
      copyOfacctIdsMultple.splice(
        copyOfacctIdsMultple.indexOf(item.arrears.details.AccountID),
        1
      );
      this.setState({
        //remove on checked list and disable select all mode
        selectAll: false,
        acctIdsMultple: copyOfacctIdsMultple,
      });
    } else {
      let accounts = this.state.accounts;
      let acctIdsMultple = this.state.acctIdsMultple;
      acctIdsMultple.push(item.arrears.details.AccountID);
      this.setState({
        selectAll: false,
        acctIdsMultple: acctIdsMultple,
      });
    }

    // FINAL CHECKER IF ALL ITEMS ARE SELECTED
    if (this.state.acctIdsMultple.length == this.state.accounts.length) {
      let accounts = this.state.accounts;
      let acctIdsMultple = [];
      for (let x = 0; x < accounts.length; x++) {
        acctIdsMultple.push(item.arrears.details.AccountID);
      }
      this.setState({
        selectAll: true,
        acctIdsMultple,
      });
    }
  };

  computeSubtotal = (i, event) => {
    let subTotalArr = [...this.state.subTotalArr];
    subTotalArr[i] = event.target.value;
    this.setState(
      {
        subTotalArr,
      },
      () => {
        this.updateTotal();
      }
    );
  };

  updateTotal = () => {
    let subtotal = 0;
    let subTotalArr = this.state.subTotalArr;

    for (let count = 0; count < subTotalArr.length; count++) {
      subtotal += parseFloat(subTotalArr[count]);
    }
    this.setState({ subtotal: subtotal });
  };
  payOnClick = (item) => {
    // console.log(item.amountToBePaid)
    if (item.amountToBePaid != "" || item.amountToBePaid != 0) {
      this.setState(
        {
          paymentProcessing: true,
        },
        () => {
          this.props.generateFpHash(item).then((response) => {
            // console.log("response",response)
            this.setState(
              {
                ...this.state,
                paymentFormData: {
                  ...this.state.paymentFormData,
                  accountId: item.accID,
                  x_login: response.login,
                  x_fp_sequence: response.fp_sequence,
                  x_fp_timestamp: response.fp_timestamp,
                  x_amount: item.amountToBePaid,
                  x_fp_hash: response.fp_hash,
                  x_invoice_num: response.fp_sequence,
                  x_po_num: item.fullName,
                  x_show_form: "PAYMENT_FORM",
                  x_relay_response: "TRUE",
                  Checkout: "Pay Now",
                },
              },
              () => {
                document.getElementById("payment-form-" + item.accID).submit();
                // console.log("this.state.paymentFormData",this.state.paymentFormData)
              }
            );
          });
        }
      );
    } else {
      this.props.showMessage(true, "Please enter an amount!");
    }
  };
  // <form id={"payment-form-"+item.accID} action="https://checkout.globalgatewaye4.firstdata.com/payment" method="post">
  paymentForm = (item, type) => {
    return (
      // <form id={"payment-form-"+item.accID} action="https://demo.globalgatewaye4.firstdata.com/payment" method="post">
      <form
        id={"payment-form-" + item.accID}
        action="https://checkout.globalgatewaye4.firstdata.com/payment"
        method="post"
      >
        <input
          name="x_login"
          value={this.state.paymentFormData.x_login}
          type="hidden"
        />
        <input
          name="x_fp_sequence"
          value={this.state.paymentFormData.x_fp_sequence}
          type="hidden"
        />
        <input
          name="x_fp_timestamp"
          value={this.state.paymentFormData.x_fp_timestamp}
          type="hidden"
        />
        <input
          name="x_amount"
          value={this.state.paymentFormData.x_amount}
          type="hidden"
        />
        <input
          name="x_fp_hash"
          value={this.state.paymentFormData.x_fp_hash}
          type="hidden"
        />
        <input
          name="x_invoice_num"
          value={this.state.paymentFormData.x_invoice_num}
          type="hidden"
        />
        <input
          name="x_po_num"
          value={this.state.paymentFormData.x_po_num}
          type="hidden"
        />
        <input
          name="x_show_form"
          value={this.state.paymentFormData.x_show_form}
          type="hidden"
        />
        <input
          name="x_relay_response"
          value={this.state.paymentFormData.x_relay_response}
          type="hidden"
        />
        <input
          type="button"
          name="Checkout"
          style={{ margin: "0" }}
          value={type}
          className="btn btn-primary font-bold mav-pay-btn"
          onClick={() => {
            this.payOnClick(item);
          }}
          disabled={this.state.paymentProcessing}
        />
      </form>
    );
  };
  render() {
    const columns = [
      {
        dataField: "payment_date",
        text: "Pay Date",
      },
      {
        dataField: "username",
        text: "UserID",
      },
      {
        dataField: "receipt_num",
        text: "Receipt No.",
      },
      {
        dataField: "transaction_tag",
        text: "CC Trans No.",
      },
      {
        dataField: "status",
        text: "Status",
      },
      {
        dataField: "amount",
        text: "Amount",
      },
    ];
    return (
      <React.Fragment>
        <Modal
          show={this.state.showModalDetails}
          onHide={this.handleCloseModalIndividual}
          size="l"
          aria-labelledby="contained-modal-title-vcenter"
          id="modal-for-individual-reports"
          backdrop="static"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h5>Account Details</h5>
            <div class="details-summary-cont">
              <Table>
                <tbody>
                  {this.state.viewDetails.map((item, i) => (
                    <tr key={i}>
                      <tr>
                        <td colSpan="3" className="td-style-first">
                          Billing Date:
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{this.formatDate(item.paymentDetails.billDate)}</td>
                      </tr>
                      <tr>
                        <td colSpan="3" className="td-style-first">
                          Account No:
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{item.arrears.details.AccountID}</td>
                      </tr>
                      <tr>
                        <td></td>
                      </tr>
                      <tr>
                        <td colSpan="3" className="td-style-first">
                          Last Payment Date:
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{this.formatDate(item.paymentDetails.billDate)}</td>
                      </tr>
                      <tr>
                        <td colSpan="3" className="td-style-first">
                          Last Payment Amount:
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{item.arrears.details.CurrentBalance}</td>
                      </tr>
                      <tr>
                        <td></td>
                      </tr>
                      <tr>
                        <td colSpan="3" className="td-style-first">
                          Current Billing Amount:
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{item.arrears.details.CurrentBalance}</td>
                      </tr>
                      <tr>
                        <td colSpan="3" className="td-style-first">
                          Total Due:
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{item.arrears.details.CurrentBalance}</td>
                      </tr>
                      <tr>
                        <td colSpan="3" className="td-style-first">
                          Due Date:
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{this.formatDate(item.paymentDetails.dueDate)}</td>
                      </tr>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>

            <div className="btn-submit-change-ok">
              <button
                type="submit"
                onClick={this.handleCloseModalIndividual}
                className="btn btn-change-ok font-bold btn-primary"
              >
                Close
              </button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showModalDetailsHistory}
          onHide={this.handleCloseModalIndividual}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          id="modal-for-phonedin-payment"
          backdrop="static"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h5>Payment History</h5>
            <p>
              Payment History for Account Number: {this.state.paymentAccountId}{" "}
            </p>
            <Tabs defaultActiveKey="web" id="uncontrolled-tab-example">
              <Tab eventKey="web" title="Web">
                <BootstrapTable
                  bootstrap4={true}
                  keyField="id"
                  data={this.state.paymentHistory}
                  columns={columns}
                  hover
                  condensed
                  wrapperClasses="table-responsive"
                />
              </Tab>
              <Tab eventKey="web-complete" title="Complete">
                <table
                  id="payment-history-complete"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                      <th scope="col" className="text-center">
                        Payment Date
                      </th>
                      <th scope="col" className="text-center">
                        Description
                      </th>
                      <th scope="col" className="text-center">
                        Payment Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(this.state.paymentHistoryComplete, (item, index) => {
                      return index < 18 ? (
                        <tr key={index}>
                          <td>{this.formatDate(item.ArrearsDate)}</td>
                          <td>
                            {item.FinancialTransactionType === "PS"
                              ? "Payment"
                              : "Payment Adjustment"}
                          </td>
                          <td>${item.TotalAmount}</td>
                        </tr>
                      ) : (
                        ""
                      );
                    })}
                  </tbody>
                </table>
              </Tab>
            </Tabs>

            <div className="btn-submit-change-ok">
              <button
                type="submit"
                onClick={this.handleCloseModalIndividual}
                className="btn btn-change-ok font-bold btn-primary"
              >
                Close
              </button>
            </div>
          </Modal.Body>
        </Modal>

        <div id="admin-phonedin-container">
          <Container fluid={true}>
            <Row>
              <Col>
                {this.state.step1 ? (
                  <div id="step1-cont">
                    <h5>Phoned-In Bill Payment</h5>
                    <p>Account Search Filter</p>
                    <div className="phonedin-search-cont">
                      <div className="input-cont-left">
                        <label>Search By:</label>
                        <div className="">
                          <h6>Account Number:</h6>
                        </div>
                      </div>
                      <div className="input-cont-right">
                        <br />
                        <input
                          type="text"
                          className="defaultInput"
                          onChange={this.handleChangeDefault.bind(this)}
                        />
                        {this.createUI()}
                      </div>
                      <div className="button-cont-right">
                        {this.state.buttonDisabled ? (
                          <button
                            type="submit"
                            disabled
                            className="btn btn-change-ok font-bold btn-primary"
                          >
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            Loading...
                          </button>
                        ) : (
                          <button
                            type="submit"
                            onClick={this.handleSubmit.bind(this)}
                            className="btn btn-change-ok font-bold btn-primary"
                          >
                            Search
                          </button>
                        )}
                      </div>
                    </div>
                    {this.state.accounts.length > 0 ? (
                      <div className="table-summary-cont">
                        <p>Please click "Next" to proceed.</p>
                        <Table className="table table-responsive-sm">
                          <thead>
                            <tr>
                              <th colSpan="7">Account Summary</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="with-style">
                              <td className="with-outline">Account No.</td>
                              <td className="with-outline">Service Location</td>
                              <td className="with-outline">Balance Due</td>
                              <td className="with-outline">Customer Name</td>
                              {this.state.accounts[0].type != "prepaid" ? (
                                <td className="with-outline">Details</td>
                              ) : null}
                              <td className="with-outline">Payment History</td>
                            </tr>
                            {this.state.accounts.map((item, i) => (
                              <tr key={i}>
                                <td className="with-outline">
                                  {item.arrears.details.AccountID}
                                </td>
                                <td className="with-outline">
                                  {/* {item.arrears.details.PremiseInfo} */}
                                  <div className="service-location">
                                    <OverlayTrigger
                                      trigger="hover"
                                      placement="top"
                                      overlay={
                                        <Popover id="popover-contained">
                                          {item.arrears.details.PremiseInfo}
                                        </Popover>
                                      }
                                    >
                                      <p className="m-0">
                                        {item.arrears.details.PremiseInfo.substr(
                                          0,
                                          25
                                        ) + "..."}
                                      </p>
                                    </OverlayTrigger>
                                  </div>
                                </td>
                                <td className="with-outline text-right">
                                  {"$ " + item.arrears.details.CurrentBalance}
                                </td>
                                <td className="with-outline">
                                  {item.arrears.details.Name}
                                </td>
                                {item.type != "prepaid" ? (
                                  <td className="with-outline">
                                    <a
                                      onClick={this.getDataDetails.bind(this)}
                                      accountId={item.arrears.details.AccountID}
                                    >
                                      view
                                    </a>
                                  </td>
                                ) : null}
                                <td className="with-outline">
                                  <a
                                    onClick={this.getDataDetailsPayment.bind(
                                      this
                                    )}
                                    accountId={item.arrears.details.AccountID}
                                  >
                                    view history
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div className="add-to-cart-cont">
                          <div className="button-cart-add">
                            <button
                              type="submit"
                              onClick={this.handleGoStep2}
                              className="btn btn-change-ok font-bold btn-primary"
                            >
                              Next
                            </button>
                          </div>
                          <div className="button-cart-cancel">
                            <button
                              type="submit"
                              // onClick={this.handleGoStep2}
                              className="btn btn-change-ok font-bold btn-primary"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : null}

                {this.state.step2 ? (
                  <div id="step2-cont">
                    <h5>Phoned-In Bill Payment</h5>
                    <p>
                      Please verify the amount to be paid for the selected
                      account.
                    </p>
                    <div className="table-shopping-cont">
                      {this.state.accountSummary.length > 0 &&
                      this.state.accountSummary[0].accountType === "prepaid" ? (
                        <React.Fragment>
                          <p>
                            You may choose to pay an amount that differs from
                            the Amount Due - simply fill-on the text box in the
                            Payment Amount column with the desired amount. When
                            ready, click CONTINUE to process to the next step.
                          </p>
                          <Table className="table">
                            <thead>
                              <tr>
                                <th colSpan="7">Account Information</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="with-style">
                                <td className="with-outline">Account No.</td>
                                <td className="with-outline">Debt Recovery</td>
                                <td className="with-outline">
                                  Electric Balance
                                </td>
                                <td className="with-outline">Payment Amount</td>
                                <td className="with-outline">Customer Name</td>
                              </tr>
                              {!(this.state.accountSummary.length > 0) ? (
                                <tr>
                                  <td colSpan="7">
                                    <center>
                                      <Spinner
                                        animation="border"
                                        size="sm"
                                        variant="primary"
                                      />
                                      {" Loading..."}
                                    </center>
                                  </td>
                                </tr>
                              ) : (
                                this.state.accountSummary.map((item, index) => (
                                  <tr key={index}>
                                    <td className="with-outline">
                                      {item.accID}
                                    </td>
                                    <td className="with-outline text-right">
                                      ${" " + item.debtRecovery}
                                    </td>
                                    <td className="with-outline text-right">
                                      ${" " + item.electricBalance}
                                    </td>
                                    <td className="with-outline">
                                      <div className="row">
                                        <div className="col-lg-12">
                                          <NumberFormat
                                            id={item.accID}
                                            className="text-right"
                                            style={{
                                              width: "60%",
                                              textAlign: "center",
                                            }}
                                            onChange={this.amountToBePaidOnChange.bind(
                                              this
                                            )}
                                            value={parseFloat(
                                              item.amountToBePaid
                                            )}
                                            // value={parseFloat(item.amountToBePaid)}
                                            thousandSeparator={true}
                                            prefix={"$ "}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            allowNegative={false}
                                          />
                                        </div>
                                      </div>
                                    </td>
                                    <td className="with-outline">
                                      {item.fullName}
                                    </td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </Table>
                        </React.Fragment>
                      ) : this.state.accountSummary.length > 0 &&
                        this.state.accountSummary[0].accountType ===
                          "postpaid" ? (
                        <React.Fragment>
                          <p>
                            You may choose to pay an amount that differs from
                            the Amount Due - simply fill-on the text box in the
                            Payment Amount column with the desired amount. When
                            ready, click CONTINUE to process to the next step.
                          </p>
                          <Table className="table">
                            <thead>
                              <tr>
                                <th colSpan="7">Account Information</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="with-style">
                                <td className="with-outline">Account No.</td>
                                <td className="with-outline">Balance Due</td>
                                <td className="with-outline">Payment Amount</td>
                                <td className="with-outline">
                                  Last Billing Date
                                </td>
                                <td className="with-outline">Customer Name</td>
                              </tr>
                              {!(this.state.accountSummary.length > 0) ? (
                                <tr>
                                  <td colSpan="7">
                                    <center>
                                      <Spinner
                                        animation="border"
                                        size="sm"
                                        variant="primary"
                                      />
                                      {" Loading..."}
                                    </center>
                                  </td>
                                </tr>
                              ) : (
                                this.state.accountSummary.map((item, index) => (
                                  <tr key={index}>
                                    <td className="with-outline">
                                      {item.accID}
                                    </td>
                                    <td className="with-outline text-right">
                                      {"$ " + item.amount}
                                    </td>
                                    <td className="with-outline">
                                      <div className="row">
                                        <div className="col-lg-12">
                                          <NumberFormat
                                            id={item.accID}
                                            className="text-right"
                                            style={{
                                              width: "60%",
                                              textAlign: "center",
                                            }}
                                            onChange={this.amountToBePaidOnChange.bind(
                                              this
                                            )}
                                            value={parseFloat(
                                              item.amountToBePaid
                                            )}
                                            // value={parseFloat(item.amountToBePaid)}
                                            thousandSeparator={true}
                                            prefix={"$ "}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            allowNegative={false}
                                          />
                                        </div>
                                      </div>
                                    </td>
                                    <td className="with-outline">
                                      {item.billDate}
                                    </td>
                                    <td className="with-outline">
                                      {item.fullName}
                                    </td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </Table>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <br />
                          <center>
                            <Spinner
                              animation="border"
                              size="md"
                              variant="primary"
                            />
                          </center>
                          <br />
                        </React.Fragment>
                      )}
                      {this.state.accountSummary.length > 0 ? (
                        <div className="shopping-button-cont">
                          <div className="shopping-button-cont-left">
                            <div className="button-shopping-back">
                              <button
                                type="submit"
                                onClick={this.handleGoStep1}
                                className="btn btn-change-ok font-bold btn-primary"
                              >
                                Back
                              </button>
                            </div>
                          </div>
                          <div className="shopping-button-cont-right">
                            <div className="button-shopping-cancel">
                              <button
                                type="submit"
                                onClick={this.handleGoStep1}
                                className="btn btn-change-ok font-bold btn-primary"
                              >
                                Cancel
                              </button>
                            </div>
                            <div className="button-shopping-continue">
                              {this.paymentForm(
                                this.state.accountSummary[0],
                                "Continue"
                              )}
                              {/* <PayNowModal accountSummary={this.state.accountSummary} subTotal={this.state.subtotal} email={this.state.emailAddress} mode={"admin"}/> */}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}

                {this.state.step3 ? <div id="step3-cont"></div> : null}
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, {
  saveAccountId,
  savePremiseAddress,
  fetchMultipleAddOpptyRequest,
  fetchMultipleLatestBill,
  saveOrderData,
  generateFpHash,
  showMessage,
})(AdminPhonedIn);
