import React, { Component } from 'react';
import { Alert, OverlayTrigger, Popover, Table, Modal  } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser } from '../actions/loginAndSignUpActions';
import ForgotPassword from '../components/forgotPassword';
import ForgotUsername from '../components/forgotUsername';
import parse from 'html-react-parser';
import axios from 'axios';

/** FOR SLICK SLIDER */
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Redirect } from 'react-router-dom';

class Login extends Component {
    constructor() {
        super()
        this.state = {
            announcementImageSource: process.env.REACT_APP_LOGIN_SIGNUP_API_URL+'/images/',
            email: '',
            username: '', 
            password: '',
            isLoggedIn: false,
            alertVariant: 'danger',
            items: [],
            isLoading: false,
            isServerError: false,
            disabledLogin: false,
            disabledPay: false,
            announcementsList: [],
            systemPop: [],
            isAnnouncementPop: false,
            isAnnouncement: false,
            showAnnouncement: false,
            errLoginMessage: "",
            modalType: "",
            modalImage: "placeholder-1-e1533569576673-960x960.png",
            modalTitle: "",
            modalContent: "",

            displayFAQ: false,
            displayDashboard: false,
            displaySignUp: false,
            displayPayNow: false
        }
        this.onChange = this.onChange.bind(this)
    }
    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        },
        () => {
        })
    }
    
    checkIfUserLoggedIn = () => {
        //session variable search
        if(localStorage.getItem("prepaid") === "true" && localStorage.getItem("postpaid") === "true"){
            this.displayDashboard();
        }
        else if(localStorage.getItem("prepaid") === "true"){
            this.displayDashboard();
        }
        else if(localStorage.getItem("postpaid") === "true"){
            this.displayDashboard();
        }
    }

    componentDidMount() {
        this.getAnnouncements();
        this.systeMaintenance();
        this.checkIfUserLoggedIn();
        this.renderSliderAnnouncement();
        this.renderPopUpAnnoncements();
    }

    closeFlagModal = () => {
        this.setState({ showFlag: false, showAnnouncement: false });
    }

    systeMaintenance = () => {
        return axios
        .get('api/v1/system-maintenance', 
        {
            headers: {'Content-Type':'application/json'}
        })
        .then(response => {
            if(response.data[0].flag == 1){
                this.setState({disabledLogin: true});
            }else{
                this.setState({disabledLogin: false});
            }
            if(response.data[1].flag == 1) {
                this.setState({disabledPay: true});
            }else{
                this.setState({disabledPay: false});
            }
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        localStorage.clear();
        this.setState({ isLoading: true, isLoggedIn: false })
        const userData = {
            username: this.state.username,
            password: this.state.password,
        }
        this.props.loginUser(userData)
        .then((result) => {
            const loginSuccessful = result.loginSuccessful
            const status = result.status
            console.log('status '+result)
            if(!(status === null)){
                this.setState({
                    isServerError: false,
                },() => {
                    if(loginSuccessful.toString() === "true"){
                        document.documentElement.scrollTop = 0;
                        this.setState({ isLoggedIn: true,  alertVariant: 'success'})
                        setInterval(() => { 
                            this.displayDashboard();
                        }, 1000);
                    }
                    else{
                        document.documentElement.scrollTop = 0;
                        this.setState({ isLoggedIn: true,  alertVariant: 'danger', isLoading: false, errLoginMessage: status.loginMessage})
                    }
                })
            }
            else{
                this.setState({
                    isServerError: true,
                    isLoggedIn: true,  
                    alertVariant: 'danger', 
                    isLoading: false
                })
            }
        })
        .catch((error)=>{
            this.setState({
                isServerError: true,
                isLoggedIn: true,  
                alertVariant: 'danger', 
                isLoading: false
            })
        })
    }

    getAnnouncements = () => {
        return axios
        .get('/api/v1/announcements-front', 
        {
            headers: {'Content-Type':'application/json'}
        })
        .then(response => {
            let announceList = response.data.announceList;
            let systemPop = response.data.systemPop;
            let isAnnouncement = announceList.length == 0 ? false : true;
            let isAnnouncementPop = systemPop.length == 0 ? false : true;
            let showFlag = isAnnouncementPop == false ? false : true;
        
          this.setState({
            announcementsList: announceList,
            isAnnouncement: isAnnouncement,
            systemPop: systemPop,
            isAnnouncementPop: isAnnouncementPop,
            showFlag: showFlag
          });
          
        });
    }

    showAnnouncePopModal = (e) => {
        e.preventDefault();
        let type = e.currentTarget.getAttribute("modaltype");
        let image = e.currentTarget.getAttribute("modalimage");
        let title = e.currentTarget.getAttribute("modaltitle");
        let content = e.currentTarget.getAttribute("modalcontent");

        this.setState({
            modalType: type,
            modalImage: image,
            modalTitle: title,
            modalContent: content,
            showAnnouncement: true,
        });
    }

    renderSliderAnnouncement() {
        let settings = {
                dots: true,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                adaptiveHeight: true,
                // autoplay: true,
                // speed: 2000,
                // autoplaySpeed: 2000,
                cssEase: "linear"
            };

        let announcements = this.state.announcementsList.map((item, index) => {
            return (
                <div key={index}>
                    <Table className="table announcement-login">
                        <tbody>
                    
                        {
                            item.length == 1  ?
                                item.map((item1, i) => {
                                    let cont = parse(item1.featured_image);
                                    return(
                                        <tr key={i}>
                                            <td className="center-cont">
                                                <div className="container">
                                                <div className="top-cont">
                                                    {
                                                        item1.type == 'Image' ?
                                                        <a modaltype={item1.type} modalimage={item1.featured_image} modaltitle={item1.title} modalcontent={item1.content} onClick={this.showAnnouncePopModal.bind(this)}><img width="450" height="300" src={this.state.announcementImageSource+item1.featured_image} /></a>
                                                        :
                                                        <a modaltype={item1.type} modalimage={item1.featured_image} modaltitle={item1.title} modalcontent={item1.content} className="iframe-click" onClick={this.showAnnouncePopModal.bind(this)}>
                                                        <div className="featured-iframe">
                                                                {cont}
                                                            </div>
                                                        </a>
                                                        
                                                    }
                                                    <div className="cont-left">
                                                        <h6><a modaltype={item1.type} modalimage={item1.featured_image} modaltitle={item1.title} modalcontent={item1.content} onClick={this.showAnnouncePopModal.bind(this)}>{item1.title}</a></h6>
                                                    </div>
                                                </div>
                                                </div>
                                            </td> 
                                        </tr>
                                    ); 
                                })
                            :
                            item.map((item1, i) => {
                                let cont = parse(item1.featured_image);
                                return(
                                    <tr key={i}>
                                        <td width="200px">
                                            {
                                                item1.type == 'Image' ?
                                                <a modaltype={item1.type} modalimage={item1.featured_image} modaltitle={item1.title} modalcontent={item1.content}onClick={this.showAnnouncePopModal.bind(this)}><img width="200" height="150" src={this.state.announcementImageSource +item1.featured_image} /></a>
                                                :
                                                <div className="click-a" modaltype={item1.type} modalimage={item1.featured_image} modaltitle={item1.title} modalcontent={item1.content} className="iframe-click" onClick={this.showAnnouncePopModal.bind(this)}>
                                                <a >
                                                    {cont}
                                                </a>
                                                </div>
                                            }
                                        </td>
                                        <td>
                                            <h6><a modaltype={item1.type} modalimage={item1.featured_image} modaltitle={item1.title} modalcontent={item1.content} onClick={this.showAnnouncePopModal.bind(this)}>{item1.title}</a></h6>
                                        </td> 
                                    </tr>
                                ); 
                            })
                        }
                        </tbody>
                    </Table>
                </div>
            );
             
        })
        return(
            <div id="announcement-container">
                <div className="with-announcement">
                    <div className="light-font-color">
                        <h3 className="font-extra-bold">OUR HOME, OUR ISLAND,</h3>
                        <h1 className="font-extra-bold">OUR POWER UTILITY</h1>
                        <p className="font-bold">Guam is growing and our needs for electricity are growing as well. All the electrical power we use is generated right here on island by the Guam Power Authority.</p>
                    </div>
                </div>
                <Slider {...settings}>
                    {announcements}
                </Slider>
            </div>
        );
    }
    
    renderPopUpAnnoncements () {
        let popUp = this.state.systemPop.map((item, index) => { 
            let it = item.content;
            let con = parse(it);
            return (
                <div className="syst">
                    <h5>{item.title}</h5>
                    {con}
                </div>
            )  
        })
        return (
            <div>
                {popUp}
            </div>
            
        )
    }

    goToSignUp = (e) => {
        e.preventDefault();
        this.setState({
            displaySignUp: true
        })
    }
    
    faqOnClick = (e) => {
        e.preventDefault();
        this.setState({
            displayFAQ: true
        })
    }

    displayDashboard = () => {
        this.setState({
            displayDashboard: true
        })
    }
    displayPayNow = () => {
        this.setState({
            displayPayNow: true
        })
    }

    render() { 
        let loginMsg = "";
        (this.state.isServerError) ? 
        loginMsg = "Server not responding. Please try again later."
        :
        loginMsg = this.state.alertVariant === 'danger' ? this.state.errLoginMessage : "Success. Please wait for redirection."
        
        if(this.state.displayFAQ){
            return <Redirect to="/faq" />
        }
        else if(this.state.displayDashboard){
            return <Redirect to="/dashboard" />
        }
        else if(this.state.displaySignUp){
            return <Redirect to="/sign-up" />
        }
        else if(this.state.displayPayNow){
            return <Redirect to="/quickpay" />
        }
        return (  
            <section id="login">
            <Modal
                show={this.state.showFlag}
                onHide={this.closeFlagModal} 
                size="x"
                aria-labelledby="contained-modal-title-vcenter"
                id="modal-with-prompt"
                backdrop="static"
                centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div id="system-pop-container">
                        {this.renderPopUpAnnoncements()}
                    </div>
                </Modal.Body>
            </Modal>
        
            <Modal
                show={this.state.showAnnouncement}
                onHide={this.closeFlagModal} 
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                id="modal-with-prompt"
                backdrop="static"
                centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div id="system-pop-announcement-container">
                        <div className="featured-iframe">
                        {   
                            this.state.modalType == 'Image' ?
                            <img width="450" height="300" src={this.state.announcementImageSource+this.state.modalImage} />
                            :
                            <div className="iframe-cont">
                                {parse(this.state.modalImage)}
                            </div>
                           
                        }
                         </div>
                        <div className="title-cont">
                            <h5>{this.state.modalTitle}</h5>
                        </div>
                        <p>{parse(this.state.modalContent)}</p>
                    </div>
                </Modal.Body>
            </Modal>
                <div className="container-fluid p-0">
                    <div className="row" style={{height: '100vh'}}>
                        <div className="col-lg-6 p-0 row-eq-height">
                            <div className="center-element">
                                <form onSubmit={e => this.handleSubmit(e)} autocomplete="off">
                                    <Alert show={this.state.isLoggedIn} variant={this.state.alertVariant}>
                                        <p>
                                            {loginMsg}
                                        </p>
                                    </Alert>
                                    <div className="login-form">
                                        <div>
                                            <img src={process.env.PUBLIC_URL + '/images/GPA-Logo.png'} alt="GPA-Logo"/>
                                        </div>
                                        <div className="pt-2">
                                            <h5 className="dark-font-color big-font-size">Manage your Guam Power Authority account with PayGPA</h5>
                                        </div>
                                        <div className="custom-width">
                                            <div className="pt-2">
                                                <button type="button" className="btn btn-primary font-bold" onClick={this.displayPayNow.bind(this)} disabled={this.state.disabledPay}>Quick Pay</button>
                                            </div>
                                            <div className="pt-2">
                                                <img src={process.env.PUBLIC_URL + '/images/card-types.png'} alt="card-types"/>
                                            </div>
                                            <div className="pt-2">
                                                <h5 className="dark-font-color big-font-size">or</h5>
                                            </div>
                                            <div className="pt-2">
                                                <label className="dark-font-color font-bold">
                                                    Username (case-sensitive)
                                                    {/* <OverlayTrigger
                                                        trigger="hover"
                                                        placement="top"
                                                        overlay={
                                                            <Popover id="popover-contained">
                                                                {"If you have a PayGPWA Mobile App username or password, you can use that here."}
                                                            </Popover>
                                                        }
                                                    >
                                                        <i 
                                                            class="fas fa-info-circle" 
                                                            style={{
                                                                fontSize: "15px",
                                                                verticalAlign: "middle",
                                                                marginLeft: "5px",
                                                                color: "#08c484"
                                                            }} 
                                                        >
                                                        </i>
                                                    </OverlayTrigger>     */}
                                                </label>
                                                <input 
                                                    type="text" 
                                                    name="username" 
                                                    className="gpa-form-input" 
                                                    // style={{textTransform: "lowercase"}} 
                                                    onChange={this.onChange.bind(this)}
                                                />
                                            </div>
                                            <div className="pt-2">
                                                <label className="dark-font-color font-bold">
                                                    Password
                                                    {/* <OverlayTrigger
                                                        trigger="hover"
                                                        placement="top"
                                                        overlay={
                                                            <Popover id="popover-contained">
                                                                {"If you have a PayGPWA Mobile App username or password, you can use that here."}
                                                            </Popover>
                                                        }
                                                    >
                                                        <i 
                                                            class="fas fa-info-circle" 
                                                            style={{
                                                                fontSize: "15px",
                                                                verticalAlign: "middle",
                                                                marginLeft: "5px",
                                                                color: "#08c484"
                                                            }} 
                                                        >
                                                        </i>
                                                    </OverlayTrigger> */}
                                                </label>
                                                <input type="password" name="password" className="gpa-form-input" onChange={this.onChange.bind(this)}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="login_actions">
                                        <div className="container custom-width p-0">
                                            <div className="row">
                                                <div className="col-lg-12 forgot">
                                                    <label className="dark-font-color form-check-label">Forgot your <span className="secondary-font-color font-bold"><ForgotUsername /></span> or <span className="secondary-font-color font-bold"><ForgotPassword />?</span></label>
                                                </div> 
                                            </div>
                                            <div className="pt-2">
                                                {
                                                    this.state.disabledLogin ?
                                                    <button type="submit" className="btn btn-primary font-bold disabled-front" >Log In</button>
                                                    :
                                                    <button type="submit" className="btn btn-primary font-bold"  disabled={this.state.isLoading}>{(this.state.isLoading === true) ? 'Please wait...' : 'Log In'}</button>
                                                    
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className="text-center pt-3 container">
                                    <p className="">Don’t have a PayGPA account yet? <a className="secondary-font-color font-bold link-hover" onClick={this.goToSignUp.bind(this)}>Sign Up Now</a></p>
                                    <p className="">Visit our <a href="/" className="secondary-font-color font-bold" onClick={this.faqOnClick.bind(this)}>FAQ page</a> for questons or Call <a className="secondary-font-color font-bold" href="tel:647-5787/8/9">647-5787/8/9</a></p>
                                    <p className="">Send us an email at <a href="mailto:paygpainfo@gpagwa.com" className="secondary-font-color font-bold">paygpainfo@gpagwa.com</a></p>
                                </div>
                                <div className="text-center pb-4">
                                    <a href="https://www.guampowerauthority.com/" target="_blank"><img src={process.env.PUBLIC_URL + '/images/guam-logo-small.png'} alt="guam-logo-small"></img></a>
                                    <a href="http://guamwaterworks.org/" target="_blank"><img src={process.env.PUBLIC_URL + '/images/guam-waterworks.png'} alt="guam-waterworks.png"></img></a>
                                    <a href="https://www.myenergyguam.com/" target="_blank"><img src={process.env.PUBLIC_URL + '/images/guam-me-energy.png'} alt="guam-me-energy"></img></a>
                                    <a href="http://guamccu.org/" target="_blank"><img src={process.env.PUBLIC_URL + '/images/guam-ccu.png'} alt="guam-ccu.png"></img></a>
                                    <a href="https://www.guamsolidwasteauthority.com/" target="_blank"><img src={process.env.PUBLIC_URL + '/images/GSWA.PNG'} alt="GSWA.png" width="42"></img></a>
                                </div>
                                <div className="login-footer text-center">
                                    <p className="no-line-height">All Rights Reserved © 2020 Guam Power Authority</p>
                                    <p><a href="/privacy-policy" className="secondary-font-color font-bold">Privacy Policy</a> |  <a href="/terms-and-conditions" className="secondary-font-color font-bold">Terms and Conditions</a></p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-6 p-0 row-eq-height login-banner">
                            {/* {
                                this.state.isAnnouncement ? 
                                    this.renderSliderAnnouncement()
                                :
                                <div className="center-element">
                                    <div className="padding-right light-font-color">
                                        <h3 className="font-extra-bold">OUR HOME, OUR ISLAND,</h3>
                                        <h1 className="font-extra-bold">OUR POWER UTILITY</h1>
                                        <p className="font-bold">Guam is growing and our needs for electricity are growing as well. All the electrical power we use is generated right here on island by the Guam Power Authority.</p>
                                    </div>
                                </div>
                                
                            } */}
                            
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

Login.propTypes = {
    loginUser: PropTypes.func.isRequired,
    status: PropTypes.string
}

const mapStateToProps = state => ({
    users: state.users.users,
    status: state.users.status
});

export default connect(mapStateToProps, { loginUser })(Login);