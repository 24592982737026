import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Header from '../partials/header';
import Footer from '../partials/footer';

class PrivacyPolicy extends Component {
    state = { 
        displayLogin: false
    }
    gotoLogin = (e) => {
        this.setState({
            displayLogin: true
        })
    }
    render() { 
        if(this.state.displayLogin) {
            return <Redirect to="/" />
        }
        return (  
            <React.Fragment>
                <Header />
                <section id="privacy_policy">
                    <div className="container">
                        <div id="title" className="row justify-content-md-center">
                            <div className="col-lg-12">
                                <h4 className="primary-font-color text-center">
                                    Guam Power Authority Internet Privacy Policy
                                </h4>
                            </div>
                        </div>
                        <div id="body" className="row">
                            <div className="col-lg-12">
                                <p>
                                    This Privacy Policy applies to GuamPowerAuthority.com and PayGPA.com. Other websites that may be affiliated with the Guam Power Authority which have other domain names may have different privacy policies. The Guam Power Authority respects the privacy of the users of GuamPowerAuthority.com and PayGPA.com and believes that any information gathered should be used responsibly and appropriately. In general, all users may visit GuamPowerAuthority.com and PayGPA.com anonymously. There are times, however, when we may need information from you, such as your name and address. This is usually required in order for the Guam Power Authority to promptly respond to your requests for information or to answer your questions. This information will only be provided to the Guam Power Authority personnel who need it to respond to your inquiries. Your personal information will not be shared, sold, or used for marketing services other than pertaining to GuamPowerAuthority.com and PayGPA.com. We do keep track of the countries, domains, browsers, internet service providers and other related data. We analyze this data for trends and statistics. We may also collect information automatically provided to us by your browser about the type of computer and operating system you are using. In general, we do not use cookies – pieces of information that a web site transfers to an individual’s hard drive for record-keeping purposes – to help identify users. If you have any questions, please contact the Guam Power Authority via email at <a href="mailto:paygpainfo@gpagwa.com">paygpainfo@gpagwa.com</a>. This Privacy Policy does not extend to anything that is inherent in the operation of the internet, and beyond the control of the Guam Power Authority, and is not to be applied in any manner contrary to applicable laws, rules, or regulations.    
                                </p>
                            </div>
                            <div className="col-lg-12 text-center mb-2 mt-5">
                                <button type="submit" className="btn btn-primary font-bold" onClick={this.gotoLogin.bind(this)}>Back to Homepage</button>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </React.Fragment>
        );
    }
}

export default PrivacyPolicy;