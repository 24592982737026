import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getListSurvey, fetchMultipleAddOpptyRequest, submitSurvey } from '../actions/dashboardActions';
import { Form, Col, Row, Spinner, Button } from 'react-bootstrap';
import Loading from './loading'
import _ from 'lodash';
import LogoutButton from './logoutButton';
import { 
    showMessage
} from '../actions/popupMessageActions';
class Survey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            validated: false,
            isSubmitLoading: false,
            userInfo: {
                personId: '',
                username: '',
                fullName: '',
            },
            surveyAnswers: {
                answer1: '',
                answer2: '',
                answer3: '',
                answer4: '',
                answer5: '',
                answer6: '',
                answer7: '',
                answer8: '',
                answer9: '',
                answer10: '',
            }
        };
    }
    componentWillMount() {
        this.props.getListSurvey()
        .then(() => {
            //session key search
            let sessionPersonId, sessionUsername;
            sessionPersonId = localStorage.getItem("personId");
            sessionUsername = localStorage.getItem("username");
            if(this.props.dashboard.userAccountDetails.fullName === undefined){
                this.props.fetchMultipleAddOpptyRequest(this.props.dashboard.selectedAccountId, sessionPersonId)
                .then(() => {
                    this.setState({
                        ...this.state,
                        userInfo: {
                            ...this.state.userInfo,
                            personId: sessionPersonId,
                            username: sessionUsername,
                            fullName: this.props.dashboard.userAccountDetails.fullName
                        },
                        isLoading: false
                    })
                })
            }
            else{
                this.setState({
                    ...this.state,
                    userInfo: {
                        ...this.state.userInfo,
                        personId: sessionPersonId,
                        username: sessionUsername,
                        fullName: this.props.dashboard.userAccountDetails.fullName
                    },
                    isLoading: false
                })
            }
            // console.clear()
        })
    }
    onSubmit = (e) => {
        const form = e.currentTarget;
        this.setState({
            ...this.state,
            isSubmitLoading: true
        })
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({
                validated: true,
                isSubmitLoading: false
            })
        }
        else{
            e.preventDefault();
            e.stopPropagation();
            const postData = this.state
            this.props.submitSurvey(postData)
            .then((status) => {
                this.setState({
                    isSubmitLoading: false
                })
                if(status === 'success'){
                    this.props.showMessage(false, 'Si Yu’os Ma’ase and Thank you for your time and responses.Your survey has been submitted successfully.');
                }
                else{
                    this.props.showMessage(true, 'Submit survey failed. Please try again.');
                }
               
            })
            .catch((error)=>{
                this.props.showMessage(true, "Server failed! \nPlease try again!");
            })
        }
    }
    onChange = (e) => {
        this.setState({
            ...this.state,
            surveyAnswers: {
                ...this.state.surveyAnswers,
                [e.target.name]: e.target.value
            }
        })
    }
    showDashboard = (e) => {
        e.preventDefault();
        window.location.replace("/dashboard");
    }
    render() { 
        return (
        <React.Fragment>
            <div className="row pb-4">
                <div className="col-lg-8">
                    <h3 className="primary-font-color">Survey</h3>
                </div>
                <div className="col-lg-4">
                    <LogoutButton />
                </div>
            </div>
            {
                this.state.isLoading ? 
                <Loading />
                :  
                <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <h5>Hafa Adai! We hope you’re finding our online service useful.</h5>
                        <label>In our effort to better serve YOU, we ask you to take part in a quick survey which will take approximately 3 minutes to complete. Your responses are treated with the strictest confidence and will be used within GPA to improve the quality of our service delivery.</label>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <hr />
                    </div>
                </div>
                <div id="survey-list" className="row">
                    <Form noValidate validated={this.state.validated} onSubmit={this.onSubmit.bind(this)} autocomplete="off">
                        { _.map(this.props.dashboard.surveyList, (item, index) => {
                            return(
                                <div className="col-lg-12">
                                { index === 3 ? 
                                    <React.Fragment>
                                        <Form.Group as={Row} key={index}>
                                            <Form.Label column lg="8">
                                                <label>{item.prompt}</label>
                                            </Form.Label>
                                            <Col lg="4" className="position-relative">
                                                <div className="not-required-field">
                                                    <Form.Control as="select" className="form-input"
                                                        name={"answer"+item.sequence}
                                                        onChange={this.onChange.bind(this)}
                                                        defaultValue=""
                                                    >
                                                        <option value="" selected></option>
                                                        { _.map(this.props.dashboard.surveyList[index].possibleAnswers, (item1, index1) => {
                                                            return(
                                                            <option key={index1} value={item1.value}>{item1.description}</option>
                                                            )
                                                        })}
                                                    </Form.Control>
                                                </div>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} key={10}>
                                            <Form.Label column lg="8">
                                                <label>In the last 90 days have you had contact with other GPA Personnel not mentioned above? Please indicate what GPA unit/section you were in contact with and rate your experience.</label>
                                            </Form.Label>
                                            <Col lg="4" className="position-relative">
                                                <div className="not-required-field">
                                                    <Form.Control name={"answer"+(this.props.dashboard.surveyList.length+1)} as="textarea" rows="2" onChange={this.onChange.bind(this)}></Form.Control>
                                                </div>
                                            </Col>
                                        </Form.Group>
                                    </React.Fragment>
                                    :
                                    index > 3 ?
                                    <Form.Group as={Row} key={index}>
                                        <Form.Label column lg="8">
                                            <label>{item.prompt}</label>
                                        </Form.Label>
                                        <Col lg="4" className="position-relative">
                                            <div className="required-field">
                                                <Form.Control as="select" className="form-input"
                                                    required
                                                    name={"answer"+item.sequence}
                                                    onChange={this.onChange.bind(this)}
                                                    defaultValue=""
                                                >
                                                    <option defaultValue="" selected></option>
                                                    { _.map(this.props.dashboard.surveyList[index].possibleAnswers, (item1, index1) => {
                                                        return(
                                                        <option key={index1} value={item1.value}>{item1.description}</option>
                                                        )
                                                    })}
                                                </Form.Control>
                                                <Form.Control.Feedback className="font-bold" type="invalid">Please select or enter a value for this question</Form.Control.Feedback>
                                            </div>
                                        </Col>
                                    </Form.Group>
                                    :
                                    <Form.Group as={Row} key={index}>
                                        <Form.Label column lg="8">
                                            <label>{item.prompt}</label>
                                        </Form.Label>
                                        <Col lg="4" className="position-relative">
                                            <div className="not-required-field">
                                                <Form.Control as="select" className="form-input"
                                                    name={"answer"+item.sequence}
                                                    onChange={this.onChange.bind(this)}
                                                    defaultValue=""
                                                >
                                                    <option defaultValue="" selected></option>
                                                    { _.map(this.props.dashboard.surveyList[index].possibleAnswers, (item1, index1) => {
                                                        return(
                                                        <option key={index1} value={item1.value}>{item1.description}</option>
                                                        )
                                                    })}
                                                </Form.Control>
                                            </div>
                                        </Col>
                                    </Form.Group>
                                }
                                </div>
                                
                            )
                        })}
                    <div className="col-lg-12">
                        <div className="thank-you-msg">
                            <h5>Thank you for taking the time out to complete this survey?</h5>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="btn-wrap">
                            {
                                !this.state.isSubmitLoading ? 
                                    <React.Fragment>
                                        <button type="submit" className="btn btn-primary regular-font-size font-bold">Submit</button>
                                        <a className="dark-font-color regular-font-size" href="/" onClick={this.showDashboard.bind(this)}>Cancel</a>
                                    </React.Fragment>
                                :  
                                    <React.Fragment>
                                        <Button variant="primary" className="btn btn-primary regular-font-size font-bold" disabled>
                                            <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            />
                                            Loading...
                                        </Button>
                                        <a href="/" className="dark-font-color regular-font-size" onClick={this.showDashboard.bind(this)} disabled>Cancel</a>
                                    </React.Fragment>
                            }
                        </div>
                    </div>
                    </Form>
                </div>
                </React.Fragment>
            }
        </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    dashboard: state.dashboard
});

export default connect(mapStateToProps, { getListSurvey, fetchMultipleAddOpptyRequest, submitSurvey, showMessage })(Survey);