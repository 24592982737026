import React, { Component } from 'react';
import Header from '../partials/header';
import Footer from '../partials/footer';
import {Accordion, Card, Button} from 'react-bootstrap';
import { Redirect } from 'react-router-dom';

class FAQ extends Component {
    state = {
        goBackClicked: false,
        displaySignUp: false,
        displayPayNow: false,
    }
    goBack = (e) => {
        e.preventDefault();
        this.setState({
            goBackClicked: true,
        })
    }
    gotoSignUp = (e) => {
        e.preventDefault();
        this.setState({
            displaySignUp: true,
        })
    }
    gotoSampleBillModal = (e) => {
        e.preventDefault()
        sessionStorage.setItem("openSampleBill", true)
        this.setState({
            displaySignUp: true,
        })
    }
    gotoForgotPass = (e) => {
        e.preventDefault()
        localStorage.clear()
        sessionStorage.setItem("openForgotPass", true)
        this.setState({
            goBackClicked: true,
        })
    }
    gotoForgotUser = (e) => {
        e.preventDefault()
        localStorage.clear()
        sessionStorage.setItem("openForgotUser", true)
        this.setState({
            goBackClicked: true,
        })
    }
    gotoContactInfo = (e) => {
        e.preventDefault()
        window.location.hash = '#contact-info';
    }
    gotoPayNow = (e) => {
        e.preventDefault()
        this.setState({
            displayPayNow: true,
        })
    }
    render() { 
        if(this.state.goBackClicked) {
            return <Redirect to="/" />
        }
        else if(this.state.displayPayNow) {
            return <Redirect to="/quickpay" />
        }
        else if(this.state.displaySignUp) {
            return <Redirect to="/sign-up" />
        }
        return ( 
            <React.Fragment>
                <Header />
                <section className="pt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h4 className="primary-font-color">PayGPA Self Service Web Portal help and FAQs</h4>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="faq">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Accordion>
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                            What is PayGPA?
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                        <Card.Body>PayGPA provides the Guam Power Authority (GPA) customers secured access to their electric account(s) information. Registered customers will be able to view their electric bill, energy usage, and payment history. In addition, customers can update their account(s) information and send messages to GPA’s Customer Service Division. Customers will also be able to pay their electric bill online safe and securely. Sign up today!</Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                            What services or features does the My Power Self Service Web Portal offer?
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="1">
                                        <Card.Body>
                                            o You can view your GPA electric billing statements, payments, and consumption history.<br/> 

                                            o You can pay your GPA electric bill online.<br/> 

                                            o You can view and update your GPA electric account information.<br/> 

                                            o If you have more than one GPA electric account, you can view them all in one place. <br/> 
                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                            What information do I need in order to register?
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="2">
                                        <Card.Body>
                                        Go to PayGPA.com and click "Sign Up Now".<br/> <br/> 

                                        1. You will need to provide your 10-digit GPA electric account number<br/> 

                                        2. You will need to provide your Billing Zip-code (ex. 96910-1234)<br/> 

                                        3. You will need to create a unique Username (30 characters or less)<br/> 

                                        4. You will need to create a Password (8 to 15 characters long with at least one letter, number, and symbol)<br/> 

                                        5. You will need a valid Email Account<br/> 

                                        6. You will need to establish a Security Question<br/> 

                                        7. You will need to accept the Terms & Conditions<br/> 
                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                            Where is my account number?
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="3">
                                        <Card.Body>Your account number can be found near the upper left hand corner and the lower left hand corner of your GPA electric billing statement.</Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="4">
                                            Why do I have to create a username and password in order to access my account?
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="4">
                                        <Card.Body>We want your online experience to be as simple and convenient as possible. When you create a username and password, it ensures that only you (or others you designate) have access to your account information. We also use your username to securely store your account information and preferences in your user profile.</Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="5">
                                            How do I enroll multiple accounts?
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="5">
                                        <Card.Body>You have the option to add other accounts after you register with a username and valid email address. To do this, simply email GPA’s Customer Service Division at <a href="mailto:paygpainfo@gpagwa.com">paygpainfo@gpagwa.com</a> and we will assist you to set up your account to view your multiple GPA accounts.</Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="6">
                                             How secure is PayGPA?
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="6">
                                        <Card.Body>PayGPA uses TLS 1.3 for secure transmissions whenever information about you or your account is displayed. TLS applies encryption between two communicating applications, such as your computer and our corporate Internet server.</Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="7">
                                            Why is PayGPA not loading properly?
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="7">
                                        <Card.Body>PayGPA will not work with Internet Explorer. We highly recommend using the latest versions of Google Chrome, Safari, Mozilla Firefox, or Microsoft Edge.</Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="8">
                                            Who should I contact if I’m having trouble enrolling in PayGPA?
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="8">
                                        <Card.Body>
                                            Please contact GPA’s Customer Service Division via email at <a href="mailto:paygpainfo@gpagwa.com">paygpainfo@gpagwa.com</a> or call us at 647-5787/8/9. You may also visit us at any of our customer service locations for any GPA related issue or concerns:<br/>
                                            <br />
                                            <p className="ml-5">
                                                o Gloria B. Nelson Public Service Building, 688 Route 15, Mangilao (7:00am to 5:00pm);<br />

                                                o 103 Julale Shopping Center, 424 West O’Brien Dr., Hagåtña (8:00am to 5:00pm);<br />

                                                o 578 N. Marine Corps Drive, Tamuning (7:30am to 6:00pm) Saturday (9am to 1pm)<br />
                                            </p>
                                            Please note, however, that we close on most local and federal holidays.
                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </div>
                            <div className="col-lg-12">
                                <h5>Forgotten username or password</h5>
                            </div>
                            <div className="col-lg-12">
                                <Accordion>
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                            What if I forget my username?
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            If you forgot your username <br /><br />

                                            1. Click on the “Username” link just beneath the sign in fields of PayGPA.com. <br />

                                            2. Enter your 10-digit account number and the email address associated with your PayGPA account. <br />

                                            3. Click Submit and if the email address matches what you entered when you created your profile, your username will be sent automatically to you via email. <br />

                                            4. If no email is received, and/or your login issues persist, please contact GPA’s Customer Service Division at 647-5787/8/9 or via email at <a href="mailto:paygpainfo@gpagwa.com">paygpainfo@gpagwa.com</a>. <br />
                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                            What if I forget my password?
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="1">
                                        <Card.Body>
                                            If you forgot your password<br /><br />

                                            1. Click on the "Password" link just beneath the sign in fields of PayGPA.com.<br />

                                            2. Enter your 10-digit account number and the username associated with your PayGPA account.<br />

                                            3. Click Submit and if the username matches what you entered when you created your profile, your password will be sent automatically to you via email.<br />

                                            4. If no email is received, and/or your login issues persist, please contact GPA’s Customer Service Division at 647-5787/8/9 or via email at <a href="mailto:paygpainfo@gpagwa.com">paygpainfo@gpagwa.com</a>.<br />
                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                            I want to change my password. How do I do that?
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="2">
                                        <Card.Body>
                                            After signing in to your PayGPA account<br /><br />

                                            1. Navigate to the Account Overview page and click on “Edit Account” located within the Account Information area just below Your Account Summary.<br />

                                            2. In the Change Password section of Edit Account click on “Edit”.<br />

                                            3. Enter your current password and then provide the new password you wish to use.<br />

                                            4. After entering your new password and confirming it click on "Save Changes”.<br />
                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                            How do I change my security question?
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="3">
                                        <Card.Body>After signing in to your PayGPA account<br /><br />

1. Navigate to the Account Overview page and click on “Edit Account” located within the Account Information area just below Your Account Summary.<br />

2. The Security Question and Answer will be located just above the Change Password section.<br />

3. You may select a new question and/or provide an updated answer.<br />

4. When complete click on “Save Changes”.<br /></Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </div>
                            <div className="col-lg-12">
                                <h5>Changing your profile information/service requests</h5>
                            </div>
                            <div className="col-lg-12">
                                <Accordion>
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                            How do I change my email address or personal information?
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                        <Card.Body>After signing in to your PayGPA account<br /><br />

1. Navigate to the Account Overview page and click on “Edit Account” located within the Account Information area just below Your Account Summary.<br />

2. From here you may update your email address, contact numbers, security question, and password.<br />

3. When satisfied with your changes click on “Save Changes”.<br /></Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </div>
                            <div className="col-lg-12">
                                <h5>Billing and payment</h5>
                            </div>
                            <div className="col-lg-12">
                                <Accordion>
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                            Can I view and pay my bill online by registering with PayGPA?
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                        <Card.Body>Registering with PayGPA grants you the ability to view 12 months of your GPA electric billing statements, payments, and consumption history. You will also be able to pay your GPA electric bill online, however, registration is not mandatory to take advantage of this feature.</Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                            What are my payment options?
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="1">
                                        <Card.Body>You are currently able to make payment on your GPA electric account(s) via:<br /><br />

· Online @ www.paygpa.com<br />

· Pay GPWA Mobile App.<br />

· 24-hour Interactive Voice Response System (IVR) @ (671) 648-3000 opt. 1<br />

· Pay by Phone live person @ (671) 647-5787/8/9 (7:00 am to 6:00 pm Monday to Friday)<br /></Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                            How can I view my electric billing statement history?
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="2">
                                        <Card.Body>
                                            After signing in to your PayGPA account<br /><br />

                                            1. Navigate to the Account Overview page and click on the “Select an Action” drop down box located on the far right within Your Account Summary section.
                                            <br />
                                            2. Click on “View Statement”.
                                            <br />
                                            3. A pop-up with a list of your energy statements should now be visible.
                                            <br />
                                            4. Click on the page icon located in the View column to bring up your energy statement.
                                            <br />
                                            5. Your energy statement will open up in a separate window/tab in PDF format.
                                            <br />
                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                            How can I view a history of my energy consumption?
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="3">
                                        <Card.Body>
                                            After signing in to your PayGPA account<br /><br />

                                            1. Navigate to the Account Overview page and click on the “Select an Action” drop down box located on the far right within Your Account Summary section.
                                            <br />
                                            2. Click on “View Consumption”.
                                            <br />
                                            3. A pop-up with a graph chart of your monthly consumption should now be visible. You may also switch your view to Monthly Billing Amount from the View By drop down box located at the upper right
                                            <br />
                                        </Card.Body>
                                            
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="4">
                                            How can I view my electric payment history?
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="4">
                                        <Card.Body>
                                        After signing in to your PayGPA account<br /><br />

                                        1. Navigate to the Account Overview page and click on the “Select an Action” drop down box located on the far right within Your Account Summary section.
                                        <br />
                                        2. Click on “Payment History”.
                                        <br />
                                        3. A pop-up with a list of your payments should now be visible.
                                        <br />
                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="5">
                                        What steps can I take to dispute a payment
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="5">
                                        <Card.Body>
                                        Please contact GPA’s Customer Service Division at 647-5787/8/9 for any issues with your payment. Please provide specific information about the payment you are disputing. We will need to have the date of the payment, the payment amount, and the payment confirmation.
                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="6">
                                        Are there any restrictions on what I can use for my Username?
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="6">
                                        <Card.Body>
                                        Your username must be unique and no longer than 30 characters.
                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="7">
                                        Are there any restrictions on what I can use for my Password?
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="7">
                                        <Card.Body>
                                        For security reasons, we require your password to be 8 to 15 characters in length and have at least one letter, number, and symbol
                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </div>
                            
                            <div className="col-lg-12">
                                <center><button id="go-back" type="button" class="btn btn-primary font-bold" onClick={this.goBack.bind(this)}>Go Back</button></center>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </React.Fragment>
        );
    }
}
export default FAQ;