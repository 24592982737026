import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { fetchBillsList, getViewBillData } from '../actions/dashboardActions';
import sha256 from 'js-sha256'
class BtnViewBill extends Component {
    constructor(props){
        super(props);
        this.state = {
            isViewBill: false,
            isLoading: true,
            show: false,
            selectedBillID: "",
            selectedBillDate: "",
        }
    }
    viewBill = (billID, billDate) => {
        this.setState({
            ...this.state,
            selectedBillID: billID,
            selectedBillDate: billDate,
        }, () => {
            this.generateViewBillURL()
        })
    }
    encode64(index) {
        let base64Char = "A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P," +
        "Q,R,S,T,U,V,W,X,Y,Z,a,b,c,d,e,f," +
        "g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v," +
        "w,x,y,z,0,1,2,3,4,5,6,7,8,9,+,/,="
        return (base64Char.split(","))[index];
    }
    generateViewBillURL() {
        console.clear()
        let d = new Date();
        const accID = this.props.accId;
        const startDate = this.state.selectedBillDate;
        const endDate = this.state.selectedBillDate;
        const saltKey = "KhKDFDS*(kjnskdfn~23$kjas*lkd"
        const utc = ( (d.getUTCHours()*60) + d.getUTCMinutes() )
        // const utc = "384"
        //sha256 -> utf8
        console.log("concatenated string")
        console.log(accID+startDate+endDate+utc+saltKey);
        console.log("utc hrs")
        console.log(d.getUTCHours())
        console.log("utc mins")
        console.log(d.getUTCMinutes())
        console.log("total utc")
        console.log(utc);
        // let strSha256 = sha256(accID+startDate+endDate+"358"+saltKey)
        let strSha256 = sha256(accID+startDate+endDate+utc+saltKey)
        // let hash256 = strSha256.replace(/\D/g,'');
        console.log("sha256")
        console.log(strSha256)
        //save every two chars into arr
        let arrSlicedHash256 = [];
        arrSlicedHash256 = strSha256.match(/.{1,2}/g);
        console.log(arrSlicedHash256)
        //binary
        let binary8Bits = ""
        for(let count = 0; count < arrSlicedHash256.length; count++){
            if(arrSlicedHash256[count] !== ""){
                for (let i=0; i < arrSlicedHash256[count].length; i++) {
                    if(!(parseInt((arrSlicedHash256[count])[i]) >= 0)){
                        let hex = "0"+(arrSlicedHash256[count])[i];
                        let dec = parseInt(hex,16);
                        binary8Bits+=parseInt(dec, 10).toString(2)
                    }
                    else{
                        binary8Bits+=((arrSlicedHash256[count])[i].charCodeAt(0).toString(2)).substring(2);
                    }
                }
                // if(!(count === arrSlicedHash256.length-1)){
                //     binary8Bits+=","
                // }
            }
        }
        console.log("8 bits")
        console.log(binary8Bits)
        let binary6Bits = (binary8Bits+"00").match(/.{1,6}/g);
        console.log("6 bits")
        console.log(binary6Bits)
        let final = ""
        for(let count = 0; count < binary6Bits.length; count++){
            final += this.encode64(parseInt(binary6Bits[count],2))
        }
        final += "="
        console.log("base64")
        console.log(final)
        console.log("URL compatible")
        console.log(encodeURIComponent(final))
        console.log("generated link")
        let generatedLink = "https://secure8.i-doxs.net/gpa/BillPopLogin.aspx?account="+accID+"&startdate="+startDate+"&enddate="+endDate+"&utc="+utc+"&id="+encodeURIComponent(final)
        console.log(generatedLink)
        window.open(
            generatedLink,
            '_blank' // <- This is what makes it open in a new window.
        );
        
    }
    hideModal = (e) => {
        this.setState({ show: false });
    }
    render() { 
        return ( 
            <React.Fragment>
                <td className="secondary-font-color font-bold regular-font-size" onClick={() => this.viewBill(this.props.billId,this.props.arrearsDate)}>
                    <p className="m-0"><img src={process.env.PUBLIC_URL + '/images/sticky-note-regular.svg'} width="16px" style={{cursor: "pointer"}}/></p>
                </td>
            </React.Fragment>
        );
    }
}
 
const mapStateToProps = state => ({
    dashboard: state.dashboard
});

export default connect(mapStateToProps, { 
    fetchBillsList,  
    getViewBillData
})(BtnViewBill);