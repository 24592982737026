import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { 
    hideMessage
} from '../actions/popupMessageActions';

class PopupMessage extends Component {
    state = {  }
    hideModal = (e) => {
        this.setState({
            hideLabels: true
        }, () => {
            this.props.hideMessage()
            setInterval(() => { 
                this.setState({
                    hideLabels: false
                })
            }, 2000);
            
        })
    }
    render() { 
        return ( 
            <React.Fragment>
                <Modal
                    id="popup-message"
                    show={this.props.popupMessage.modalConfig.show}
                    onHide={this.hideModal.bind(this)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    centered
                >
                    <Modal.Header closeButton>
                        {
                            this.props.popupMessage.modalConfig.isError && !this.state.hideLabels ? 
                            <h5 className="mb-0"><img src={process.env.PUBLIC_URL + '/images/danger.png'} /> Error</h5>
                            :
                            !this.props.popupMessage.modalConfig.isError && !this.state.hideLabels ?
                            <h5 className="mb-0"><img src={process.env.PUBLIC_URL + '/images/success.png'} /> Success</h5>
                            :
                            null
                        }
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <center><label style={{whiteSpace: 'pre-line'}}>{this.props.popupMessage.modalConfig.message}</label></center>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    popupMessage: state.popupMessage
});

export default connect(mapStateToProps, { hideMessage })(PopupMessage);