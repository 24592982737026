import React, { Component } from 'react';
import { Form, Button, Spinner, Col } from 'react-bootstrap';
import {connect} from 'react-redux';
import Loading from './loading';
import { fetchOldUserDetails, getSequQuestions, getCountry, updateUserDetails, updateUserPassword } from '../actions/dashboardActions';
import _ from 'lodash';
import LogoutButton from './logoutButton';
import { 
    showMessage
} from '../actions/popupMessageActions';
class EditAccount extends Component {
    constructor() {
        super()
        this.state = {
            validatedEditAcc: false,
            validatedChangePass: false,
            isNotPword: false,
            isEditingPass : false,
            isEditLoading: true,
            isAdressEditable: true,
            isEmail: true,
            isProcessing: false,
            isDeletePhones: {
                homePhone: false,
                mobilePhone: false,
                workPhone: false
            },
            userAccDetails: {
                personId: '',
                accountId: '',
                username: '',
                fullName: '',
                emailAddress: '',
                address1: '',
                address2: '',
                city: '',
                postal: '',
                stateInitials: '',
                stateDesc: '',
                country: '',
                home: '',
                mobile: '',
                work: '',
                oldDateEmail: '',
                oldDateQuestion: '',
                oldDateAnswer: '',
                characteristicValue: '',
                answer: ''
            },
            userPassDetails: {
                oldPass: '',
                newPass: '',
                confirmPass: ''
            },
            pwErrMsg: ''
        }
    }

    componentWillMount() {
        //session variable search
        let sessionPersonId, sessionUsername;
        sessionPersonId = localStorage.getItem("personId");
        sessionUsername = localStorage.getItem("username");
        this.props.getSequQuestions()
        .then(() => {
            this.props.getCountry().then(() => {
                this.props.fetchOldUserDetails(sessionPersonId)
                .then(() => {
                    this.setState({
                        ...this.state,
                        isEditLoading: false,
                        isAdressEditable: (this.props.dashboard.userOldDataDetails.billAddressSource !== "PER") ? false : true,
                        userAccDetails: {
                            personId:            sessionPersonId,
                            // accountId:           this.props.accountId,
                            username:            sessionUsername,
                            fullName:            this.props.dashboard.userAccountDetails.fullName || '',
                            emailAddress:        this.props.dashboard.userAccountDetails.emailAddress || '',
                            address1:            this.props.dashboard.userAccountDetails.addressLine1 || '',
                            address2:            this.props.dashboard.userAccountDetails.addressLine2 || '',
                            city:                this.props.dashboard.userAccountDetails.city || '',
                            postal:              this.props.dashboard.userAccountDetails.postal || '',
                            stateInitials:       this.props.dashboard.userAccountDetails.stateInitials || '',
                            stateDesc:           this.props.dashboard.userAccountDetails.state || '',
                            country:             this.props.dashboard.userAccountDetails.country || '',
                            home:                this.props.dashboard.userAccountDetails.homePhone || '',
                            mobile:              this.props.dashboard.userAccountDetails.mobilePhone || '',
                            work:                this.props.dashboard.userAccountDetails.workPhone || '',
                            oldDateEmail:        this.props.dashboard.userOldDataDetails.oldDateEmailAdd || '',
                            oldDateQuestion:     this.props.dashboard.userOldDataDetails.oldDateSecuQuestion || '',
                            oldDateAnswer:       this.props.dashboard.userOldDataDetails.oldDateAnswer || '',
                            characteristicValue: this.props.dashboard.userOldDataDetails.oldCharValueSecuQues ||  this.props.dashboard.securityQuestions[0].characteristicValue,
                            answer:              this.props.dashboard.userOldDataDetails.oldAnswerSecuQues || ''
                        }
                    }, () => {
                        console.log('this.state')
                        console.log(this.state)
                    })
                })
            })
        })
    }

    onChangePassword = (e) => {
        if(e.target.name === "newPass" || e.target.name === "confirmPass"){
            this.setState({
                ...this.state,
                userPassDetails: {
                    ...this.state.userPassDetails,
                    [e.target.name] : e.target.value
                }
            },
            () => {
                let pword = String(this.state.userPassDetails.newPass);
                let confirm_pword = String(this.state.userPassDetails.confirmPass);
            
                if(pword.length < 8){
                    this.setState({ 
                        isNotPword: true,
                        pwErrMsg: "Your password must be between 8 – 15 characters long"
                    });
                }
                else{
                    if(pword===confirm_pword){
                        this.setState({ isNotPword: false });
                    }else{
                        this.setState({ isNotPword: true, pwErrMsg: "Password doesn't match!" });
                    }
                }
            })
        }
        else{
            this.setState({
                ...this.state,
                userPassDetails: {
                    ...this.state.userPassDetails,
                    [e.target.name] : e.target.value
                }
            })
        }
    }

    onChangeEditAcc = (e) => {
        if(e.target.name === "emailAddress" && e.target.value !== ""){
            this.setState({
                ...this.state,
                userAccDetails: {
                    ...this.state.userAccDetails,
                    [e.target.name] : e.target.value
                }
            },
            () => {
                let email = String(this.state.userAccDetails.emailAddress);
                // eslint-disable-next-line
                if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
                    this.setState({ 
                        ...this.state,
                        isEmail: true 
                    });
                }
                else{
                    this.setState({ 
                        ...this.state,
                        isEmail: false 
                    });
                }
            })
        }
        else if(e.target.name === "mobile" || e.target.name === "work" || e.target.name === "home"){
            if(e.target.value === ""){
                this.setState({
                    ...this.state,
                    userAccDetails: {
                        ...this.state.userAccDetails,
                        [e.target.name]: e.target.value
                    },
                    isDeletePhones: {
                        ...this.state.isDeletePhones,
                        [e.target.name+"Phone"] : true
                    }
                })
            }
            else{
                this.setState({
                    ...this.state,
                    userAccDetails: {
                        ...this.state.userAccDetails,
                        [e.target.name]: e.target.value
                    },
                    isDeletePhones: {
                        ...this.state.isDeletePhones,
                        [e.target.name+"Phone"] : false
                    }
                })
            }
        }
        else if(e.target.name === "answer"){
            const value = (e.target.value).replace(/[^a-zA-Z0-9 ]/g, "")
            this.setState({
                ...this.state,
                userAccDetails: {
                    ...this.state.userAccDetails,
                    [e.target.name]: value
                }
            })
        }
        else{
            this.setState({
                ...this.state,
                userAccDetails: {
                    ...this.state.userAccDetails,
                    [e.target.name]: e.target.value
                }
            })
        }
    }

    stateOnChange = (e) => {
        var select = document.getElementById("validationCustom08")
        this.setState({ 
            ...this.state,
            userAccDetails: {
                ...this.state.userAccDetails, 
                stateDesc: select[select.selectedIndex].text,
                stateInitials: e.target.value
            } 
        }) 
    }

    questionOnChange = (e) => {
        var select = document.getElementById("validationCustom13")
        this.setState({ 
            ...this.state,
            userAccDetails: {
                ...this.state.userAccDetails, 
                // characteristicValue: select[select.selectedIndex].text,
                characteristicValue: e.target.value
            } 
        }) 
    }

    showChangePass = e => {
        e.preventDefault()
        this.setState({isEditingPass:!this.state.isEditingPass})
    }

    handleSubmit(event) {
        event.preventDefault();
        const form = event.currentTarget;
        if(this.state.isEditingPass === false){
            if (form.checkValidity() === false){ 
                event.preventDefault();
                event.stopPropagation();
                this.setState({
                    ...this.state,
                    validatedEditAcc: true
                })
            }
            else{
                this.setState({
                    ...this.state,
                    isProcessing: true
                })
                const postData = {
                    personId: this.state.userAccDetails.personId,
                    accountId: this.state.userAccDetails.accountId,
                    emailAddress: this.state.userAccDetails.emailAddress,
                    address1: this.state.userAccDetails.address1,
                    address2: this.state.userAccDetails.address2,
                    city: this.state.userAccDetails.city,
                    postal: this.state.userAccDetails.postal,
                    stateInitials: this.state.userAccDetails.stateInitials,
                    stateDesc: this.state.userAccDetails.stateDesc,
                    country: this.state.userAccDetails.country,
                    home: (this.state.userAccDetails.home === this.props.dashboard.userAccountDetails.homePhone) ? '' : this.state.userAccDetails.home,
                    mobile: (this.state.userAccDetails.mobile === this.props.dashboard.userAccountDetails.mobilePhone) ? '' : this.state.userAccDetails.mobile,
                    work: (this.state.userAccDetails.work === this.props.dashboard.userAccountDetails.workPhone) ? '' : this.state.userAccDetails.work,
                    isDeletedHome: this.state.isDeletePhones.homePhone,
                    isDeletedMobile: this.state.isDeletePhones.mobilePhone,
                    isDeletedWork: this.state.isDeletePhones.workPhone,
                    homeSeq: (this.state.userAccDetails.home === this.props.dashboard.userAccountDetails.homePhone) ? '' : this.props.dashboard.userAccountDetails.homePhoneSeq,
                    mobileSeq: (this.state.userAccDetails.mobile === this.props.dashboard.userAccountDetails.mobilePhone) ? '' : this.props.dashboard.userAccountDetails.mobilePhoneSeq,
                    workSeq: (this.state.userAccDetails.work === this.props.dashboard.userAccountDetails.workPhone) ? '' : this.props.dashboard.userAccountDetails.workPhoneSeq,
                    oldDateEmail: this.state.userAccDetails.oldDateEmail,
                    oldDateQuestion: this.state.userAccDetails.oldDateQuestion,
                    oldDateAnswer: this.state.userAccDetails.oldDateAnswer,
                    characteristicValue: this.state.userAccDetails.characteristicValue,
                    answer: this.state.userAccDetails.answer
                }
                this.props.updateUserDetails(postData)
                .then((response) => {
                    this.setState({
                        ...this.state,
                        isProcessing: false
                    })
                    if(response.result.status === "True")
                    {
                        this.props.showMessage(false, response.result.description)
                        this.setState({
                            ...this.state,
                            isProcessing: false
                        })
                    }
                    else{
                        this.props.showMessage(true, response.result.description)
                        this.setState({
                            ...this.state,
                            isProcessing: false
                        })
                    }
                })
                .catch((error) => {
                    this.props.showMessage(true, "Server error! Please try again later!")
                    this.setState({
                        ...this.state,
                        isProcessing: false
                    })
                })
            }
        }
        else{
            if (form.checkValidity() === false){ 
                event.preventDefault();
                event.stopPropagation();
                this.setState({
                    ...this.state,
                    validatedChangePass: true
                })
            }
            else{
                this.setState({
                    ...this.state,
                    isProcessing: true
                })
                const postData = {
                    personId: this.state.userAccDetails.personId,
                    oldPassword: this.state.userPassDetails.oldPass,
                    password: this.state.userPassDetails.newPass
                }
                this.props.updateUserPassword(postData)
                .then((response) => {
                    if(response.result.status === "True")
                    {
                        this.props.showMessage(false, response.result.description)
                        this.setState({
                            ...this.state,
                            isProcessing: false
                        })
                    }
                    else{
                        this.props.showMessage(true, response.result.description)
                        this.setState({
                            ...this.state,
                            isProcessing: false
                        })
                    }
                })
                .catch((error) => {
                    this.props.showMessage(true, "Server error! Please try again later!")
                    this.setState({
                        ...this.state,
                        isProcessing: false
                    })
                })
            }
        }
        
        // this.setState({ validated: true });
    }

    render() { 
        const { validatedEditAcc, validatedChangePass } = this.state;
        //customer name modification
        var fullName   = this.state.userAccDetails.fullName;
        var firstName = (fullName.substr(0,fullName.indexOf(' ')).trim()).split(" ")[0]
        var lastName  = fullName.substr(fullName.indexOf(' ')+1)
        if(fullName.includes(',') || lastName === ""){
            firstName = (fullName.substr(fullName.indexOf(',')+1).trim()).split(" ")[0]
            lastName  = fullName.substr(0,fullName.indexOf(','))
        }
        const accountId = this.state.userAccDetails.accountId;
        const username = this.state.userAccDetails.username;
        //cutomer details
        const address1 = this.state.userAccDetails.address1;
        const address2 = this.state.userAccDetails.address2;
        const city = this.state.userAccDetails.city;
        const stateDesc = this.state.userAccDetails.stateDesc;
        const stateInitials = this.state.userAccDetails.stateInitials;
        const postal = this.state.userAccDetails.postal;
        const country = this.state.userAccDetails.country;
        const emailAddress = this.state.userAccDetails.emailAddress;
        const home = this.state.userAccDetails.home;
        const work = this.state.userAccDetails.work;
        const mobile = this.state.userAccDetails.mobile;
        const quesCharValue = this.props.dashboard.userOldDataDetails.oldCharValueSecuQues
        const answer = this.state.userAccDetails.answer;
        // for blur textfields
        const blurStyle = {
            color: "transparent",
            textShadow: "0 0 5px rgba(0,0,0,0.5)"
        }

        const errPwordMsg = this.state.isNotPword === true ? this.state.pwErrMsg+" " : 'Please enter your password.';
        const errPwordInpClass = this.state.isNotPword === true ? 'invalid form-control' : 'valid form-control';
        const errPwordMsgDisp = this.state.isNotPword === true ? 'invalid_msg invalid-feedback' : 'invalid-feedback';

        const errEmailMsg = this.state.isEmail === true ? "Please enter your Email Address. " : "Please enter a valid Email Address.";
        const errEmailInpClass = this.state.isEmail === true ? 'valid form-control' : 'invalid form-control';
        const errEmailMsgDisp = this.state.isEmail === true ? ' invalid-feedback' : 'invalid_msg invalid-feedback';
        return (  
            this.state.isEditingPass === true ?
            <React.Fragment>
                <div className="row pb-4">
                    <div className="col-lg-8">
                        <h3 className="primary-font-color">Change Password</h3>
                    </div>
                    <div className="col-lg-4">
                        <LogoutButton /> 
                    </div>
                </div>
                <div className="edit-form">
                    <Form
                        autocomplete="off"
                        noValidate
                        validated={validatedChangePass}
                        onSubmit={e => this.handleSubmit(e)}
                    >
                        <Form.Row>
                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                <Form.Label>Current Password</Form.Label>
                                <Form.Control
                                    required
                                    type="password"
                                    name="oldPass"
                                    className="valid"
                                    value={this.state.userPassDetails.oldPass}
                                    onChange={this.onChangePassword.bind(this)}
                                />
                                <Form.Control.Feedback id="feedback" type="invalid">
                                    Please enter your Current Password.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                <Form.Label>New Password</Form.Label>
                                <Form.Control
                                    required
                                    type="password"
                                    name="newPass"
                                    className={errPwordInpClass}
                                    value={this.state.userPassDetails.newPass}
                                    onChange={this.onChangePassword.bind(this)}
                                />
                                <Form.Label>(Your password must be between 8 – 15 characters long)</Form.Label>
                                <Form.Control.Feedback id="feedback" className={errPwordMsgDisp} type="invalid">
                                    {errPwordMsg}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="6" controlId="validationCustom03">
                                <Form.Label>Confirm Password </Form.Label>
                                <Form.Control
                                    required
                                    type="password"
                                    name="confirmPass"
                                    className={errPwordInpClass}
                                    value={this.state.userPassDetails.confirmPass}
                                    onChange={this.onChangePassword.bind(this)}
                                    
                                />
                                <Form.Control.Feedback id="feedback" type="invalid">
                                    Please confirm your New Password.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <div id="form-actions" className="row">
                            <div className="col-lg-6">
                            </div>
                            <div className="col-lg-6">
                                <div className="row">
                                    <div id="save" className="col-lg-6 order-lg-last">
                                        {
                                            !this.state.isProcessing ? 
                                                <button type="submit" className="btn btn-primary regular-font-size font-bold">Save Changes</button>
                                            :  
                                                <Button variant="primary" disabled>
                                                    <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    />
                                                    Loading...
                                                </Button>
                                        }
                                    </div>
                                    <div id="cancel" className="col-lg-6 order-lg-first">
                                        <a href="/dashboard" className="dark-font-color regular-font-size" onClick={this.showChangePass.bind(this)}><u>Cancel</u></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </React.Fragment>
            :
                (this.state.isEditLoading === true) ? 
                <Loading />
                :
                <React.Fragment>
                    <div className="row pb-4">
                        <div className="col-lg-8">
                            <h3 className="primary-font-color">Edit Account</h3>
                        </div>
                        <div className="col-lg-4">
                            <LogoutButton />
                        </div>
                    </div>
                    <div className="edit-form">
                        <Form
                            autocomplete="off"
                            noValidate
                            validated={validatedEditAcc}
                            onSubmit={e => this.handleSubmit(e)}
                        >
                            <Form.Row>
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Username </Form.Label>
                                    <Form.Control
                                        disabled
                                        type="text"
                                        name="username"
                                        value={username}
                                    />
                                    <Form.Control.Feedback id="feedback" type="invalid">
                                        {/* {errUsernameMsg} */}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Customer Name </Form.Label>
                                    <Form.Control
                                        disabled
                                        type="text"
                                        name="firstName"
                                        className="valid"
                                        value={fullName}
                                        onChange={this.onChangeEditAcc.bind(this)}
                                    />
                                    <Form.Control.Feedback id="feedback" type="invalid">
                                        Please enter your First Name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} md="6" controlId="validationCustom12">
                                    <Form.Label>Email Address </Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="emailAddress"
                                        value={emailAddress}
                                        onChange={this.onChangeEditAcc.bind(this)}
                                        className={errEmailInpClass}
                                    />
                                    <Form.Control.Feedback id="feedback" type="invalid" className={errEmailMsgDisp}>
                                        {errEmailMsg}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom10">
                                    <Form.Label>Home Phone </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="home"
                                        value={home}
                                        onChange={this.onChangeEditAcc.bind(this)}
                                    />
                                    <Form.Control.Feedback id="feedback" type="invalid">
                                        Please enter Home Phone.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} md="6" controlId="validationCustom11">
                                    <Form.Label>Mobile Phone </Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="mobile"
                                        value={mobile}
                                        onChange={this.onChangeEditAcc.bind(this)}
                                    />
                                    <Form.Control.Feedback id="feedback" type="invalid">
                                        Please enter Mobile Phone.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom12">
                                    <Form.Label>Work Phone</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="work"
                                        value={work}
                                        onChange={this.onChangeEditAcc.bind(this)}
                                    />
                                    <Form.Control.Feedback id="feedback" type="invalid">
                                        Please enter Work Phone.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-4">
                                <Form.Group as={Col} md="6" controlId="validationCustom13">
                                    <Form.Label>Security Question </Form.Label>
                                    <Form.Control as="select" name="security_question" ref="sec_ques" defaultValue={quesCharValue} onChange={this.questionOnChange.bind(this)}>
                                        { _.map(this.props.dashboard.securityQuestions, (item, index) => {
                                            return(
                                            (item.characteristicValue === quesCharValue) ? 
                                            <option key={index} value={item.characteristicValue}>{item.description}</option>
                                            :
                                            (item.characteristicValue !== quesCharValue) ? 
                                            <option key={index} value={item.characteristicValue}>{item.description}</option>
                                            :
                                            ''
                                            )
                                        })}
                                    </Form.Control>
                                    <Form.Control.Feedback id="feedback" type="invalid">
                                        Please Select Question.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom14">
                                    <Form.Label>Answer </Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="answer"
                                        value={answer}
                                        onChange={this.onChangeEditAcc.bind(this)}
                                    />
                                    <Form.Control.Feedback id="feedback" type="invalid">
                                        Please enter Answer.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <br />
                            <br />
                            <hr />
                                <div className="change-pass">
                                    <div className="float-left">
                                        <h5>Change Password</h5>
                                    </div>
                                    <div className="float-right">
                                        <a href="/" onClick={this.showChangePass.bind(this)} className="secondary-font-color"><span><img src={process.env.PUBLIC_URL + '/images/edit.png'} alt="edit" /></span> Edit</a>
                                    </div>
                                </div>
                            <hr />
                            <div id="form-actions" className="row">
                                <div className="col-lg-6">
                                </div>
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div id="save" className="col-lg-6 order-lg-last">
                                            {
                                                !this.state.isProcessing ? 
                                                    <button type="submit" className="btn btn-primary regular-font-size font-bold" disabled={this.state.isProcessing}>Save Changes</button>
                                                :  
                                                    <Button variant="primary" disabled>
                                                        <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        />
                                                        Loading...
                                                    </Button>
                                            }
                                        </div>
                                        <div id="cancel" className="col-lg-6 order-lg-first">
                                            <a id="btnCancelEditAcc" href="/dashboard" className="dark-font-color regular-font-size"><u>Cancel</u></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    dashboard: state.dashboard
});

export default connect(mapStateToProps, { showMessage, fetchOldUserDetails, getSequQuestions, getCountry, updateUserDetails, updateUserPassword })(EditAccount);