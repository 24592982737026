import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    searchString
} from '../actions/dashboardActions';
import { Redirect } from 'react-router-dom';
class PaymentUserFailed extends Component {
    state = { 
        displayLogin: false
    }
    showLogin = () => {
        this.setState({
            displayLogin: true
        })
    }
    getFieldValue = (str, result) => {
        return this.props.searchString(str, result)
    }
    getCardType = (code) => {
        if(code === "CCM"){
            return "Mastercard"
        }
        else if(code === "CCV"){
            return "Visa"
        }
        else if(code === "CCD"){
            return "Discover"
        }
        else{
            return "NO CARD FOUND!"
        }
    }
    render() { 
        const result = this.props.result;
        let today = new Date();
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        let date = today.getDate()+' '+months[today.getMonth()]+' '+today.getFullYear();
        let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        let dateTime = date+' '+time;
        if(this.state.displayLogin) {
            return <Redirect to="/" />
        }
        return (  
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="text-center">
                            <h3><span style={{color: '#c40808'}}><i class="fas fa-times-circle"></i></span> Sorry, your payment was not processed.</h3>
                        </div>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-lg-12">
                        <div className="text-center">
                            <label className="font-bold">There was a problem processing your credit card information.</label>
                            <label className="font-bold">If you repeatedly see this message, complete your payment by contacting GPA directly.</label>
                        </div>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-lg-12">
                        <div className="bordered">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div>
                                            <label><strong>CARD & AMOUNT:</strong> {this.getCardType(result.payment_source) + " $ " + result.dollar_amount + " " + result.currency}</label>
                                            <label><strong>CARDHOLDER NAME:</strong> {result.card_holders_name}</label>
                                            <label><strong>CARD NUMBER:</strong> {result.card_number}</label>
                                            <label><strong>DATE/TIME:</strong>{" "+dateTime}</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="left-auto">
                                            <label><strong>REFERENCE #:</strong>{this.getFieldValue("REFERENCE #", result)}</label>
                                            <label><strong>AUTHORIZATION #:</strong>{this.getFieldValue("AUTHOR. #", result)}</label>
                                            <label><strong>PAYMENT ID: </strong>{result.paymentId}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-lg-12 text-center">
                        <button id="btn-download" type="submit" class="btn regular-font-size font-bold btn-left" onClick={this.showLogin.bind(this)}>Go Back</button>
                    </div>
                    {/* <div className="col-lg-6 text-left">
                        <button id="btn-member-dashboard" type="submit" class="btn regular-font-size font-bold btn-right" onClick={() => { localStorage.setItem('reload','true');window.close();}}>Close This Window</button>
                    </div> */}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    dashboard: state.dashboard
});

export default connect(mapStateToProps, { searchString })(PaymentUserFailed);