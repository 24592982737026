import React, { Component } from 'react';

class Stepper extends Component {
    state = { 
        currentStep: 1,
    }
    componentDidMount() {
        if(window.location.pathname == "/payment-arrangement/use-deposit"){
            this.setCurrentStep(1);
        }
        else if(window.location.pathname == "/payment-arrangement/pay-today"){
            this.setCurrentStep(2);
        }
        else if(window.location.pathname == "/payment-arrangement/apply-for-payment-arrangement"){
            this.setCurrentStep(3);
        }
        else if(window.location.pathname == "/payment-arrangement/print-sign-send"){
            this.setCurrentStep(4);
        }
    }
    setCurrentStep = (step) => {
        this.setState({
            ...this.state,
            currentStep: step
        });
    }
    render() { 
        return ( 
            <React.Fragment>
                <div id="payment-arrangement-stepper" className="container">
                    <div className="row">
                        <div className="col-lg-12 my-auto text-center">
                            <div className="step-wrap">
                                {
                                    this.state.currentStep >= 1 ?
                                        <img src={process.env.PUBLIC_URL + '/images/PA-STEP-ICONS/use-deposit-selected.png'} alt="logout"/>
                                    : 
                                        <span className="plain-circle">
                                            &#9711;
                                        </span>
                                }
                            </div>
                            <div className="step-wrap">
                                {
                                    this.state.currentStep >= 2 ?
                                        <img src={process.env.PUBLIC_URL + '/images/PA-STEP-ICONS/pay-today-selected.png'} alt="logout"/>
                                    : 
                                        <img src={process.env.PUBLIC_URL + '/images/PA-STEP-ICONS/pay-today-unselected.png'} alt="logout"/>
                                }
                            </div>
                            <div className="step-wrap">
                                {
                                    this.state.currentStep >= 3 ?
                                        <img src={process.env.PUBLIC_URL + '/images/PA-STEP-ICONS/apply-selected.png'} alt="logout"/>
                                    : 
                                        <img src={process.env.PUBLIC_URL + '/images/PA-STEP-ICONS/apply-unselected.png'} alt="logout"/>
                                }
                            </div>
                            <div className="step-wrap last-step">
                                {
                                    this.state.currentStep >= 4 ?
                                        <img src={process.env.PUBLIC_URL + '/images/PA-STEP-ICONS/print-sign-send-selected.png'} alt="logout"/>
                                    : 
                                        <img src={process.env.PUBLIC_URL + '/images/PA-STEP-ICONS/print-sign-send-unselected.png'} alt="logout"/>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
 
export default Stepper;