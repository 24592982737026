import axios from "axios";
import { SAVE_PA_ACCOUNTS } from "./types";

import curlirize from "axios-curlirize";
import * as moment from "moment";

const dashboardBaseURL = process.env.REACT_APP_DASHBOARD_API_URL;
const loginSignupBaseURL = process.env.REACT_APP_LOGIN_SIGNUP_API_URL;
const paynowBaseURL = process.env.REACT_APP_PAYNOW_API_URL;

export const updateFilePath = (path) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        loginSignupBaseURL + "/api/v1/update-file-path",
        {
          file_path: path,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getFilePath = () => (dispatch) => {
  return axios
    .get(loginSignupBaseURL + "/api/v1/get-file-path", {
      headers: { "Content-Type": "application/json" },
    })
    .then((response) => {
      return response.data;
    });
};

export const generateFpHash = (item) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        paynowBaseURL + "/api/v1/get-fphash-payment-arr",
        {
          accountId: item.accountId,
          // login: "WSP-GPA-O-keKmtQDXag",
          // login: "HCO-GUAM-12",
          login: "HCO-GUAM-663",
          // type: item.className === "RESID" ? "RESID" : "COMM",
          amount: item.amountToBePay,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
//
export const stopDeposit = (accountId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        dashboardBaseURL + "/api/v1/stop-deposit",
        {
          accountId: accountId,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(function (response) {
        response.data.accountId = accountId;
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createCase = (item) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        dashboardBaseURL + "/api/v1/create-case",
        {
          accountId: item.accountId,
          name: item.fullName,
          emailAddress: item.emailAddress,
          paymentArrAmount: item.newBalanceAfterPay,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(function (response) {
        response.data.accountId = item.accountId;
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getPaDetails = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        dashboardBaseURL + "/api/v1/details-payment-arr",
        {
          personId: localStorage.getItem("personId"),
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(function (response) {
        let caseData = [];
        try {
          const { customerContactList } = response.data.data;
          if (Array.isArray(customerContactList)) {
            caseData = customerContactList.map(
              ({
                customerContactId,
                customerContactCharsList,
                customerContactStatus,
              }) => ({
                customerContactId: customerContactId,
                accountId:
                  customerContactCharsList[0].characteristicType == "CI_ACCT"
                    ? customerContactCharsList[0].characteristicValue
                    : customerContactCharsList[1].characteristicValue,
                caseId:
                  customerContactCharsList[0].characteristicType == "CMCASEID"
                    ? customerContactCharsList[0].characteristicValue
                    : customerContactCharsList[1].characteristicValue,
                customerContactStatus: customerContactStatus,
                fileData: "",
              })
            );
          } else {
            caseData.push({
              customerContactId: customerContactList.customerContactId,
              accountId:
                customerContactList.customerContactCharsList[0]
                  .characteristicType == "CI_ACCT"
                  ? customerContactList.customerContactCharsList[0]
                      .characteristicValue
                  : customerContactList.customerContactCharsList[1]
                      .characteristicValue,
              caseId:
                customerContactList.customerContactCharsList[0]
                  .characteristicType == "CMCASEID"
                  ? customerContactList.customerContactCharsList[0]
                      .characteristicValue
                  : customerContactList.customerContactCharsList[1]
                      .characteristicValue,
              customerContactStatus: customerContactList.customerContactStatus,
              fileData: "",
            });
          }
          // caseData = caseData.reduce((unique, o) => {
          //     if(!unique.some(obj => obj.accountId === o.accountId)) {
          //       unique.push(o);
          //     }
          //     return unique;
          // },[]);
        } catch (ex) {
          caseData = [];
        }
        resolve(caseData);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const uploadPaForm = (item) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("customerContactId", item.customerContactId);
    formData.append("pdf", item.fileData);
    formData.append(
      "fileName",
      `CASE-${item.caseId}_ACCT-${
        item.accountId
      }-${new Date().getTime()}.${item.fileName.split(".").pop()}`
    );
    console.log("myFilemyFilemyFilemyFile", formData.get("fileName"));
    axios
      .post(dashboardBaseURL + "/api/v1/payment-arr-update-details", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getLatestBalance = (accountId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        dashboardBaseURL + "/api/v1/payment-arr-list",
        {
          accountIds: [accountId],
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(function (response) {
        const paArrList = response.data;
        let depAmount = 0;
        let balance = 0;
        let depCredit = 0;
        for (let count = 0; count < paArrList.length; count++) {
          const result = paArrList[count].result;
          for (let count1 = 0; count1 < result.length; count1++) {
            if (
              result[count1].saType.charAt(0) == "E" &&
              result[count1].status == "20"
            ) {
              balance = result[count1].currentBalance;
            }
            if (
              result[count1].saType.substring(0, 4) == "DEP-" &&
              result[count1].status == "20"
            ) {
              const payoffBalance = result[count1].payoffBalance;
              depCredit = parseFloat(result[count1].currentBalance);
              depAmount =
                payoffBalance > 0 ? 0 : parseFloat(payoffBalance * -1);
            }
          }
        }
        resolve({ depAmount, balance, depCredit });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const paArrList =
  (accountIds, customerName, emailAddress) => (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          dashboardBaseURL + "api/v1/payment-arr-list",
          {
            accountIds: accountIds,
          },
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((response) => {
          const paArrList = response.data;
          console.log("paArrList", paArrList);
          let paAccounts = [];
          for (let count = 0; count < paArrList.length; count++) {
            let depAmount = 0;
            let balance = 0;
            let depCredit = 0;
            let depData = [];

            const result = paArrList[count].result;
            for (let count1 = 0; count1 < result.length; count1++) {
              if (
                result[count1].saType.charAt(0) == "E" &&
                result[count1].status == "20"
              ) {
                balance = result[count1].currentBalance;
              }
              if (
                result[count1].saType.substring(0, 4) == "DEP-" &&
                result[count1].status == "20"
              ) {
                //push all fetched deposit data
                depData.push(result[count1]);
              }
            }
            console.log("depData", depData);

            if (depData.length > 0) {
              //get the oldest
              const creationDate = depData.map((item, index) => item.startDate);
              const orderedDates = creationDate.sort((a, b) => {
                return Date.parse(a) - Date.parse(b);
              });
              depData.filter(({ startDate }) => startDate == orderedDates[0]);
              const arrFirstDep = depData.filter(
                ({ startDate }) => startDate == orderedDates[0]
              );
              const payoffBalance = arrFirstDep[0].payoffBalance;
              depCredit = arrFirstDep[0].currentBalance;
              depAmount =
                payoffBalance > 0 ? 0 : parseFloat(payoffBalance * -1);
            } else {
              //no dep data! btch please!
            }

            let applyDeposit = false;
            // if(balance > 0 && depAmount > 0 && depCredit == 0){
            //     applyDeposit = true;
            // }
            if (parseFloat(balance - depAmount) > 0) {
              paAccounts.push({
                checked: false,
                validDepAmount: true,
                accountId: paArrList[count].accountId,
                balance: parseFloat(balance),
                newBalanceAfterPay: applyDeposit
                  ? parseFloat(balance - depAmount)
                  : parseFloat(balance),
                depAmount: depAmount,
                amountToBePay: 0,
                applyDeposit: applyDeposit,
                fullName: customerName,
                requestExtension: false,
                emailAddress: emailAddress,
                isPaid: false,
                depCredit: parseFloat(depCredit),
              });
            }
          }
          return paAccounts;
        })
        .then((paAccounts) => {
          dispatch({
            type: SAVE_PA_ACCOUNTS,
            payload: paAccounts,
          });
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
