import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { 
    fetchBillsList 
} from '../actions/dashboardActions';
import _ from 'lodash';
import Loading from './loading';
import BtnViewBill from './btnViewBill'
import { 
    showMessage
} from '../actions/popupMessageActions';
import NumberFormat from 'react-number-format';

class ModalMyBills extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
          show: false,
          isLoading: true
        };
    }
    showModal = (e) => {
        this.setState({ show: true });
        if(this.state.isLoading){
            this.props.fetchBillsList(this.props.accId)
            .then(() => {
                this.setState({ isLoading: false });
            })
            .catch((error)=>{
                this.props.showMessage(true, 'Server failed! Try again later!');
            })
        }
    }
    hideModal = (e) => {
        this.setState({ show: false, isLoading: true });
    }
    render() { 
        return ( 
            <React.Fragment>
                <Modal
                    id="my-bills-modal"
                    show={this.state.show}
                    onHide={this.hideModal.bind(this)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    centered
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.isLoading === true ?
                            <center><h5>Please wait...</h5></center>
                            :
                            <React.Fragment>
                            <div className="my-bills-title">
                                <label className="light-font-color font-bold m-0">Energy Statements</label>
                            </div>
                            <div id="my-bills-table">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <table className="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                <th scope="col" className="text-center">Date</th>
                                                <th scope="col" className="text-center">Amount</th>
                                                {
                                                    this.props.mode === "prepaid" ?
                                                        null
                                                    : 
                                                    <th scope="col" className="text-center">View</th>
                                                }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { _.map(this.props.dashboard.billList, (item, index) => {
                                                    return(
                                                        index < 18 ?
                                                            <tr key={index}>
                                                                <td>{item.billDate}</td>
                                                                <NumberFormat 
                                                                    value={parseFloat(item.totalAmount)} 
                                                                    displayType={'text'} 
                                                                    thousandSeparator={true} 
                                                                    prefix={'$ '} 
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true}
                                                                    renderText={value => <td className="text-right">{value}</td>} 
                                                                />
                                                                {
                                                                    this.props.mode === "prepaid" ?
                                                                        null
                                                                    : 
                                                                    <BtnViewBill billId={item.billID} arrearsDate={item.arrearsDate} accId={this.props.accId}/>
                                                                }
                                                            </tr>
                                                        :
                                                            ""
                                                    )
                                                })}    
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            </React.Fragment>
                        }
                    </Modal.Body>
                </Modal>
                <p style={{margin: "0"}} onClick={this.showModal.bind(this)}><img src={process.env.PUBLIC_URL + '/images/sticky-note-regular.svg'} width="16px"/> View Statement</p>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    dashboard: state.dashboard
});

export default connect(mapStateToProps, { 
    fetchBillsList,  
    showMessage,
})(ModalMyBills);