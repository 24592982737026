import { 
    SHOW_MESSAGE, 
    HIDE_MESSAGE
} from '../actions/types';

const initialState = {
    modalConfig: {
        show: false,
        isError: false,
        message: "",
    }
}

export default function(state = initialState, action){
    switch(action.type){
        case SHOW_MESSAGE:
            return {
                ...state,
                modalConfig: {
                    ...state.modalConfig,
                    show: true,
                    isError: action.payload.isError,
                    message: action.payload.message
                }
            }
        case HIDE_MESSAGE:
            return{
                ...state,
                modalConfig: {
                    ...state.modalConfig,
                    show: false,
                    isError: action.payload.isError,
                    message: action.payload.message
                }
            }
        default:
            return state;
    }
}