import React, { Component } from 'react';
import Stepper from '../../components/PaymentArrangement/stepper';
import Header from '../../components/PaymentArrangement/header';
import { Table, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { 
    paArrList,
} from '../../actions/paymentArrangementActions';
import { 
    showMessage
} from '../../actions/popupMessageActions';
import { Redirect } from 'react-router-dom';

class UseDeposit extends Component {
    state = { 
        accountsResponse: []
    }
    componentDidMount(){
        this.setState({
            accountsResponse: this.props.location.state.paAccountsResponse
        }, () => {
            console.log(this.state.accountsResponse.length)
        })
    }
    applyDepositOnChange = (item) => {
        let accountsResponse = this.state.accountsResponse;
        for(let count = 0; count < accountsResponse.length; count++){
            if(accountsResponse[count].accountId == item.accountId){
                if(parseFloat(item.depAmount) > 0 && item.depCredit == 0){
                    const applyDepositChecked = !item.applyDeposit;
                    accountsResponse[count].applyDeposit = applyDepositChecked;
                    accountsResponse[count].newBalanceAfterPay = applyDepositChecked ? parseFloat(item.balance - item.depAmount) : parseFloat(item.balance);
                }else{
                    this.props.showMessage(true, 'Invalid deposit amount!');
                }
                break;
            }
        }
        this.setState({
            ...this.state,
            accountsResponse: accountsResponse
        }, () => {
            console.log('this.state.accountsResponse', this.state.accountsResponse)
        })
    }
    render() { 
        const priceSplitter = (number) => (number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
        const formatAmount = (amount) => {
            amount = parseFloat(Math.round(amount * 100) / 100).toFixed(2);
            return priceSplitter(amount);
        }
        if(this.state.displayPaInfo) {
            return <Redirect to={{
                pathname: "/payment-arrangement/", 
                state: {
                    accountSummary: this.props.location.state.accountSummary
                }
            }} />
        }
        if(this.state.displayPayToday) {
            return <Redirect to={{
                pathname: "/payment-arrangement/pay-today", 
                state: {
                    accountSummary: this.props.location.state.accountSummary,
                    paAccountsResponse: this.state.accountsResponse
                }
            }} />
        }
        return ( 
            <React.Fragment>
                <Header />
                <section id="payment-arrangement-use-deposit">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center mt-5 mb-4">
                                <h3 className="font-bold">Request for Payment Arrangement in 4 Easy Steps!</h3>
                            </div>
                        </div>
                    </div>
                    <Stepper />
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center mt-5">
                                <p className="font-bold m-0">Step 1: Use Existing Deposit</p>
                                <p>We are waiving the usual 12-month hold on your deposit if it is applied to your electric balance. Would you like to apply this amount to your existing balance?</p>
                            </div>
                            <div className="col-lg-9 text-center mt-5 mx-auto">
                                {
                                this.state.accountsResponse.length == 0 ?
                                <Spinner animation="border" size="md" variant="primary" />
                                :
                                <Table id="payment-arrangement-step-table" bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Account #</th>
                                            <th>Balance</th>
                                            <th>Deposit Amount</th>
                                            <th>Apply Deposit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.accountsResponse.map((item, index) => (
                                                <tr>
                                                    <td>{item.accountId}</td>
                                                    <td>{formatAmount(item.newBalanceAfterPay)}</td>
                                                    <td>{item.applyDeposit ? formatAmount(0) : item.depCredit > 0 ? formatAmount(0) : formatAmount(parseFloat(item.depAmount))}</td>
                                                    <td>
                                                        <input
                                                            className="react-switch-checkbox"
                                                            name="login"
                                                            id={item.accountId+"react-switch"}
                                                            type="checkbox"
                                                            checked={item.applyDeposit}
                                                            onChange={() => {this.applyDepositOnChange(item)}}
                                                        />
                                                        <label
                                                            className="react-switch-label"
                                                            htmlFor={item.accountId+"react-switch"}
                                                        >
                                                            <span className="react-switch-button" />
                                                        </label>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                                }
                            </div>
                            <div className="col-lg-12 mt-5 mb-5 text-center">
                                <div className="row">
                                    <div className="col-lg-3 ml-auto">
                                        <button 
                                            className="btn pa-btn-secondary"
                                            onClick={() => {this.setState({
                                                displayPaInfo: true
                                            })}}
                                        >
                                            Back
                                        </button>
                                    </div>
                                    <div className="col-lg-3 mr-auto">
                                        <button 
                                            className="btn pa-btn-primary"
                                            onClick={() => {this.setState({
                                                displayPayToday: true
                                            })}}
                                        >
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    paymentArrangement: state.paymentArrangement
});

export default connect(mapStateToProps, { 
    paArrList,
    showMessage
})(UseDeposit);
