import { 
    SAVE_PA_ACCOUNTS, 
} from '../actions/types';

const initialState = {
    paAccounts: []
}

export default function(state = initialState, action){
    switch(action.type){
        case SAVE_PA_ACCOUNTS:
            return {
                ...state,
                paAccounts: action.payload
            }
        default:
            return state;
    }
}