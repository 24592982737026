import { 
    SHOW_MESSAGE, 
    HIDE_MESSAGE
} from './types';

export const showMessage = (isError, message) => dispatch => {
    const postData = {
        isError: isError, 
        message: message
    }
    dispatch({
        type:    SHOW_MESSAGE,
        payload: postData
    })
}
export const hideMessage = () => dispatch => {
    const postData = {
        isError: false, 
        message: ""
    }
    dispatch({
        type:    HIDE_MESSAGE,
        payload: postData
    })
}