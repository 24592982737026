import React, { Component } from "react";
import { Col, Container, Row} from 'react-bootstrap';

class LessContentFooter extends Component {
  render() {
    return (
      <div id="footer-bottom-less-content">
        <section id="dashboard">
          <div className="container-fluid p-0">
              <footer id="footer-main">
                    <hr />
                    <div className="text-center float-left pl-5 pt-2">
                        <p className="m-0">All Rights Reserved © 2020 Guam Power Authority</p>
                    </div>
                    <div className="text-center float-right pr-5 pb-2">
                        <img src={process.env.PUBLIC_URL + '/images/guam-logo-small.png'} alt="guam-logo-small"></img>
                        <img src={process.env.PUBLIC_URL + '/images/guam-waterworks.png'} alt="guam-waterworks.png"></img>
                        <img src={process.env.PUBLIC_URL + '/images/guam-me-energy.png'} alt="guam-me-energy"></img>
                        <img src={process.env.PUBLIC_URL + '/images/guam-ccu.png'} alt="guam-ccu.png"></img>
                    </div>
                </footer>
              </div>
          </section>
        </div>
    );
  }
}

export default LessContentFooter;
