import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchBillsList, getViewBillData } from '../actions/dashboardActions';
import _ from 'lodash';
import LogoutButton from './logoutButton';
import sha256 from 'js-sha256'
class MyBills extends Component {
    constructor(props){
        super(props);
        this.state = {
            selectedItem: 0,
            itemCount: 0,
            selectedBillID: "",
            selectedBillDate: "",
        }
    }
    componentWillMount() {
        let totalItems = 0;
        for(var count1 = 0; count1 < this.props.dashboard.billList.length; count1++){
            for(var count2 = 0; count2 < this.props.dashboard.billList[count1].length; count2++){
                totalItems = totalItems + 1
            }
        }
        this.setState({
            ...this.state,
            itemCount: totalItems
        })
    }
    viewBill = (billID, billDate) => {
        this.setState({
            ...this.state,
            selectedBillID: billID,
            selectedBillDate: billDate,
        }, () => {
            this.generateViewBillURL()
        })
    }
    encode64(index) {
        let base64Char = "A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P," +
        "Q,R,S,T,U,V,W,X,Y,Z,a,b,c,d,e,f," +
        "g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v," +
        "w,x,y,z,0,1,2,3,4,5,6,7,8,9,+,/,="
        return (base64Char.split(","))[index];
    }
    generateViewBillURL() {
        console.clear()
        let d = new Date();
        const accID = this.props.dashboard.selectedAccountId;
        const startDate = this.state.selectedBillDate;
        const endDate = this.state.selectedBillDate;
        const saltKey = "KhKDFDS*(kjnskdfn~23$kjas*lkd"
        const utc = ( (d.getUTCHours()*60) + d.getUTCMinutes() )
        // const utc = "384"
        //sha256 -> utf8
        console.log("concatenated string")
        console.log(accID+startDate+endDate+utc+saltKey);
        console.log("utc hrs")
        console.log(d.getUTCHours())
        console.log("utc mins")
        console.log(d.getUTCMinutes())
        console.log("total utc")
        console.log(utc);
        // let strSha256 = sha256(accID+startDate+endDate+"358"+saltKey)
        let strSha256 = sha256(accID+startDate+endDate+utc+saltKey)
        // let hash256 = strSha256.replace(/\D/g,'');
        console.log("sha256")
        console.log(strSha256)
        //save every two chars into arr
        let arrSlicedHash256 = [];
        arrSlicedHash256 = strSha256.match(/.{1,2}/g);
        console.log(arrSlicedHash256)
        //binary
        let binary8Bits = ""
        for(let count = 0; count < arrSlicedHash256.length; count++){
            if(arrSlicedHash256[count] !== ""){
                for (let i=0; i < arrSlicedHash256[count].length; i++) {
                    if(!(parseInt((arrSlicedHash256[count])[i]) >= 0)){
                        let hex = "0"+(arrSlicedHash256[count])[i];
                        let dec = parseInt(hex,16);
                        binary8Bits+=parseInt(dec, 10).toString(2)
                    }
                    else{
                        binary8Bits+=((arrSlicedHash256[count])[i].charCodeAt(0).toString(2)).substring(2);
                    }
                }
                // if(!(count === arrSlicedHash256.length-1)){
                //     binary8Bits+=","
                // }
            }
        }
        console.log("8 bits")
        console.log(binary8Bits)
        let binary6Bits = (binary8Bits+"00").match(/.{1,6}/g);
        console.log("6 bits")
        console.log(binary6Bits)
        let final = ""
        for(let count = 0; count < binary6Bits.length; count++){
            final += this.encode64(parseInt(binary6Bits[count],2))
        }
        final += "="
        console.log("base64")
        console.log(final)
        console.log("URL compatible")
        console.log(encodeURIComponent(final))
        console.log("generated link")
        let generatedLink = "https://idoxs.prep.kubra.com/GPA/BillPopLogin.aspx?account="+accID+"&startdate="+startDate+"&enddate="+endDate+"&utc="+utc+"&id="+encodeURIComponent(final)
        console.log(generatedLink)
        window.open(
            generatedLink,
            '_blank' // <- This is what makes it open in a new window.
        );
        
    }
    handleNext = () => {
        if(this.state.selectedItem < this.props.dashboard.billList.length - 1)
        {
            this.setState({
                selectedItem: this.state.selectedItem + 1
            })
        }
    }
    handlePrev = () => {
        if(this.state.selectedItem > 0)
        {
            this.setState({
                selectedItem: this.state.selectedItem - 1
            })
        }
    }
    render() { 
        return (  
            <React.Fragment>
                <div className="row pb-4">
                    <div className="col-lg-8">
                        <h3 className="primary-font-color">My Bills</h3>
                    </div>
                    <div className="col-lg-4">
                        <LogoutButton />
                    </div>
                </div>
                <div className="row my-bills-info pb-4">
                    <div className="col-lg-12">
                        <p className="font-bold">Your bills for account {this.props.dashboard.selectedAccountId}</p>
                        <p>Click on the corresponding link next to a bill to view or save the bill as a PDF file.</p>
                    </div>
                </div>
                <div className="row my-bills-table">
                    <div className="col-lg-9">
                        <table className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                <th scope="col">Bill Date</th>
                                <th scope="col">Total Amount Due</th>
                                <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                { 
                                    _.map(this.props.dashboard.billList[this.state.selectedItem], (item, index) => {
                                        return(
                                        <tr key={index}>
                                            <td>{item.billDate}</td>
                                            <td>${item.totalAmount}</td> 
                                            <td className="secondary-font-color font-bold regular-font-size" onClick={() => this.viewBill(item.billID, item.arrearsDate)}>
                                                <p><span><img src={process.env.PUBLIC_URL + '/images/view.png'} alt="view-bill" /></span> View Bill</p>
                                            </td>
                                        </tr>
                                        )
                                    })
                                }    
                            </tbody>
                        </table>
                        <div className="pt-2">
                            {/* {this.state.arrMyBills[this.state.selectedItem]} */}
                            <div className="float-left">
                                <p>Showing {this.props.dashboard.billList[this.state.selectedItem].length} Results out of {this.state.itemCount}</p>
                            </div>
                            <div className="float-right">
                                <p><span className="fa fa-chevron-left prev" onClick={this.handlePrev}></span> {this.state.selectedItem+1 + ' / ' + this.props.dashboard.billList.length} <span className="fa fa-chevron-right next" onClick={this.handleNext}></span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    dashboard: state.dashboard
});

export default connect(mapStateToProps, { 
    fetchBillsList,  
    getViewBillData
})(MyBills);