import React, { Component } from 'react';
import MyAccDetails from './myAccDetails';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { 
    checkAccountNumber,
    getOtherDetails,
    getPremiseInfo,
    getAcovInfo
} from '../actions/loginAndSignUpActions';
import { getSequQuestions } from '../actions/dashboardActions';
import { Redirect } from 'react-router-dom';

class MyAccNumber extends Component {
    constructor(props) {
        super(props);
        this.state = {
            billAddressSource: '',
            showMessage: 'none',
            resultMessage: '',
            isSuccess: false,
            showSample: false,
            isLoading: false,
            accountId: '',
            displayLogin: false
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    componentWillMount() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    onSubmit = () => {
        this.setState({
            isLoading: true,
            showMessage: 'none'
        })
        this.props.checkAccountNumber(this.state.accountId)
        .then((response) => {
            if(response.status === "False"){
                //first check if response.data is array, if true get index 0
                if(Array.isArray(response.data)){
                    if(response.data[0].billAddressSource === 'PER'){
                        this.setState({
                            billAddressSource: 'PER'
                        })
                        this.props.getOtherDetails(response.data[0].personId)
                        .then(() => {
                            this.setState({
                                isSuccess: true
                            })
                        })
                    }
                    else if(response.data[0].billAddressSource === 'PREM'){
                        this.setState({
                            billAddressSource: 'PREM'
                        })
                        this.props.getOtherDetails(response.data[0].personId)
                        .then(() => {
                            this.props.getPremiseInfo(this.state.accountId)
                            .then(() => {
                                this.setState({
                                    isSuccess: true
                                })
                            })
                        })
                    }
                    else if(response.data[0].billAddressSource === 'ACOV'){
                        this.setState({
                            billAddressSource: 'ACOV'
                        })
                        const postData = response.data[0].personAddressOverride
                        this.props.getOtherDetails(response.data[0].personId)
                        .then(() => {
                            this.props.getAcovInfo(postData)
                            this.setState({
                                isSuccess: true
                            })
                        })
                    }
                    else{
                        this.setState({ showMessage: 'block', resultMessage: 'No bill address source found!', isLoading: false })
                    }
                }
                else{
                    if(response.data.billAddressSource === 'PER'){
                        this.setState({
                            billAddressSource: 'PER'
                        })
                        this.props.getOtherDetails(response.data.personId)
                        .then(() => {
                            this.setState({
                                isSuccess: true
                            })
                        })
                    }
                    else if(response.data.billAddressSource === 'PREM'){
                        this.setState({
                            billAddressSource: 'PREM'
                        })
                        this.props.getOtherDetails(response.data.personId)
                        .then(() => {
                            this.props.getPremiseInfo(this.state.accountId)
                            .then(() => {
                                this.setState({
                                    isSuccess: true
                                })
                            })
                        })
                    }
                    else if(response.data.billAddressSource === 'ACOV'){
                        this.setState({
                            billAddressSource: 'ACOV'
                        })
                        const postData = response.data.personAddressOverride
                        this.props.getOtherDetails(response.data.personId)
                        .then(() => {
                            this.props.getAcovInfo(postData)
                            this.setState({
                                isSuccess: true
                            })
                        })
                    }
                    else{
                        this.setState({ showMessage: 'block', resultMessage: 'No bill address source found!', isLoading: false })
                    }
                }
            }
            else{
                this.setState({ showMessage: 'block', resultMessage: response.description, isLoading: false })
            }
        })
        .catch((error)=>{
            console.log(error);
        })
    }
    onChange = (e) => {
        this.setState({
            ...this.state,
            accountId: e.target.value
        })
    }
    showLogin = () => {
        this.setState({
            displayLogin: true
        })
    }
    handleClose() {
        this.setState({ showSample: false });
    }
    
    handleShow(e) {
        e.preventDefault();
        this.setState({ showSample: true });
    }
    render() { 
        if(this.state.displayLogin) {
            return <Redirect to="/" />
        }
        return ( 
            (this.state.isSuccess === true) ?
            <MyAccDetails billAddressSource={this.state.billAddressSource} accountId={this.state.accountId}/>
            :
            <React.Fragment>
                <Modal show={this.state.showSample} onHide={this.handleClose} dialogClassName="hint-modal" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Body>
                        <div className="hint">
                            <img src={process.env.PUBLIC_URL + '/images/find-account-number.png'} alt="find-account-number"/>
                        </div>
                    </Modal.Body>
                </Modal>
                <section id="sign-up-title">
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-lg-12 text-center">
                                <h4 className="primary-font-color">Create Account</h4>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="sign-up-stage">
                    <div className="container">
                        <div className="row justify-content-md-center rounded-left rounded-right border-style">
                            <div className="col-lg-4 p-0">
                                <div class="content selected rounded-left">
                                    <label>
                                        <span><img src={process.env.PUBLIC_URL + '/images/sign-up-1-light.png'} alt="sign-up-1-light"/></span> 
                                        My Account Number
                                    </label>
                                </div>
                            </div>
                            <div className="col-lg-4 p-0">
                                <div class="content border-left-right">
                                    <label className="dark-font-color">
                                        <span><img src={process.env.PUBLIC_URL + '/images/sign-up-2-dark.png'} alt="sign-up-2-dark"/></span> 
                                        My Account Details
                                    </label>
                                </div>
                            </div>
                            <div className="col-lg-4 p-0">
                                <div className="content rounded-right">
                                    <span><img src={process.env.PUBLIC_URL + '/images/sign-up-3-dark.png'} alt="sign-up-3-dark"/></span> 
                                    <label className="dark-font-color">
                                        My Login Details
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="sign-up-form">
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-lg-12">
                                <div className="sign-up-result" style={{ display: this.state.showMessage }}>
                                    <div className="col-lg-12 text-center">
                                        <label className="secondary-font-color font-bold">{ this.state.resultMessage }</label>
                                    </div>
                                </div>
                                <div className="form-wrap">
                                    <div className="form-lablel-wrap">
                                        <label className="dark-font-color font-bold">Enter your 10-digit account number <span className="secondary-font-color font-bold">*</span></label>
                                    </div>
                                    <div className="form-input-btn-wrap">
                                        <input name="accountId" type="text" className="form-input" onChange={this.onChange} required/>
                                        <button type="submit" onClick={this.onSubmit} className="btn btn-primary form-button regular-font-size font-bold" disabled={this.state.isLoading}>{(this.state.isLoading) ? 'Verifying' : 'Continue'}</button>
                                    </div>
                                    <div className="form-link-wrap">
                                        <a href="/" onClick={this.handleShow} className="primary-font-color regular-font-size">Where can I find my account number?</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="sign-up-form-bottom">
                    <div className="container">
                        <div className="row content-wrap">
                            <div className="col-lg-6 left">
                                <label>Fields marked as * are mandatory</label>
                            </div>
                            <div className="col-lg-6 right">
                                <label>Already Registered? <span className="primary-font-color" onClick={this.showLogin.bind(this)}>Login Here</span></label>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
 
export default connect(null, { 
    checkAccountNumber,
    getOtherDetails,
    getPremiseInfo,
    getAcovInfo,
    getSequQuestions
})(MyAccNumber);