import React, { Component } from 'react';
import { Form, Spinner, Button } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { 
    submitHelpAndSupport,
} from '../actions/dashboardActions';
import LogoutButton from './logoutButton';
import { 
    showMessage
} from '../actions/popupMessageActions';

class HelpAndSupport extends Component {
    constructor(props){
        super(props);
        this.state = {
            emailAddress: '',
            fname: '',
            lname: '',
            contactType: 'TECHNICAL',
            message: '',
            validated: false,
            isLoading: false,
        }
    }
    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            this.setState({
                ...this.state,
                validated:true
            })
        }
        else{
            this.setState({
                ...this.state,
                isLoading: true
            })
            const postData = {
                accountId:    this.props.dashboard.selectedAccountId,
                emailAddress: this.state.emailAddress,
                fullName:     this.state.fname + ' ' + this.state.lname,
                contactType:  this.state.contactType,
                message:      this.state.message,
            }
            this.props.submitHelpAndSupport(postData)
            .then((status) => {
                if(status === 'Success'){
                    this.setState({
                        ...this.state,
                        isLoading: false
                    })
                    this.props.showMessage(false, 'Submit Successfully!')
                }
                else{
                    this.setState({
                        ...this.state,
                        isLoading: false
                    })
                    this.props.showMessage(true, 'Submit Failed!')
                }
            })
        }
    }
    render() { 
        const { validated } = this.state;
        return (
            <React.Fragment>
                <div className="row pb-4">
                    <div className="col-lg-8">
                        <h3 className="primary-font-color">Help & Support</h3>
                    </div>
                    <div className="col-lg-4">
                        <LogoutButton />
                    </div>
                </div>
                <div className="customer-feedback" style={{ maxWidth: "750px" }}>
                    <h5 className="dark-font-color">Customer Feedback</h5>
                    <Form
                        autocomplete="off"
                        noValidate
                        validated={validated}
                        onSubmit={e => this.handleSubmit(e)}
                    >
                        <Form.Row>
                            <Form.Group as={Col} md="6" controlId="first-name">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="fname"
                                    className="valid"
                                    onChange={this.onChange.bind(this)}
                                />
                                <Form.Control.Feedback id="feedback" type="invalid">
                                    Please enter your First Name.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="last-name">
                                <Form.Label>Last name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="lname"
                                    onChange={this.onChange.bind(this)}
                                />
                                <Form.Control.Feedback id="feedback" type="invalid">
                                    Please enter your Last Name.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="6" controlId="emailAddress">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="emailAddress"
                                    className="valid"
                                    onChange={this.onChange.bind(this)}
                                />
                                <Form.Control.Feedback id="feedback" type="invalid">
                                    Please enter your Email Address.
                                </Form.Control.Feedback>
                            </Form.Group>
                            {/* <Form.Group as={Col} md="6" controlId="validationCustom04">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="phone"
                                />
                                <Form.Control.Feedback id="feedback" type="invalid">
                                    Please enter your Phone.
                                </Form.Control.Feedback>
                            </Form.Group> */}
                            <Form.Group as={Col} md="6" controlId="contactType">
                                <Form.Label>Contact Type</Form.Label>
                                <Form.Control as="select" name="contactType" ref="subject" onChange={this.onChange.bind(this)}>
                                    <option value="TECHNICAL">Technical Query</option>
                                    <option value="COMPLAINT">Comment or Complaint</option>
                                </Form.Control>
                                <Form.Control.Feedback id="feedback" type="invalid">
                                    Please select Contact Type.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        {/* <Form.Row>
                            <Form.Group as={Col} md="12" controlId="validationCustom05">
                                <Form.Label>Subject</Form.Label>
                                <Form.Control as="select" name="subject" ref="subject">
                                    <option>Subject 1</option>
                                    <option>Subject 2</option>
                                    <option>Subject 3</option>
                                    <option>Subject 4</option>
                                    <option>Subject 5</option>
                                </Form.Control>
                                <Form.Control.Feedback id="feedback" type="invalid">
                                    Please select Subject.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row> */}
                        <Form.Row>
                            <Form.Group as={Col} md="12" controlId="message">
                                <Form.Label>Message</Form.Label>
                                <Form.Control as="textarea" name="message" rows="3" required onChange={this.onChange.bind(this)}/>
                                <Form.Control.Feedback id="feedback" type="invalid">
                                    Please enter your Message.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        {
                            this.state.isLoading === true ? 
                            <Button variant="primary" className="btn btn-primary regular-font-size font-bold mt-2" disabled>
                                <Spinner
                                as="span"
                                class="mt-1"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                />
                                Loading...
                            </Button>
                            :
                            <button type="submit" className="btn btn-primary regular-font-size font-bold mt-2"> 
                                Submit
                            </button>
                        }
                    </Form>
                    <br />
                    <br />
                    <br />
                    <br />
                    <h5 className="dark-font-color">Contact Us</h5>
                    <p>Having trouble? Our Customer Service representatives are eager to assist you.</p>
                    <br />
                    <p><strong>Customer Service Division: </strong>Phone: (671) 647-5787/8/9</p>
                    <p><strong>For account assistance: </strong>Email: <a href="mailto:customersfirst@gpagwa.com">customersfirst@gpagwa.com</a></p>
                    <p><strong>For PayGPA assistance: </strong>Email: <a href="mailto:paygpainfo@gpagwa.com">paygpainfo@gpagwa.com</a></p>
                    <p><strong>For billing inquiries: </strong>Email: <a href="mailto:customersfirst@gpagwa.com">billinquiries@gpagwa.com</a></p>
                    <br />
                    <p><strong>Facebook: </strong><a href="https://facebook.com/GuamPowerAuthority" target="_blank">https://facebook.com/GuamPowerAuthority</a></p>
                    <br />
                    <p><strong>Guam Power Authority: </strong><a href="https://www.guampowerauthority.com" target="_blank">https://www.guampowerauthority.com</a></p>
                    <p><strong>Guam Waterworks Authority: </strong><a href="https://www.guamwaterworks.org" target="_blank">https://www.guamwaterworks.org</a></p>
                    <p><strong>My Energy Guam: </strong><a href="https://www.myenergyguam.com" target="_blank">https://www.myenergyguam.com</a></p>
                    <p><strong>Consolidated Commission of Utilities (CCU): </strong><a href="https://www.guamccu.org" target="_blank">https://www.guamccu.org</a></p>
                    <p><strong>Guam Solid Waste Authority: </strong><a href="https://www.guamsolidwasteauthority.com/" target="_blank">https://www.guamsolidwasteauthority.com/</a></p>
                </div>
            </React.Fragment>
        ); 
    }
}


const mapStateToProps = state => ({
    users:     state.users,
    dashboard: state.dashboard
});

export default connect(mapStateToProps, { 
    submitHelpAndSupport,
    showMessage
})(HelpAndSupport);