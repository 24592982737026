import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
class SuccessMessage extends Component {
    state = { 
        displayLogin: false
    }
    componentWillMount() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    gotoLogin = (e) => {
        this.setState({
            displayLogin: true
        })
    }
    render() { 
        if(this.state.displayLogin) {
            return <Redirect to="/" />
        }
        return ( 
            <React.Fragment>
                <section id="success-message-icon">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <img src={process.env.PUBLIC_URL + '/images/sign-up-success-msg-icon.png'} alt="sign-up-success-msg-icon"/>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="success-message-title">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <h5 className="primary-font-color">Si Yu’os Ma’ase & Thank you for activating your PAYGPA Web Portal!</h5>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="success-message-body">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <label className="">An email confirmation of your PayGPA registration has just been sent. If you have not received an email confirmation within the next few minutes, please contact our Customer Service department at (671)647-5787/8/9 or let us know via email at <a href="mailto:paygpainfo@gpagwa.com">paygpainfo@gpagwa.com</a></label>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="success-message-body">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <label className="">Remember to check your junk folder as the email may have been caught in your spam filter.</label>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="success-message-button">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <button type="submit" className="btn btn-primary form-button regular-font-size font-bold" onClick={this.gotoLogin.bind(this)}>Login Here</button>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default SuccessMessage;