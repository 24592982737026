// FOR LOGIN AND SIGNUP
export const FETCH_USERS                   = "FETCH_USERS";
export const NEW_USER                      = "NEW_USER";
export const LOGIN_USER                    = "LOGIN_USER";
export const CHECK_USERNAME                = "CHECK_USERNAME";
export const FETCH_ADDRESS_DETAILS         = "FETCH_USER_DETAILS";
export const FETCH_SECURITY_QUESTIONS      = "FETCH_SECURITY_QUESTIONS";
export const FETCH_CONTACT_NUMBERS         = "FETCH_CONTACT_NUMBERS";
export const FETCH_BASIC_DETAILS           = "FETCH_BASIC_DETAILS";
export const FETCH_ACOV_ADDRESS_DETAILS    = "FETCH_ACOV_ADDRESS_DETAILS";
export const FETCH_PREM_ADDRESS_DETAILS    = "FETCH_PREM_ADDRESS_DETAILS";

// FOR DASHBOARD
export const SAVE_ACCOUNT_ID               = "SAVE_ACCOUNT_ID";
export const SAVE_PREMISE_ADDRESS          = "SAVE_PREMISE_ADDRESS";
export const FETCH_USER_DETAILS            = "FETCH_USER_DETAILS";
export const FETCH_USER_OLD_DETAILS        = "FETCH_USER_OLD_DETAILS";
export const FETCH_EDIT_SECURITY_QUESTIONS = "FETCH_EDIT_SECURITY_QUESTIONS";
export const FETCH_COUNTRIES               = "FETCH_COUNTRIES";
export const FETCH_LATEST_BILL             = "FETCH_LATEST_BILL";
export const FETCH_CONSUMPTION_DETAILS     = "FETCH_CONSUMPTION_DETAILS";
export const FETCH_BILL_LIST               = "FETCH_BILL_LIST";
export const FETCH_SURVEY_LIST             = "FETCH_SURVEY_LIST";
export const SAVE_VIEW_BILL_DATA           = "SAVE_VIEW_BILL_DATA";
export const SAVE_ORDER_DATA               = "SAVE_ORDER_DATA";
export const FETCH_PAYMENT_HISTORY_LIST    = "FETCH_PAYMENT_HISTORY_LIST";

//FOR PAYNOW PAGE 
export const SAVE_STEP1_DATA               = "SAVE_STEP1_DATA";
export const SAVE_STEP2_DATA               = "SAVE_STEP2_DATA";
export const SAVE_STEP3_DATA               = "SAVE_STEP3_DATA";
export const CHECK_STEP1_RESPONSE          = "CHECK_STEP1_RESPONSE";
export const SAVE_STEP_CONFIG              = "SAVE_STEP_CONFIG";

//FOR POPUP MESSAGE 
export const SHOW_MESSAGE                  = "SHOW_MESSAGE";
export const HIDE_MESSAGE                  = "HIDE_MESSAGE";

//FOR PAYMENT ARRANGEMENT
export const SAVE_PA_ACCOUNTS              = "SAVE_PA_ACCOUNTS";

//NOTHIN' JUS' A BULLSHIT
export const LOAD_COMPONENT                = "LOAD_COMPONENT";
export const GET_COMPONENT                 = "GET_COMPONENT";

//FOR GETTING CUSTOMER CLASS
export const CUSTOMER_CLASS                = "CUSTOMER_CLASS";