import React, { Component } from "react";
import Stepper from "../../components/PaymentArrangement/stepper";
import Header from "../../components/PaymentArrangement/header";
import { Table, Spinner } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  paArrList,
  generateFpHash,
} from "../../actions/paymentArrangementActions";
import { showMessage } from "../../actions/popupMessageActions";

class PayToday extends Component {
  state = {
    accountsResponse: [],
    paymentFormData: {
      accountId: "",
      x_login: "",
      x_fp_sequence: "",
      x_fp_timestamp: "",
      x_amount: "",
      x_fp_hash: "",
      x_invoice_num: "",
      x_po_num: "",
      x_show_form: "PAYMENT_FORM",
      x_relay_response: "TRUE",
      Checkout: "Pay Now",
    },
  };
  componentDidMount() {
    this.setState(
      {
        accountsResponse: this.props.location.state.paAccountsResponse,
      },
      () => {
        console.log("bobo", this.state.accountsResponse);
        //reset all amount to be pay
        const { accountsResponse } = this.state;
        const value = 0;
        for (let count = 0; count < accountsResponse.length; count++) {
          accountsResponse[count].amountToBePay = value;
          accountsResponse[count].newBalanceAfterPay = accountsResponse[count]
            .applyDeposit
            ? parseFloat(
                accountsResponse[count].balance -
                  accountsResponse[count].depAmount
              )
            : parseFloat(accountsResponse[count].balance);
          this.setState({
            accountsResponse: accountsResponse,
          });
        }
      }
    );
  }
  formatAmount = (value) => {
    value = value.replace("$ ", ""); //removes '$ '
    value = value.replace(/\,/g, ""); //removes all ','
    return (value = parseFloat(Math.round(value * 100) / 100).toFixed(2));
  };
  depositAmountOnChange = (e) => {
    //default state
    let { accountsResponse } = this.state;
    const value = this.formatAmount(e.target.value);
    for (let count = 0; count < accountsResponse.length; count++) {
      if (accountsResponse[count].accountId == e.target.id) {
        // if(parseFloat(accountsResponse[count].depAmount) + parseFloat(value) > accountsResponse[count].newBalanceAfterPay) {
        //     const validPaymentAmount = parseFloat(accountsResponse[count].newBalanceAfterPay - accountsResponse[count].depAmount);
        //     this.props.showMessage(true, `Payment Amount Invalid! \n Please enter a payment amount up to ${parseFloat(Math.round(validPaymentAmount * 100) / 100).toFixed(2)}`);
        //     //reset the dep amount field by setting the default state
        //     this.setState({
        //         accountsResponse: accountsResponse
        //     })
        //     break;
        // }
        // else {
        // const depDiff = parseFloat(accountsResponse[count].depAmount - value); //getting the difference between old and new dep amount
        if (value > 0) {
          accountsResponse[count].amountToBePay = value;
          accountsResponse[count].newBalanceAfterPay = accountsResponse[count]
            .applyDeposit
            ? parseFloat(
                accountsResponse[count].balance -
                  accountsResponse[count].depAmount
              ) - value
            : accountsResponse[count].depCredit == 0
            ? accountsResponse[count].balance - value
            : accountsResponse[count].balance;
        } else {
          accountsResponse[count].amountToBePay = value;
          accountsResponse[count].newBalanceAfterPay = accountsResponse[count]
            .applyDeposit
            ? parseFloat(
                accountsResponse[count].balance -
                  accountsResponse[count].depAmount
              )
            : parseFloat(accountsResponse[count].balance);
        }
        this.setState({
          accountsResponse: accountsResponse,
        });
        break;
        // }
      }
    }
  };
  payOnClick = (item) => {
    // console.log(item.amountToBePaid)
    if (parseFloat(item.amountToBePay) > 0) {
      this.setState(
        {
          paymentProcessing: true,
        },
        () => {
          this.props.generateFpHash(item).then((response) => {
            // console.log("response",response)
            this.setState(
              {
                ...this.state,
                paymentFormData: {
                  ...this.state.paymentFormData,
                  accountId: item.accountId,
                  x_login: response.login,
                  x_fp_sequence: response.fp_sequence,
                  x_fp_timestamp: response.fp_timestamp,
                  x_amount: item.amountToBePay,
                  x_fp_hash: response.fp_hash,
                  x_invoice_num: response.fp_sequence,
                  x_po_num: item.fullName,
                  x_show_form: "PAYMENT_FORM",
                  x_relay_response: "TRUE",
                  Checkout: "Pay Now",
                },
              },
              () => {
                document
                  .getElementById("payment-form-" + item.accountId)
                  .submit();
                localStorage.setItem(
                  "paAccounts",
                  JSON.stringify(this.state.accountsResponse)
                );
                localStorage.setItem(
                  "accountSummary",
                  JSON.stringify(this.props.location.state.accountSummary)
                );
                // console.log("this.state.paymentFormData",this.state.paymentFormData)
              }
            );
          });
        }
      );
    } else {
      this.props.showMessage(true, "Please enter an amount!");
    }
  };
  paymentForm = (item, type) => {
    return (
      // <form id={"payment-form-"+item.accountId} action="https://demo.globalgatewaye4.firstdata.com/payment" method="post">

      <form
        id={"payment-form-" + item.accountId}
        action="https://checkout.globalgatewaye4.firstdata.com/payment"
        method="post"
      >
        <input
          name="x_login"
          value={this.state.paymentFormData.x_login}
          type="hidden"
        />
        <input
          name="x_fp_sequence"
          value={this.state.paymentFormData.x_fp_sequence}
          type="hidden"
        />
        <input
          name="x_fp_timestamp"
          value={this.state.paymentFormData.x_fp_timestamp}
          type="hidden"
        />
        <input
          name="x_amount"
          value={this.state.paymentFormData.x_amount}
          type="hidden"
        />
        <input
          name="x_fp_hash"
          value={this.state.paymentFormData.x_fp_hash}
          type="hidden"
        />
        <input
          name="x_invoice_num"
          value={this.state.paymentFormData.x_invoice_num}
          type="hidden"
        />
        <input
          name="x_po_num"
          value={this.state.paymentFormData.x_po_num}
          type="hidden"
        />
        <input
          name="x_show_form"
          value={this.state.paymentFormData.x_show_form}
          type="hidden"
        />
        <input
          name="x_relay_response"
          value={this.state.paymentFormData.x_relay_response}
          type="hidden"
        />
        <input
          type="button"
          name="Checkout"
          value={type}
          className="btn pa-btn-primary"
          onClick={() => {
            this.payOnClick(item);
          }}
          // disabled={item.applyDeposit ? false : true}
        />
      </form>
    );
  };
  render() {
    const priceSplitter = (number) =>
      number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const formatAmount = (amount) => {
      amount = parseFloat(Math.round(amount * 100) / 100).toFixed(2);
      return priceSplitter(amount);
    };
    if (this.state.displayUseDeposit) {
      return (
        <Redirect
          to={{
            pathname: "/payment-arrangement/use-deposit",
            state: {
              accountSummary: this.props.location.state.accountSummary,
              paAccountsResponse: this.state.accountsResponse,
            },
          }}
        />
      );
    }
    if (this.state.displayApplyForPA) {
      return (
        <Redirect
          to={{
            pathname: "/payment-arrangement/apply-for-payment-arrangement",
            state: {
              accountSummary: this.props.location.state.accountSummary,
              paAccountsResponse: this.state.accountsResponse,
            },
          }}
        />
      );
    }
    return (
      <React.Fragment>
        <Header />
        <section id="payment-arrangement-pay-today" className="pt-5">
          <Stepper />
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center mt-5">
                <p className="font-bold">
                  Step 2: How much are you able to pay today? (can be as low as
                  $0)
                </p>
              </div>
              <div className="col-lg-9 text-center mt-5 mx-auto">
                {this.state.accountsResponse.length == 0 ? (
                  <Spinner animation="border" size="md" variant="primary" />
                ) : (
                  <Table id="payment-arrangement-step-table" bordered hover>
                    <thead>
                      <tr>
                        <th>Account #</th>
                        <th>Balance</th>
                        <th>Payment Amount</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.accountsResponse.map((item, index) => (
                        // {formatAmount(item.newBalanceAfterPay)}
                        <tr>
                          <td>{item.accountId}</td>
                          <td>
                            ${" "}
                            {item.applyDeposit
                              ? formatAmount(item.balance - item.depAmount)
                              : formatAmount(item.balance)}
                          </td>
                          <td>
                            <NumberFormat
                              id={item.accountId}
                              className="text-center mx-auto"
                              value={item.amountToBePay}
                              thousandSeparator={true}
                              prefix={"$ "}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              allowNegative={false}
                              onChange={this.depositAmountOnChange.bind(this)}
                            />
                          </td>
                          <td>{this.paymentForm(item, "Pay Now")}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </div>
              <div className="col-lg-12 mt-5 mb-5 text-center">
                <div className="row">
                  <div className="col-lg-3 ml-auto">
                    <button
                      className="btn pa-btn-secondary"
                      onClick={() => {
                        this.setState({
                          displayUseDeposit: true,
                        });
                      }}
                    >
                      Back
                    </button>
                  </div>
                  <div className="col-lg-3 mr-auto">
                    <button
                      className="btn pa-btn-primary"
                      onClick={() => {
                        this.setState({
                          displayApplyForPA: true,
                        });
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  paymentArrangement: state.paymentArrangement,
});

export default connect(mapStateToProps, {
  generateFpHash,
  paArrList,
  showMessage,
})(PayToday);
