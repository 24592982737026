import React, { Component } from 'react';
import * as moment from 'moment';
import Barcode from 'react-barcode';
import $ from 'jquery';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { 
    showMessage,
} from '../../actions/popupMessageActions';
import { 
    getPaDetails
} from '../../actions/paymentArrangementActions';
import { forEach } from 'lodash';

class CaseForm extends Component {
    state = {  
        paFormData: [],
        currentRenderData: [],
        downloaded: 0
    }
    componentDidMount(){
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        
        const {paAccountsResponse, accountSummary} = this.props.location.state;
        console.log('paAccountsResponsepaAccountsResponsepaAccountsResponsepaAccountsResponsepaAccountsResponse',paAccountsResponse)
        console.log('accountSummaryaccountSummaryaccountSummaryaccountSummaryaccountSummaryaccountSummaryaccountSummary',accountSummary)
        // get pa details 
        this.props.getPaDetails()
        .then((response) => {
            let caseData = response;
            console.log("caseData",caseData)
     
            const {paAccountsResponse, accountSummary, download} = this.props.location.state;
            console.log("paAccountsResponse",paAccountsResponse)
            //BUILD PA FORM DATA
            let paFormData = [];
            for(let count = 0; count < caseData.length; count++){
                for(let count1 = 0; count1 < paAccountsResponse.length; count1++){
                    if(caseData[count].accountId == paAccountsResponse[count1].accountId){
                        paFormData.push(caseData[count]);
                    }
                }
            }
           

            for(let count = 0; count < paFormData.length; count++){
                for(let count1 = 0; count1 < paAccountsResponse.length; count1++){
                    if(paFormData[count].accountId == paAccountsResponse[count1].accountId){
                        paFormData[count].paAccountsResponseData = paAccountsResponse[count1];
                        break;
                    }
                }
                for(let count2 = 0; count2 < accountSummary.length; count2++){
                    if(paFormData[count].accountId == accountSummary[count2].accID){
                        paFormData[count].accountSummaryData = accountSummary[count2];
                        break;
                    }
                }
            }

            console.log('paFormData', paFormData);
            this.setState({
                paFormData: paFormData
            }, async () => {
                console.log(' paFormData.length', paFormData)
                const sleep = ms => {
                    return new Promise(resolve => setTimeout(resolve, ms))
                }
                for(let count1=0; count1 < paFormData.length; count1++){
                    if(paFormData[count1].customerContactStatus == '10' && download){
                        await sleep(500).then(this.download(paFormData[count1].caseId, paFormData[count1].accountId));
                        this.setState({
                            downloaded: this.state.downloaded + 1
                        })
                    }
                }
                setInterval(() => {
                    this.setState({displayPrintSignSend: true});
                }, 1000);
            })
            console.log('paFormData1', paFormData);
        })
        .catch((error) => {
            this.props.showMessage(true,'Server Failed! \n Failed to get your Payment Arrangement Details!')
        })
        
        // $('#dl-pa-form').click();
    }
    download = (caseId, accountId) => {
        // var newWindow = window.open();
        var svgString = new XMLSerializer().serializeToString(document.querySelector('svg'));
        var canvas = document.getElementById(`canvas-${caseId}`);
        var ctx = canvas.getContext("2d");
        var DOMURL = window.URL || window.webkitURL;
        var img = new Image();
        var svg = new Blob([svgString], {type: "image/svg+xml;charset=utf-8"});
        var url = DOMURL.createObjectURL(svg);
        img.onload = () => {
            ctx.drawImage(img, 0, 0);
            var png = canvas.toDataURL("image/png");
            document.querySelector(`#png-container-${caseId}`).innerHTML = '<img src="'+png+'"/>';
            $(`#canvas-${caseId}`).hide();
            DOMURL.revokeObjectURL(png);
            const jsPDF = require('jspdf')
            var pdf = new jsPDF('p','px','a4');
            var source = $(`#pa-case-form-${caseId}`)[0];
            pdf.addHTML(source,function() {
                pdf.save(`CASE-${caseId}_ACCT-${accountId}-${new Date().getTime()}.pdf`)
                const screenWidth = window.screen.width
                // if(screenWidth < 1200){
                //     window.close();
                    
                // }
            });
        };
        img.src = url;
        // setTimeout(function() {
		// 	newWindow.close();
        // }, 100);
        return true;
    }
    render() { 
        const {paFormData} = this.state;

        if(this.state.displayPrintSignSend) {
            const {paAccountsResponse, caseData, accountSummary} = this.props.location.state;
            return <Redirect to={{
                pathname: "/payment-arrangement/print-sign-send", 
                state: {
                    accountSummary: accountSummary,
                    paAccountsResponse: paAccountsResponse,
                    caseData: caseData,
                    paFormData: paFormData
                }
            }} />
        }

        if(paFormData.length == 0){
            return (
                <React.Fragment>
                    <br/>
                    <center><Spinner animation="border" size="md" variant="primary" /></center>
                    <section style={{width: '100%', margin: 'auto', height: '2000px', padding: '0px 40px', backgroundColor: '#fff', zIndex: '1111', position: 'absolute'}}>

                    </section>
                </React.Fragment>
            );
        }
        return (  
            paFormData.map((item, index) => (
                <React.Fragment>
                    <br/>
                    <center><Spinner animation="border" size="md" variant="primary" /><br/>{this.props.location.state.download ? 'Downloading...' : ''}</center>
                    <section style={{width: '100%', margin: 'auto', height: '2000px', padding: '0px 40px', backgroundColor: '#fff', zIndex: '1111', position: 'absolute'}}>

                    </section>
                    <button id="dl-pa-form" style={{marginTop: '500px'}}onClick={() => {this.download(item.caseId, item.accountId)}}>download {index}</button>
                    <section id={`pa-case-form-${item.caseId}`} style={{width: '900px', margin: 'auto', height: '1150px', padding: '30px 40px', backgroundColor: '#fff'}}>
                        <div style={{textAlign: 'center'}}>
                            <div style={{display: 'inline-block'}}>
                                <img className="gpa_logo" src={process.env.PUBLIC_URL + '/images/GPA-Logo.png'} alt="gpa-logo"></img>
                            </div>
                            <div style={{display: 'inline-block', verticalAlign: 'middle'}}>
                                <h2 className="font-bold">Guam Power Authority</h2>
                                <h4 className="font-bold">Aturidåt Ilektresedåt Guåhan</h4>
                            </div>
                        </div>
                        <div style={{textAlign: 'center', marginTop: '55px'}}>
                            <h3 className="font-extra-bold">Payment Arrangement Agreement Form</h3>
                        </div>
                        <div style={{textAlign: 'left', marginTop: '50px'}}>
                            Date: {moment(new Date(Date.now())).format('MM/DD/YYYY')}
                        </div>
                        <div style={{textAlign: 'left', marginTop: '30px'}}>
                            Account Name: <span className="font-extra-bold">{item.accountSummaryData.fullName}</span><br />
                            Account Number: <span className="font-extra-bold">{item.accountId}</span><br />
                            Premise Address: <span className="font-extra-bold">{item.accountSummaryData.serviceLocation}</span>
                        </div>
                        <div style={{textAlign: 'left', marginTop: '30px'}}>
                            <span className="font-extra-bold">Payment Arrangement Details</span>
                        </div>
                        <div style={{textAlign: 'left', marginTop: '30px'}}>
                            Case Number: <span className="font-extra-bold">{item.caseId}</span><br />
                            Customer Contact ID: <span className="font-extra-bold">{item.customerContactId}</span>
                        </div>
                        <div style={{textAlign: 'justify', marginTop: '30px'}}>
                        I agree and understand that my electric service is subject to disconnection should I fail to make timely payments on my monthly electric statement. To make modifications to this payment arrangement, please email customersfirst@gpagwa.com or call 647-5787 before the payment due date.
                        </div>
                        <div style={{textAlign: 'justify', marginTop: '30px'}}>
                        By affixing my signature, I agree to the terms and conditions stipulated in the regulations governing the Payment Arrangement offered by the Guam Power Authority.
                        </div>
                        <div style={{textAlign: 'left', marginTop: '70px'}}>
                            <div style={{textAlign: 'center', display: 'inline-block', marginRight: '80px'}}>
                                ______________________________________<br/>
                                {item.accountSummaryData.fullName}
                            </div>
                            <div style={{textAlign: 'center', display: 'inline-block'}}>
                                {moment(new Date(Date.now())).format('MM/DD/YYYY')}<br/>
                                Date
                            </div>
                            <div style={{textAlign: 'left', display: 'inline-block', float: 'right'}}>
                                Case Number
                                <canvas id={`canvas-${item.caseId}`} width="200" height="100"></canvas>
                                <div id={`png-container-${item.caseId}`} className="barcode"></div>
                                <div className="barcode">
                                    <Barcode id="testing" value={item.caseId}
                                        displayValue={true}
                                        width={2}
                                        height={100}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                </React.Fragment>
            ))
        );
    }
}
 
const mapStateToProps = state => ({
    paymentArrangement: state.paymentArrangement
});

export default connect(mapStateToProps, {
    showMessage,
    getPaDetails
})(CaseForm);