import React, { Component } from 'react';
class PaymentServerFailed extends Component {
    state = {  }
    render() { 
        return ( 
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="text-center">
                            <h3><span style={{color: '#c40808'}}><i class="fas fa-times-circle"></i></span> Sorry, your payment was not processed.</h3>
                        </div>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-lg-12">
                        <div className="text-center">
                            <label className="font-bold">Please try again later.</label>
                            <label className="font-bold">If you repeatedly see this message, complete your payment by contacting GPA directly.</label>
                        </div>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-lg-6 text-right">
                        <button id="btn-download" type="submit" class="btn regular-font-size font-bold btn-left" onClick={() => {localStorage.removeItem('reload');window.close();}}>Go Back</button>
                    </div>
                    <div className="col-lg-6 text-left">
                        <button id="btn-member-dashboard" type="submit" class="btn regular-font-size font-bold btn-right" onClick={() => { localStorage.setItem('reload','true');window.close();}}>Close This Window</button>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default PaymentServerFailed;