import axios from 'axios';

import { 
    SAVE_STEP1_DATA, 
    SAVE_STEP2_DATA, 
    SAVE_STEP3_DATA,
    CHECK_STEP1_RESPONSE,
    SAVE_STEP_CONFIG,
    CUSTOMER_CLASS
} from './types';

const dashboardBaseURL = process.env.REACT_APP_DASHBOARD_API_URL;
const loginSignupBaseURL = process.env.REACT_APP_LOGIN_SIGNUP_API_URL;
const paynowBaseURL = process.env.REACT_APP_PAYNOW_API_URL;

export const saveStep1Data = (targetName, targetValue) => dispatch => {
    const postData = {
        targetName: targetName,
        targetValue: targetValue
    }
    dispatch({
        type:    SAVE_STEP1_DATA,
        payload: postData
    })
}
export const saveFormConfig = (postData) => dispatch => {
    dispatch({
        type:    SAVE_STEP_CONFIG,
        payload: postData
    })
}

export const getCustomerClass = (accountId) => dispatch => {
    return new Promise((resolve,reject) => {
        axios
        .get(
            paynowBaseURL+'api/v1/quickpay-customer-class?accountId='+accountId.acountId.accID,
            // {
            //     accountId: accountId
            // },
            {
                headers: { 'Content-Type': 'application/json' }
            }
        )
        .then(function(response) {
            console.log("fist test", response)
            dispatch({
                type:    CUSTOMER_CLASS,
                payload: response.data,
            })
            resolve(true);
        })
        .catch( error => {
            reject(error);
        })
    });
}

export const validateZipCode = (accountId, postal) => dispatch => {
    return new Promise((resolve,reject) => {
        axios
        .post(
            paynowBaseURL+'/api/v1/validate-account',
            {
                accountId: accountId,
                division: 'GPA',
                postal: postal
            },
            {
                headers: { 'Content-Type': 'application/json' }
            }
        )
        .then(function(response) {
            dispatch({
                type:    CHECK_STEP1_RESPONSE,
                payload: response.data,
            })
            resolve(true);
        })
        .catch( error => {
            reject(error);
        })
    });
}