import React, { Component } from 'react';
import Header from '../../partials/header';
import Footer from '../../partials/less-content-footer';
import { connect } from 'react-redux';

import { Modal, Table, Col, Container, Row, Form, Spinner, Button } from 'react-bootstrap';
import axios from 'axios';
import $ from 'jquery';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { 
  showMessage
} from '../../actions/popupMessageActions';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

class AdminSystemMaintenance extends Component {
  constructor(){
    super()
    this.state = {
        userId: "",
        accountId: "",
        fullName: "",
        personId: "",
        password: "",
        confirmPassword: "",
        prompt: 'The password is successfully updated!',
        buttonIsDisabled: false,
        submitIsDisabled: false,
        showUpdateModal: false,
        notEqualPassword: false,
        showAlert: false,
        searchResults: [],
        pwErrMsg: '',
        
    }
  }

  inputChange = e => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    });
  };

  onChangePassword = (e) => {
    if(e.target.name === "password" || e.target.name === "confirmPassword"){
      this.setState({
        ...this.state,
        [e.target.name]: e.target.value
      },
      () => {
          let pword = String(this.state.password);
          let confirm_pword = String(this.state.confirmPassword);
      
          if(pword.length < 8){
            this.setState({ 
                notEqualPassword: true,
                pwErrMsg: "Your password must be between 8 – 15 characters long"
            });
        }
        else{
            if(pword===confirm_pword){
                this.setState({ notEqualPassword: false });
            }else{
                this.setState({ notEqualPassword: true, pwErrMsg: "Password doesn't match!" });
            }
        }
      })
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({searchResults: [], buttonIsDisabled: true})
      axios.post('/api/v1/search-web-user',
      {
        accountId: this.state.accountId,
        username: this.state.userId,
        division: 'GPA'
      },{
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        if(response.data.result.status === 'Success') {
          this.setState({
            searchResults: [
              {
                username: response.data.result.desc.username,
                fullName: response.data.result.desc.fullName,
                personId: response.data.result.desc.personId
              }
            ] ,
            buttonIsDisabled: false
          });
        }else{
          this.props.showMessage(true, `${response.data.result.description} (${this.state.accountId})`);
          this.setState({
            searchResults: [] ,
            buttonIsDisabled: false
          });
        }
      })
      .catch(error => {
      });
   
  }

  renderSearch() {
    const columns = 
    [
      {
        dataField: 'username',
        text: 'User ID'
      }, {
        dataField: 'fullName',
        text: 'Customer Name',
        sort: true,
      }, {
        dataField: 'personId',
        text: 'Actions',
        formatter: (cellContent, row) => (
          <a id={row.personId} onClick={this.handleOpenModal.bind(this)} >
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/writing.png"
              }
              title="Edit"
              />
          </a>
        )
      }
    ];

    const options = {
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      showTotal: true,
      // paginationTotalRenderer: customTotal,
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: '30', value: 30
      }, {
        text: '50', value:50
      }]
    };

    const defaultSorted =
     [
      {
        dataField: 'username',
        order: 'desc'
      }, {
        dataField: 'fullName',
        order: 'desc'
      }
    ];
   return (

      <div id="report-list-container">
        <h5>Search Results</h5>
        <BootstrapTable 
        bootstrap4 = {true}
        keyField='personId' 
        data={this.state.searchResults} 
        columns={ columns } 
        pagination={ paginationFactory(options) }
        defaultSorted={ defaultSorted }
        noDataIndication="No data available"  
        hover
        condensed
        wrapperClasses="table-responsive search-change-pass"
        />
        
      </div>
    );
  }

  handleOpenModal = (e) => {
    let id = e.currentTarget.getAttribute("id");
    this.setState({ showUpdateModal: true, personId: id }, ()=> console.log(this.state.personId));
  };

  handleCloseModal = () => {
    this.setState({ showUpdateModal: false, personId: "", password: "", confirmPassword: "", showAlert: false });
  };

  submitChangePass = (event) => {
    event.preventDefault();
    this.setState({submitIsDisabled: true});
    if(this.state.notEqualPassword == false) {
      axios.post('/api/v1/password-reset',
      {
        personId: this.state.personId,
        password: this.state.password
      },{
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        this.setState({submitIsDisabled: false, showUpdateModal: false, showAlert: true});
      })
      .catch(error => {
      });
    }else {
      this.setState({submitIsDisabled: false});
    } 
  }

  render() { 
    const errPwordMsg       = this.state.notEqualPassword === true ? this.state.pwErrMsg+" " : 'Please enter your password.';
    const errPwordInpClass  = this.state.notEqualPassword === true ? 'invalid form-control' : 'valid form-control';
    const errPwordMsgDisp   = this.state.notEqualPassword === true ? 'invalid_msg invalid-feedback' : 'invalid-feedback';
    return (
      <React.Fragment>
        <Modal
          show={this.state.showAlert}
          onHide={this.handleCloseModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          id="modal-for-alert"
          backdrop="static"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h6>{this.state.prompt}</h6>
              <div className="btn-submit-change-ok">
                <button
                  type="submit"
                  onClick={this.handleCloseModal}
                  className="btn btn-change-ok font-bold btn-primary"
                >
                  Okay
                </button>
              </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.showUpdateModal}
          onHide={this.handleCloseModal}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          id="modal-for-change-pass"
          backdrop="static"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Form autocomplete="off">
              <Form.Group controlId="password">
                <Form.Label>New Password: <span className="red-font">*</span></Form.Label>
                <Form.Control
                  required
                  autocomplete="off"
                  type="password"
                  name="password"
                  className={errPwordInpClass}
                  value={this.state.password}
                  onChange={this.onChangePassword.bind(this)}
              />
              <Form.Label>(Password must be between 8 – 15 characters long)</Form.Label>
              <Form.Control.Feedback id="feedback" className={errPwordMsgDisp} type="invalid">
                  {errPwordMsg}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="confirm-password">
                <Form.Label>Confirm New Password: <span className="red-font">*</span></Form.Label>
                <Form.Control
                  required
                  autocomplete="off"
                  type="password"
                  name="confirmPassword"
                  className={errPwordInpClass}
                  value={this.state.confirmPassword}
                  onChange={this.onChangePassword.bind(this)}
              />
              {/* <Form.Control.Feedback id="feedback" className={errPwordMsgDisp} type="invalid">
                  {errPwordMsg}
              </Form.Control.Feedback> */}
            </Form.Group>
                    {
                      this.state.submitIsDisabled ? 
                      <button
                          type="submit"
                          disabled
                          className="btn btn-change font-bold "
                        >
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Loading...
                      </button>
                      :
                      <button 
                        type="submit" 
                        onClick={this.submitChangePass.bind(this)} 
                        className="btn btn-primary btn-change font-bold" 
                      >
                        Update
                      </button>
                    }
            </Form>
          </Modal.Body>
        </Modal>
        <div id="admin-reset-pass-container">
          <Container fluid={true}>
            <Row>
              <Col>
                <h5>Change Password</h5>
                <p>Account Search Filter(s):</p>
              <div className="search-container">
                  {/* <div className="search-container-left">
                    <div className="input-cont">
                      <label>User ID: </label><div className="input-right"><input type="text" autocomplete="off" name="userId" onChange={this.inputChange.bind(this)} /></div>
                    </div>
                  </div> */}
                  <div className="search-container-left">
                    <div className="input-cont">
                      <label>Account Number:</label><div className="input-right"><input type="text" autocomplete="off" name="accountId" onChange={this.inputChange.bind(this)} /></div>
                    </div>
                  </div>
                  <div className="button-inblock">
                    {
                      this.state.buttonIsDisabled ? 
                      <button
                          type="submit"
                          disabled
                          className="btn btn-change-ok font-bold "
                        >
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Loading...
                      </button>
                      :
                      <button
                          type="submit"
                          onClick={this.handleSubmit.bind(this)}
                          className="btn btn-change-ok font-bold btn-primary"
                        >
                          Search
                      </button>
                    }
 
                  </div>
                </div>
                <div className="table-search-account">
                  {this.renderSearch()}
                </div>

              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment> 
    );
  }
}
const mapStateToProps = state => ({
  dashboard: state.dashboard
});
export default connect(mapStateToProps, { 
  showMessage
})(AdminSystemMaintenance);