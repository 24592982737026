import React, { Component } from 'react';
import Header from '../../partials/header';
import Footer from '../../partials/less-content-footer';
import { connect } from 'react-redux';

import { Modal, Col, Container, Row, Form, Spinner, Button } from 'react-bootstrap';
import AdminDashboard from '../../components/admin-control/admin-users';
import axios from 'axios';
import $ from 'jquery';

axios.defaults.baseURL = process.env.REACT_APP_LOGIN_SIGNUP_API_URL;

class AdminLogin extends Component {
  constructor(){
    super()
    this.state = {
      isLoggedIn: false,
      isDisabled: false,
      username: '',
      password: ''

    }
  }

  componentWillMount() {
    this.checkIfLoggin();
  }

  checkIfLoggin = () => {
    let a = localStorage.getItem('isAdminLogin') 
    if( a != null){
      window.location.replace("/admin-dashboard");
    }
  }

  inputChange = (e) => {
    this.setState({
        ...this.state,
        [e.target.name]: e.target.value
    });
  }

  submitLoginForm = (e) => {
    localStorage.clear();
    this.setState({isDisabled: true});
    $('#error-container').css('display', 'none');
    e.preventDefault();
    return axios 
      .post('api/v1/login',
      {
          username: this.state.username,
          password: this.state.password
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
          if(response.data.status == 'Success'){
            localStorage.setItem('isAdminLogin', true);
            localStorage.setItem('level', response.data.result[0].admin_role);
            localStorage.setItem('adminusername', response.data.result[0].username);
            localStorage.setItem('adminid', response.data.result[0].id);
            setInterval(() => { 
                window.location.replace('/admin-dashboard');
            }, 1000);
            
          }else {
            $('#error-container').css('display', 'block');
            this.setState({isDisabled: false});
          }
      })
      .catch(error => {
          console.log(error);
      });
  }

  render() { 

    return (
      <React.Fragment>
        <Header /> 
        <div id="admin-control-login" >
          <Container>
            <Form onSubmit={this.submitLoginForm.bind(this)} autocomplete="off">
              <Row>
                <Col>
                  <div id="error-container">
                      <h6>Log in failed. Invalid Username or Password.</h6>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                    <Form.Group controlId="admin-username">
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="username"
                            className="valid"
                            onChange={this.inputChange.bind(this)}
                        />
                    </Form.Group>
                    <Form.Group controlId="admin-password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            required
                            type="password"
                            name="password"
                            className="valid"
                            onChange={this.inputChange.bind(this)}
                        />
                    </Form.Group>
                </Col>
              </Row>
              <Row>
                  <Col>
                    {/* <Form.Group>
                      <Form.Check.Input isValid className="admin-check-remember"/>
                      <Form.Check.Label>Remember Me</Form.Check.Label>
                      <p>Forgot your <span>Password?</span></p>
                    </Form.Group> */}
                  </Col>
              </Row>
              <Row>
                <Col>
                {
                  this.state.isDisabled ? 
                    <button type="submit" className="btn btn-primary font-bold isDisabled-admin">Loading Please Wait!</button>
                  :
                    <button type="submit" className="btn btn-primary font-bold">Log In</button>
                }
                    
                </Col>
            </Row>
            </Form>
          </Container>
        </div>
        <Footer />
      </React.Fragment> 
    );
  }
}

export default AdminLogin;