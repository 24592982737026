import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Header from '../partials/header';
import Footer from '../partials/footer';

import { library } from '@fortawesome/fontawesome-svg-core'
// fa-chevron-right
import {
  faChevronRight,
  faCog,
  faSpinner,
  faQuoteLeft,
  faSquare,
  faCheckSquare
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(
  faChevronRight,
  faCog,
  faSpinner,
  faQuoteLeft,
  faSquare,
  faCheckSquare
)

class TermsAndConditions extends Component {
    state = { 
        displayLogin: false
    }
    gotoLogin = (e) => {
        this.setState({
            displayLogin: true
        })
    }
    render() { 
        if(this.state.displayLogin) {
            return <Redirect to="/" />
        }
        return (  
            <React.Fragment>
                <Header />
                <section id="terms_and_conditions">
                    <div className="container">
                        <div id="title" className="row justify-content-md-center">
                            <div className="col-lg-6">
                                <h4 className="primary-font-color text-center">
                                    GPA BILLPAY ON-LINE PAYMENT PORTAL TERMS & CONDITIONS
                                </h4>
                            </div>
                        </div>
                        <div id="body" className="row">
                            <div className="col-lg-12">
                                <p>
                                    Prior to you receiving access to the PayGPA website the Guam Power Authority is requesting that you review the below terms and conditions and indicate your consent to them in order to provide you with this service.
                                </p>
                                <label className="primary-font-color">
                                    <strong>I. INTRODUCTION AND GENERAL INFORMATION.</strong>
                                </label>
                                <p>
                                    The Guam Power Authority’s (GPA) PayGPA website allows you to pay GPA issued residential and commercial electric bills on-line. For residential customers Visa, MasterCard, and Discover are accepted. For Commercial customers MasterCard is the only credit card accepted at this time.  
                                </p>
                                <p>
                                    You may pay any residential electric bill on-line, including past due electric bills. However, since electric payments may not be credited to your account(s) immediately you may be subject to disconnection while the transaction is being processed. Given the potential delays in crediting your residential account by paying on-line, GPA recommends that you immediately pay past due electric bills in person at any of our locations to avoid service interruptions:  
                                </p>
                                <p className="ml-5">
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> Gloria B. Nelson Public Service Building, Mangilao <br />
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> Julale Shopping Center, Hagåtña<br />
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> GPWA Upper Tumon Office, Upper Tumon<br />
                                </p>
                                <p>
                                    You may pay someone else's electric bill for them using your credit card, although you will need to enter the correct GPA residential account information.
                                </p>
                                <p>
                                    If you have any questions regarding GPA's PayGPA website please contact the Guam Power Authority at 647-5787/8/9 from 7:00 a.m. to 6:00 p.m. Chamorro Standard Time, Monday through Friday, excluding Government of Guam and Federal holidays.
                                </p>
                                <label className="primary-font-color">
                                    <strong>II. TERMS AND CONDITIONS FOR USE OF GPA'S PAYGPA WEBSITE.</strong>
                                </label>
                                <p>
                                    By utilizing GPA's PayGPA website, you do hereby agree to the following terms and conditions:
                                </p>
                                <label className="dark-font-color">
                                    <strong>A. GENERAL TERMS:</strong>
                                </label>
                                <p>
                                    To use GPA's PayGPA website you will need to provide accurate and complete billing details supplied by the Guam Power Authority and you will need to provide your complete and accurate credit card information.
                                </p>
                                <p>
                                    You are responsible for entering all information correctly and if by entering the incorrect information you acknowledge that:
                                </p>
                                <p className="ml-5">
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> Your bills may not be paid and all penalties associated with non-payment of your account may be added to your account.<br />
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> Your power may be disconnected for non-payment as per GPA Service Rules & Regulations.<br />
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> GPA is under no responsibility to inform you that your bill has not been paid.<br />
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> You are responsible for any and all fees or charges imposed by your credit card provider or your bank for any reason.<br />
                                </p>
                                <p>
                                    Upon providing GPA with your credit card details you agree that you are authorizing Guam Power Authority to charge the specified amount to your designated account.
                                </p>
                                <p>
                                    Where your credit card issuer or financial institution for any reason declines a payment made by you on-line those bills will be considered not paid.
                                </p>

                                <p>
                                    Credit card payments made on-line will be debited to your credit card account immediately.
                                </p>
                                <p>
                                    If you are uncertain when a payment will be processed contact your designated financial institution
                                </p>
                                <p>
                                    It is your responsibility to ensure that you have sufficient funds available on your credit card in order to allow payments to be debited immediately.
                                </p>
                                <p>
                                    If you believe GPA's PayGPA website transaction is incorrect or unauthorized, you must contact Guam Power Authority’s Customer Service Division immediately.
                                </p>
                                <p>
                                    The Guam Power Authority has access to transaction history and may check the details of the transaction.
                                </p>
                                <p>
                                    Should the dispute require the services of the financial institution, you may be asked to contact your financial institution. Information relating to the dispute may be provided to that financial institution by the Guam Power Authority to assist with the resolution.
                                </p>
                                <label className="dark-font-color">
                                    <strong>B. SECURITY.</strong>
                                </label>
                                <p>
                                    GPA uses standard internet banking security levels that large scale e-commerce sites utilize and which involves the use of standard TLS 1.3 encryption. You can check the security level of a web page by clicking on your browser's padlock or key icon
                                </p>
                                <p>
                                    Encryption is the standard way of protecting your information as it is transmitted between you and the Guam Power Authority. This involves converting the information into an unreadable code using a "key" and also decoding it using this "key." The longer the key, the more difficult it is for others to break the encrypted codes.
                                </p>
                                <p>
                                    To access GPA's PayGPA website, your browser may need to be configured to handle these secure keys. It is highly recommended that you use the latest version of Chrome, Safari, FireFox, or Edge.
                                </p>
                                <label className="dark-font-color">
                                    <strong>C. PRIVACY.</strong>
                                </label>
                                <p>
                                    Personal information collected through GPA's PayGPA website includes your name, address, contact numbers, and other information closely tied to your GPA electric account. Additional information that may be gathered by GPA’s PayGPA website are user countries, domains, browsers, internet service providers and other related data for the purpose of analytics. GPA’s PayGPA website, however, does not store your financial information.
                                </p>
                                <p>
                                    If you wish to request access to any personal information the Guam Power Authority may have collected about you for the purposes of GPA's PayGPA website please provide a written correspondence indicating your request and address it to the General Manager of the Guam Power Authority.
                                </p>
                                <label className="dark-font-color">
                                    <strong>D. WARRANTIES.</strong>
                                </label>
                                <p>
                                    While every effort has been made to ensure that information is free from error, the Guam Power Authority does not warrant the accuracy, adequacy, or completeness of GPA's PayGPA website.
                                </p>
                                <p>
                                    Notice is hereby provided that all information on GPA's PayGPA website and these Terms of Use are subject to change without notice.
                                </p>
                                <p>
                                    The Guam Power Authority does not warrant that GPA's PayGPA website will be free from viruses, that access to GPA's PayGPA website will be uninterrupted, or that the data transferred through GPA's PayGPA website is secure and will not be read or monitored by others.
                                </p>
                                <label className="dark-font-color">
                                    <strong>E. WAIVER AND JURISDICTION.</strong>
                                </label>
                                <p>
                                    The Guam Power Authority makes no representations, either express or implied, that GPA's PayGPA website complies with the laws of any jurisdiction. If you access this site you do so at your own risk and you are responsible for complying with the laws relating to such use in the applicable jurisdiction.
                                </p>
                                <p>
                                    Any disputes arising out of the use of GPA's PayGPA website shall be governed by the laws of the Territory of Guam and other related federal statutes.
                                </p>
                                <label className="dark-font-color">
                                    <strong>F. COPYRIGHT AND TRADEMARKS.</strong>
                                </label>
                                <p>
                                    GPA retains all copyright and trademark rights and unauthorized use is prohibited.
                                </p>
                                <label className="dark-font-color">
                                    <strong>G. 	WITHDRAWAL AND SUSPENSION OF SERVICE.</strong>
                                </label>
                                <p>
                                    GPA's PayGPA website, or your access to it, may be withdrawn or suspended for a period of time for any reason, including, without limitation, the following:
                                </p>
                                <p className="ml-5">
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> If there is an interruption or fault in any applicable computer or telecommunications system.<br />

                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> If you breach any of these Terms & Conditions of Use.<br />

                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> If you use GPA's PayGPA website for an inappropriate purpose, including the storage or transmission of inappropriate or offensive material.<br />

                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> If GPA's PayGPA website and any applicable website computer or telecommunications systems are subjected to any virus, unauthorized access, denial-of-service or other malicious attack.<br />

                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> For maintenance or security purposes.<br />

                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> If Guam Power Authority no longer is able to provide GPA's PayGPA website.<br />

                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> You acknowledge that withdrawal from GPA's PayGPA website does not release you of any responsibility to pay outstanding fees, charges or other liabilities incurred prior to the date of withdrawal.<br />
                                </p>
                                
                                <label className="dark-font-color">
                                    <strong>H. ACCEPTANCE AND CHANGES TO THESE TERMS OF USE.</strong>
                                </label>
                                <p>
                                    <strong>By utilizing GPA's PayGPA website, you agree to the above terms and conditions. You further acknowledge that GPA retains the right to modify the terms of use at any time with or without notice.</strong>
                                </p>
                            </div>
                            <div className="col-lg-12 text-center mb-2 mt-5">
                                <button type="submit" className="btn btn-primary font-bold" onClick={this.gotoLogin.bind(this)}>Back to Homepage</button>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </React.Fragment>
        );
    }
}

export default TermsAndConditions;