import React, { Component } from 'react';
import {Bar} from 'react-chartjs-2';
import { Modal, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { 
    saveAccountId,
    savePremiseAddress,
    fetchMultipleAddOpptyRequest 
} from '../actions/dashboardActions';
import _ from 'lodash';
import Loading from './loading';
class ModalViewChart extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
          show: false,
          showMessage: false,
          consumptionViewBy: 'monthlyKWH',
          isHaveConsumption: false,
          kwhsOptions: {},
          amountsOptions: {},
          kwhsChartData:{},  
          amountsChartData: {},
          isEdit: false,  
          validated: false,
          selectedAccountId: '',
          selectedPremiseAddress: '',
          isLoading: true,
          isMultipleAccount: false,
          prepaidChartPeriod: '0',
        };
    }

    fetchConsumptionData () {
        if(this.state.isLoading){
            let sessionPersonId;
            for(var count = 0; count < Object.keys(localStorage).length; count++){
                if(!(Object.keys(localStorage)[count] === "baseUrl" || Object.keys(localStorage)[count] === "ruulzIndex")){
                    if(Object.keys(localStorage)[count] === 'personId'){
                        sessionPersonId = localStorage.getItem(Object.keys(localStorage)[count]);
                    }
                }
            }
            const accountId = this.props.accId
            const personId = sessionPersonId
            this.props.fetchMultipleAddOpptyRequest(accountId, personId, this.props.accountType, this.props.prepaySAId, this.state.prepaidChartPeriod)
            .then((status) => {
                this.updateState(status.isHaveConsumptionChart)
            })
        }
    }
    updateState = (status) => {
        const userAccountDetails = this.props.dashboard.userAccountDetails;
        const latestBill = this.props.dashboard.latestBill;
        //update details for consumption chart
        if(status !== "False"){
            this.setState({
                ...this.state,
                kwhsOptions: {
                    tooltips: {
                        callbacks: {
                            title: function(tooltipItem, data) {
                                const startDate =  data['labels'][tooltipItem[0]['index']][0];
                                const endDate =  data['labels'][tooltipItem[0]['index']][2];
                                return startDate + ' - ' + endDate;
                            },
                        }
                    },
                    legend: {
                        display: false
                    },
                    scales: {
                        xAxes: [{
                            stacked: true,
                            gridLines: { display: false },
                            ticks: {
                                fontFamily: "Montserrat",
                                fontStyle: "bold",
                                fontColor: '#333333',
                                fontSize: 14,
                            }
                        }],
                        yAxes: [{
                            ticks: {
                                steps: 7,
                                // max: highestKwh,
                                beginAtZero: true, 
                                fontFamily: "Montserrat",
                                fontStyle: "bold",
                                fontColor: '#333333',
                                fontSize: 14,
                            }
                        }]
                    },
                    responsive: false,
                },
                amountsOptions: {
                    tooltips: {
                        callbacks: {
                            title: function(tooltipItem, data) {
                                const startDate =  data['labels'][tooltipItem[0]['index']][0];
                                const endDate =  data['labels'][tooltipItem[0]['index']][2];
                                return startDate + ' - ' + endDate;
                            },
                        }
                    },
                    legend: {
                        display: false
                    },
                    scales: {
                        xAxes: [{
                            stacked: true,
                            gridLines: { display: false },
                            ticks: {
                                fontFamily: "Montserrat",
                                fontStyle: "bold",
                                fontColor: '#333333',
                                fontSize: 14,
                            }
                        }],
                        yAxes: [{
                            ticks: {
                                steps: 7,
                                beginAtZero: true, 
                                fontFamily: "Montserrat",
                                fontStyle: "bold",
                                fontColor: '#333333',
                                fontSize: 14,
                                userCallback: function(value, index, values) {
                                    return '$ ' + value;
                                }
                            }
                        }]
                    },
                    responsive: true,
                },
                kwhsChartData:{
                    labels: this.props.dashboard.consumptionDetails.months,
                    datasets:[
                        {
                            label: 'Electric Consumption',
                            data: this.props.dashboard.consumptionDetails.totalKwhs,
                            backgroundColor:[
                                '#00459e','#00459e','#00459e', '#00459e','#00459e','#00459e', '#00459e','#00459e','#00459e','#00459e','#00459e','#00459e','#00459e','#00459e','#00459e','#00459e','#00459e','#00459e',
                            ]
                        }
                    ],
                },
                amountsChartData:{
                    labels: this.props.dashboard.consumptionDetails.months,
                    datasets:[
                        {
                            label: 'Bill Amount',
                            data: this.props.dashboard.consumptionDetails.amounts,
                            backgroundColor:[
                                '#00459e','#00459e','#00459e', '#00459e','#00459e','#00459e', '#00459e','#00459e','#00459e','#00459e','#00459e','#00459e','#00459e','#00459e','#00459e','#00459e','#00459e','#00459e',
                            ]
                        }
                    ],
                }, 
            }, () => {
                this.setState({
                    isHaveConsumption: true,
                    isLoading: false
                })
            })
        }
        else{
            this.setState({
                isHaveConsumption: false,
                isLoading: false
            })
        }
    }
    changeDataSet(e){
        if(this.props.accountType === "prepaid"){
            this.setState({
                ...this.state,
                prepaidChartPeriod: e.target.value,
                isLoading: true,
            }, () => {
                this.fetchConsumptionData();
            })
        }
        else{
            this.setState({
                ...this.state,
                consumptionViewBy: e.target.value
            })
        }
        
    }
    getAverage = () => {
        try{
            const consumptionDetails = this.props.dashboard.consumptionDetails;
            if(this.state.isHaveConsumption === true){
                if(this.state.consumptionViewBy === 'monthlyBill'){
                    const totalAmounts = consumptionDetails.amounts
                    let sum         = 0;
                    let average     = 0;
                    for(var count = 0; count < totalAmounts.length; count++){
                        sum += parseInt(totalAmounts[count])                  
                    }
                    average = sum / totalAmounts.length
                    return '$ '+String(Math.round(average))
                }
                else if(this.state.consumptionViewBy === 'monthlyKWH'){
                    const totalKwh = consumptionDetails.totalKwhs
                    let sum         = 0;
                    let average     = 0;
                    for(var count=0; count < totalKwh.length; count++){
                        sum += totalKwh[count]                      
                    }
                    average = sum / totalKwh.length
                    return String(Math.round(average))+' KWH'
                }
            }
        }
        catch(ex){
            
        }
    }
    showModal = (e) => {
        this.fetchConsumptionData()
        this.setState({ show: true });
        
    }
    hideModal = (e) => {
        this.setState({ show: false });
    }
    render() { 
        const moreDetails = this.props.dashboard.consumptionDetails.moreDetails;
        const priceSplitter = (number) => (number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
        return ( 
            <React.Fragment>
                <Modal
                    id="bar-chart-modal"
                    show={this.state.show}
                    onHide={this.hideModal.bind(this)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    centered
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.isLoading === true ?
                            <center>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="md"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </center>
                            :
                            this.state.isHaveConsumption ?
                                <React.Fragment>
                                    {
                                         this.props.accountType === 'prepaid' ?
                                         <section>
                                             <div className="row">
                                                 <div className={moreDetails === undefined ? "col-lg-12" : "col-lg-9"}>
                                                     <div id="bar-chart-wrap">
                                                         <div className="row top">
                                                             <div className="col-lg-6 text-center">
                                                                 <label className="primary-font-color font-bold">
                                                                     Your Daily Electricity Consumption Chart
                                                                     {/* {
                                                                         (this.state.consumptionViewBy === 'monthlyBill') ? 
                                                                         'Your Monthly Electricity Bills Chart' :
                                                                         (this.state.consumptionViewBy === 'monthlyKWH') ?
                                                                         'Your Monthly Electricity Consumption Chart' : ''
                                                                     } */}
                                                                 </label>
                                                             </div>
                                                             <div className="col-lg-6 text-center">
                                                                 <p>View By:</p>
                                                                 <select onChange={this.changeDataSet.bind(this)} defaultValue={this.state.prepaidChartPeriod}>
                                                                     <option value="0">0 to 30 days</option>
                                                                     <option value="1">31 to 60 days</option>
                                                                     <option value="2">61 to 90 days</option>
                                                                 </select>
                                                             </div>
                                                         </div>
                                                         <div className="row bottom">
                                                             <div className="col-lg-12 graph-label">
                                                                 <label className="font-bold">
                                                                     KWH
                                                                 </label>
                                                             </div>
                                                             <div className="col-lg-12 scroll-hidden-mobile">
                                                                 <div id="bar-scroll-mobile">
                                                                     {
                                                                         <Bar
                                                                             id="consumption-chart"
                                                                             data={  
                                                                                     (this.state.consumptionViewBy === 'monthlyBill') ? 
                                                                                     this.state.amountsChartData :
                                                                                     (this.state.consumptionViewBy === 'monthlyKWH') ?
                                                                                     this.state.kwhsChartData : ''
                                                                                     }
                                                                             height={300}
                                                                             width={755}
                                                                             style={{margin: "auto"}}
                                                                             options={
                                                                                     (this.state.consumptionViewBy === 'monthlyBill') ? 
                                                                                     this.state.amountsOptions :
                                                                                     (this.state.consumptionViewBy === 'monthlyKWH') ?
                                                                                     this.state.kwhsOptions : ''
                                                                                     }
                                                                             redraw
                                                                         />
                                                                     }
                                                                 </div>
                                                             </div>
                                                             <div className="col-lg-12">
                                                                 <div className="text-center">
                                                                     <br/>
                                                                     <label>
                                                                         {
                                                                             (this.state.prepaidChartPeriod === '0') ? 
                                                                             'Daily Usage for 0 - 30 days' :
                                                                             (this.state.prepaidChartPeriod === '1') ?
                                                                             'Daily Usage for 31 - 60 days' : 
                                                                             (this.state.prepaidChartPeriod === '2') ?
                                                                             'Daily Usage for 61 - 90 days' : ''
                                                                         }
                                                                     </label>
                                                                     <h4>
                                                                         {
                                                                             this.getAverage()
                                                                         }
                                                                     </h4>
                                                                 </div>
                                                             </div>
                                                             {/* <div className="content-right">
                                                             </div> */}
                                                         </div>
                                                     </div>
                                                 </div>
                                                 { 
                                                     moreDetails != undefined ? 
                                                         <div className="col-lg-3">
                                                             <div className="consumption-detail">
                                                                 <div className="label">
                                                                     <p>Prepay Balance</p>
                                                                 </div>
                                                                 <div className="value">
                                                                     <p>{"$ "+priceSplitter(parseFloat(Math.round(moreDetails.prepayBalance * 100) / 100).toFixed(2))}</p>
                                                                 </div>
                                                             </div>
                                                             <div className="consumption-detail">
                                                                 <div className="label">
                                                                     <p>Last Payment</p>
                                                                 </div>
                                                                 <div className="value">
                                                                     <p>{"$ "+priceSplitter(parseFloat(Math.round(moreDetails.lastPaymentAmount * 100) / 100).toFixed(2))}</p>
                                                                 </div>
                                                             </div>
                                                             <div className="consumption-detail">
                                                                 <div className="label">
                                                                     <p>Unpaid Balance</p>
                                                                 </div>
                                                                 <div className="value">
                                                                     <p>{"$ "+priceSplitter(parseFloat(Math.round(moreDetails.unpaidBalance * 100) / 100).toFixed(2))}</p>
                                                                 </div>
                                                             </div>
                                                             <div className="consumption-detail">
                                                                 <div className="label">
                                                                     <p>Last Energy Charge</p>
                                                                 </div>
                                                                 <div className="value">
                                                                     {
                                                                         moreDetails.lastDayCharge == '' ? 
                                                                             <p>NA</p>
                                                                         :   
                                                                            //  _.map(moreDetails.lastDayCharge, (item, index) => {
                                                                            //      return(
                                                                            //          <div>
                                                                            //              <p>{item}</p>
                                                                            //          </div>
                                                                            //      )
                                                                            //  })
                                                                            
                                                                            <p>{"$ "+priceSplitter(parseFloat(Math.round(moreDetails.lastDayCharge * 100) / 100).toFixed(2))}</p>
                                                                     }
                                                                 </div>
                                                             </div>
                                                             <div className="consumption-detail">
                                                                 <div className="label">
                                                                     <p>Last Energy Consumption</p>
                                                                 </div>
                                                                 <div className="value">
                                                                     {
                                                                         moreDetails.lastDayUsage == "" ? 
                                                                             <p>NA</p>
                                                                         :   
                                                                            //  _.map(moreDetails.lastDayUsage, (item, index) => {
                                                                            //      return(
                                                                            //          <div>
                                                                            //              <p>{item}</p>
                                                                            //          </div>
                                                                            //      )
                                                                            //  })
                                                                            <p>{""+priceSplitter(parseFloat(Math.round(moreDetails.lastDayUsage * 100) / 100).toFixed(2))}</p>
                                                                     }
                                                                 </div>
                                                             </div>
                                                             <div className="consumption-detail">
                                                                 <div className="label">
                                                                     <p>Average Daily Charge</p>
                                                                 </div>
                                                                 <div className="value">
                                                                     <p>{"$ "+priceSplitter(parseFloat(Math.round(moreDetails.averageDailyCharge * 100) / 100).toFixed(2))}</p>
                                                                 </div>
                                                             </div>
                                                             <div className="consumption-detail">
                                                                 <div className="label">
                                                                     <p>Average Measured Quantity</p>
                                                                 </div>
                                                                 <div className="value">
                                                                     <p>{"$ "+priceSplitter(parseFloat(Math.round(moreDetails.averageMeasuredQuantity * 100) / 100).toFixed(2))+" KW"}</p>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                     : 
                                                         null
                                                 }
                                                 
                                             </div>
                                         </section>
                                         :
                                         <div id="bar-chart-wrap">
                                             <div className="row top">
                                                 <div className="col-lg-6 text-center">
                                                     <label className="primary-font-color font-bold">
                                                         {
                                                             (this.state.consumptionViewBy === 'monthlyBill') ? 
                                                             'Your Monthly Electricity Bills Chart' :
                                                             (this.state.consumptionViewBy === 'monthlyKWH') ?
                                                             'Your Monthly Electricity Consumption Chart' : ''
                                                         }
                                                     </label>
                                                 </div>
                                                 <div className="col-lg-6 text-center">
                                                     <p>View By:</p>
                                                     <select onChange={this.changeDataSet.bind(this)} defaultValue={this.state.consumptionViewBy}>
                                                         <option value="monthlyKWH">Monthly KWH Consumption</option>
                                                         <option value="monthlyBill">Monthly Bill Amount</option>
                                                     </select>
                                                 </div>
                                             </div>
                                             <div className="row bottom">
                                                 <div className="col-lg-12 graph-label">
                                                     <label className="font-bold">
                                                         {
                                                             (this.state.consumptionViewBy === 'monthlyBill') ? 
                                                             'USD' :
                                                             (this.state.consumptionViewBy === 'monthlyKWH') ?
                                                             'KWH' : ''
                                                         }
                                                     </label>
                                                 </div>
                                                 <div className="col-lg-12 scroll-hidden-mobile">
                                                     <div id="bar-scroll-mobile">
                                                         {
                                                             <Bar
                                                                 id="consumption-chart"
                                                                 data={  
                                                                         (this.state.consumptionViewBy === 'monthlyBill') ? 
                                                                         this.state.amountsChartData :
                                                                         (this.state.consumptionViewBy === 'monthlyKWH') ?
                                                                         this.state.kwhsChartData : ''
                                                                         }
                                                                 height={300}
                                                                 width={980}
                                                                 options={
                                                                         (this.state.consumptionViewBy === 'monthlyBill') ? 
                                                                         this.state.amountsOptions :
                                                                         (this.state.consumptionViewBy === 'monthlyKWH') ?
                                                                         this.state.kwhsOptions : ''
                                                                         }
                                                                 redraw
                                                             />
                                                         }
                                                     </div>
                                                 </div>
                                                 <div className="col-lg-12">
                                                     <div className="text-center">
                                                         <br/>
                                                         <label>
                                                             {
                                                                 (this.state.consumptionViewBy === 'monthlyBill') ? 
                                                                 'Average Bill for '+this.state.amountsChartData.labels.length+' Months' :
                                                                 (this.state.consumptionViewBy === 'monthlyKWH') ?
                                                                 'Average Usage for '+this.state.kwhsChartData.labels.length+' Months' : ''
                                                             }
                                                         </label>
                                                         <h4>
                                                             {
                                                                 this.getAverage()
                                                             }
                                                         </h4>
                                                     </div>
                                                 </div>
                                                 {/* <div className="content-right">
                                                 </div> */}
                                             </div>
                                         </div>
                                    }
                                </React.Fragment>
                            :
                            <h3 class="no-data">There is no data available as of the moment.</h3>
                    }
                    </Modal.Body>
                </Modal>
                <p style={{margin: "0"}} onClick={this.showModal.bind(this)}><img src={process.env.PUBLIC_URL + '/images/chart-bar-regular.svg'} width="15px"/> View Consumption</p>
            </React.Fragment>
        );
    }
}
 
const mapStateToProps = state => ({
    dashboard: state.dashboard
});

export default connect(mapStateToProps, { 
    saveAccountId,
    savePremiseAddress,
    fetchMultipleAddOpptyRequest 
})(ModalViewChart);