import React, { Component } from 'react';
import Header from '../partials/header';
import Footer from '../partials/footer';

class ResultMessage extends Component {
    render() { 
        return (  
            <React.Fragment>
                <Header />
                <section id="result_message">
                    <div className="container">
                        <div id="title" className="row justify-content-md-center">
                            <div className="col-lg-12">
                                <h4 className="primary-font-color text-center"> { this.props.title }</h4>
                            </div>
                        </div>
                        <div id="body" className="row">
                            <div className="col-lg-12 text-center">
                                <p className="font-bold">
                                    { this.props.body }
                                </p>
                            </div>
                            <div className="col-lg-12 text-center mb-2 mt-5">
                                <a href="http://guampowerauthority.com/"><button type="submit" className="btn btn-primary font-bold">Back to Home</button></a>
                            </div>
                            
                            <div className="col-lg-12 text-center">
                                <a href="/" className="dark-font-color font-bold">Login Here</a>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </React.Fragment>
        );
    }
}

export default ResultMessage;