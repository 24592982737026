import React, { Component } from 'react';
import {connect} from 'react-redux'
import { Modal, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';
import Loading from './loading';
import { 
    showMessage
} from '../actions/popupMessageActions';

const baseURL = process.env.REACT_APP_DASHBOARD_API_URL;

class ModalPaperlessBilling extends Component {
    state = { 
        show: false,
        switchedOn: false,
        isProcessing: false,
        isLoading: true,
        updateResult: "",
        showResult: false,
        errorMessage: "",
    }
    componentDidMount() {
        
    }
    getCurrentSetting = () => {
        axios.post(baseURL+'/api/v1/paper-less-details',
        {
            accountId: this.props.accId,
            division: 'GPA'
        },
        {
            headers: {
            'Content-Type': 'application/json',
            },
        })
        .then(response => {
            const result = response.data.result;
            const routeType = result.details.billRouteType
            this.setState({
                switchedOn: routeType === "POSTAL" ? false : true,
                isLoading: false
            })
        })
        .catch(error => {
            this.props.showMessage(true, "Server Error! ");
        });
    }
    paperlessBillingSwitch = (e) => {
        this.setState({
            switchedOn: !this.state.switchedOn,
        })
    }
    updateSettings = (e) => {
        this.setState({
            isProcessing: true
        })

        axios.post(baseURL+'/api/v1/update-paper-less-details',
        {
            accountId: this.props.accId,
            routeType: this.state.switchedOn ? "ONLINE" : "POSTAL",
        },
        {
            headers: {
            'Content-Type': 'application/json',
            },
        })
        .then(response => {
            this.setState({
                isProcessing: false,
                showResult: true,
                updateResult: response.data.response.returnCd,
                errorMessage: response.data.response.errorMessage
            })
        })
        .catch(error => {
            this.props.showMessage(true, "Server Error!");
        });
    }
    showModal = (e) => {
        this.setState({
            show: true,
            isLoading: true,
            showResult: false
        }, () => {
            this.getCurrentSetting();
        })
    }
    hideModal = (e) => {
        this.setState({
            show: false
        })
    }
    hideModal1 = e => {
        e.preventDefault();
        this.setState({
            show: false
        })
    }
    render() { 
        return (  
            <React.Fragment>
                <Modal
                    id="paperless-billing-modal"
                    show={this.state.show}
                    onHide={this.hideModal.bind(this)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    centered
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.isLoading === true ? 
                            <center>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="md"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </center>
                            :
                            this.state.showResult === true ?
                            <React.Fragment>
                                <center>
                                    <img src={process.env.PUBLIC_URL + (this.state.updateResult === "0" ? '/images/Check.gif' : '/images/Cross.gif')} width="80px"/>
                                    <h5 style={{margin: "0", marginTop: "15px"}}>{this.state.updateResult === "0" ? "Your paperless billing has been updated!" : "Sorry, the update failed, please try again later."}</h5>
                                    {this.state.updateResult === "1" ? <p className="m-0">{this.state.errorMessage}</p> : ""}
                                    <button id="close-result" onClick={this.hideModal.bind(this)}>Close</button>
                                </center>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <center><img src={process.env.PUBLIC_URL + '/images/paperless-modal-icon.png'} width="80px"/></center>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-9 col-md-9 col-sm-9 account-info">
                                            <p style={{ fontWeight: "600"}}>Paperless Billing Account ID: {this.props.accId} </p>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 toggle-switch">
                                            <input
                                                className="react-switch-checkbox"
                                                name="paynow"
                                                id="react-switch-new1"
                                                type="checkbox"
                                                checked={this.state.switchedOn}
                                                onClick={this.paperlessBillingSwitch.bind(this)}
                                            />
                                            <label
                                                className={this.state.switchedOn ? 'react-switch-label on' : 'react-switch-label off'}
                                                htmlFor="react-switch-new1"
                                            >
                                                <span className="react-switch-button" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div id="form-actions" className="">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-6 order-lg-last">
                                            <div id="save">
                                                {
                                                    !this.state.isProcessing ? 
                                                        <button type="submit" className="btn btn-primary regular-font-size font-bold" disabled={this.state.isProcessing} onClick={this.updateSettings.bind(this)}>Update</button>
                                                    :  
                                                        <Button variant="primary" disabled>
                                                            <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                            />
                                                        </Button>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6 order-lg-first">
                                            <div id="cancel">
                                                <a id="btnCancelEditAcc" href="/" className="dark-font-color regular-font-size" onClick={this.hideModal1.bind(this)}><u>Cancel</u></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>    
                            </React.Fragment>
                        }
                    </Modal.Body>
                </Modal>
                <p style={{margin: "0"}} onClick={this.showModal.bind(this)}><img src={process.env.PUBLIC_URL + '/images/leaf.png'} width="15px"/> Paperless Billing</p>
            </React.Fragment>
        );
    }
}
 
const mapStateToProps = state => ({
    dashboard: state.dashboard
});

export default connect(mapStateToProps, 
{ 
    showMessage 
})(ModalPaperlessBilling);