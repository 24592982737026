import React, { Component } from "react";
import Header from "../../partials/header";
import Footer from "../../partials/less-content-footer";
import { connect } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import DatePicker from "react-datepicker";
import ReactJson from "react-json-view";

import "react-datepicker/dist/react-datepicker.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import * as moment from "moment";
import { CSVLink } from "react-csv";

import {
  Modal,
  Tabs,
  Tab,
  Table,
  Col,
  Container,
  Row,
  Form,
  Spinner,
  Button,
} from "react-bootstrap";
import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_LOGIN_SIGNUP_API_URL;

class AdminReports extends Component {
  constructor() {
    super();
    this.state = {
      reports: [],
      indivdualList: [],
      pages: "",
      showModalIndividual: false,
      showModalLogs: false,
      Logs: null,
      receiptNum: "",
      startDate: new Date(),
      endDate: new Date(),
      receipt: "",
      sequence_no: "",
      username: "",
      mode: "",
      accountId: "",
      payeezy: "",
      totalOnline: "",
      totalPhonedIn: "",
      sumOnline: "",
      sumPhonedin: "",
      invoice: "",
      transaction_tag: "",
      buttonIsDisabled: false,
      isLoading: true,
      fileName:
        "GPA-Admin-Control-Reports-" + moment().format("DD-MM-YYYY") + ".csv",
    };
  }
  componentWillMount() {
    this.getReports();
  }

  getReports = () => {
    return axios
      .get("/api/v1/transactions", {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        console.log(
          "/api/v1/transactions",
          JSON.stringify(response.data.result.list[0].code_response)
        );
        this.setState({
          pages: response.data.result.pages,
          reports: response.data.result.list,
          sumOnline: response.data.result.total,
          // sumPhonedin: response.data.result.sumPhonedin,
          totalOnline: response.data.result.totalOnline,
          // totalPhonedIn: response.data.result.totalPhonedIn,
          isLoading: false,
        });
      });
  };

  searchReports = (e) => {
    this.setState({ reports: [], buttonIsDisabled: true, isLoading: true });
    e.preventDefault();
    let startDateSave =
      this.state.startDate != null
        ? moment(this.state.startDate).format("MM/DD/YYYY")
        : null;
    let endDateSave =
      this.state.endDate != null
        ? moment(this.state.endDate).format("MM/DD/YYYY")
        : null;
    return axios
      .post(
        "/api/v1/search-transaction",
        {
          pay_date_start: startDateSave,
          pay_date_end: endDateSave,
          accountId: this.state.accountId,
          sequence_no: this.state.sequence_no,
          invoice_num: this.state.invoice_num,
          transaction_tag: this.state.transaction_tag,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        this.setState({
          reports: response.data.result.list,
          sumOnline: response.data.result.total,
          // sumPhonedin: response.data.result.sumPhonedin,
          totalOnline: response.data.result.totalOnline,
          // totalPhonedIn: response.data.result.totalPhonedIn,
          buttonIsDisabled: false,
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  inputChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };

  renderList() {
    const columns = [
      {
        dataField: "payment_date",
        text: "Payment Date",
        sort: true,
      },
      {
        dataField: "accountId",
        text: "Account ID",
        sort: true,
      },
      {
        dataField: "paymentId",
        text: "Payment ID",
        sort: true,
      },
      {
        dataField: "transaction_tag",
        text: "Trans. Tag",
        sort: true,
      },
      {
        dataField: "sequence_no",
        text: "Payeezy Seq. #",
        sort: true,
      },
      {
        dataField: "amount",
        text: "Amount",
        sort: true,
      },
      {
        dataField: "bank_message",
        text: "Bank Message",
      },
      {
        dataField: "payment_source",
        text: "Payment Source",
      },
      {
        dataField: "mode",
        text: "Mode",
        sort: true,
      },
      {
        dataField: "ccb_status",
        text: "In CCB",
        sort: true,
      },
      {
        dataField: "code_response",
        text: "LOGS",
        formatter: (cellContent, row) => {
          return cellContent ? (
            <a
              id={row.id}
              onClick={() => {
                this.setState({
                  showModalLogs: true,
                  Logs: JSON.parse(cellContent),
                });
              }}
            >
              view
            </a>
          ) : (
            <></>
          );
        },
      },
    ];

    const options = {
      paginationSize: 4,
      pageStartIndex: 1,
      // alwaysShowAllBtns: true, // Always show next and previous button
      // withFirstAndLast: false, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: "First",
      prePageText: "Back",
      nextPageText: "Next",
      lastPageText: "Last",
      nextPageTitle: "First page",
      prePageTitle: "Pre page",
      firstPageTitle: "Next page",
      lastPageTitle: "Last page",
      showTotal: true,
      // paginationTotalRenderer: customTotal,
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "30",
          value: 30,
        },
        {
          text: "50",
          value: 50,
        },
      ],
    };

    const defaultSorted = [
      {
        dataField: "payment_date",
        order: "desc",
      },
      {
        dataField: "amount",
        order: "desc",
      },
    ];

    const headers = [
      {
        label: "Payment Date",
        key: "payment_date",
      },
      {
        label: "Account ID",
        key: "accountId",
      },
      {
        label: "Payment ID",
        key: "paymentId",
      },
      {
        label: "Trans. Tag",
        key: "transaction_tag",
      },
      {
        label: "Payeezy Seq. #",
        key: "sequence_no",
      },
      {
        label: "Amount",
        key: "amount",
      },
      {
        label: "Payeezy Seq. #",
        key: "sequence_no",
      },
      {
        label: "Amount",
        key: "amount",
      },
      {
        label: "Bank Message",
        key: "bank_message",
      },
      {
        label: "Payment Source",
        key: "payment_source",
      },
      {
        label: "In CCB",
        key: "ccb_status",
      },
    ];
    return (
      <div id="report-list-container">
        <div class="csv-container">
          <CSVLink
            data={this.state.reports}
            headers={headers}
            filename={this.state.fileName}
            className="btn-export-csv"
          >
            Export CSV
          </CSVLink>
        </div>
        <BootstrapTable
          bootstrap4={true}
          keyField="id"
          data={this.state.reports}
          columns={columns}
          pagination={paginationFactory(options)}
          defaultSorted={defaultSorted}
          noDataIndication="No Payments Made"
          hover
          condensed
          wrapperClasses="table-responsive"
        />
      </div>
    );
  }

  getIndividual = (e) => {
    let id = e.currentTarget.getAttribute("id");
    let receiptNum = e.currentTarget.getAttribute("receipt");
    e.preventDefault();
    return axios
      .get("/api/v1/get-individual/" + id, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        this.setState(
          {
            receiptNum: receiptNum,
            indivdualList: response.data.result.data,
          },
          () => {
            console.clear();

            if (this.state.indivdualList != null) {
              this.setState({
                showModalIndividual: true,
              });
            }
          }
        );
      });
  };

  handleCloseModalIndividual = () => {
    this.setState({ showModalIndividual: false });
  };

  handleCloseModalLogs = () => {
    this.setState({ showModalLogs: false });
  };

  handleChangeStart = (date) => {
    this.setState({
      startDate: date,
    });
  };

  handleChangeEnd = (date) => {
    this.setState({
      endDate: date,
    });
  };

  render() {
    const columns = [
      {
        dataField: "account_number",
        text: "Account No.",
      },
      {
        dataField: "amount",
        text: "Amount",
      },
      {
        dataField: "status",
        text: "Status",
      },
      {
        dataField: "payment_date",
        text: "GWA Interface Data",
      },
    ];

    return (
      <React.Fragment>
        <Modal
          show={this.state.showModalIndividual}
          onHide={this.handleCloseModalIndividual}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          id="modal-for-individual"
          backdrop="static"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <p>Payment Details for Receipt Number: {this.state.receiptNum}</p>
            <BootstrapTable
              bootstrap4={true}
              keyField="id"
              data={this.state.indivdualList}
              noDataIndication="No Web Payment Made"
              columns={columns}
              hover
              condensed
              wrapperClasses="table-responsive"
            />
            <div className="btn-submit-change-ok">
              <button
                type="submit"
                onClick={this.handleCloseModalIndividual}
                className="btn btn-change-ok font-bold btn-primary"
              >
                Close
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.showModalLogs}
          onHide={this.handleCloseModalLogs}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          id="modal-for-individual"
          backdrop="static"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h2 className="mb-20">Payment Logs</h2>
            {/* <p>{this.state.Logs}</p> */}
            <ReactJson theme="solarized" src={this.state.Logs} />
            <div className="btn-submit-change-ok">
              <button
                type="submit"
                onClick={this.handleCloseModalLogs}
                className="btn btn-change-ok font-bold btn-primary"
              >
                Close
              </button>
            </div>
          </Modal.Body>
        </Modal>

        <div id="admin-reports-container">
          <Container fluid={true}>
            <Row>
              <Col>
                <h5>All Payments/Transaction Inquiry</h5>
                <h6>(Successful and Unsuccessful Transactions)</h6>
                <p>Search for a Payment Transactions</p>
                <div className="search-container">
                  <div className="search-container-left">
                    <div className="input-cont">
                      <label>Pay Date Start: </label>
                      <div className="input-right">
                        <DatePicker
                          dateFormat="MM/dd/yyyy"
                          selected={this.state.startDate}
                          onChange={this.handleChangeStart}
                        />
                      </div>
                    </div>
                    {/* <div className="input-cont">
                  <label>Receipt No.: </label><div className="input-right"><input type="text" name="receipt" onChange={this.inputChange.bind(this)} /></div>
                </div>
                <div className="input-cont">
                  <label>User ID:</label><div className="input-right"><input type="text" name="username" onChange={this.inputChange.bind(this)} /></div>
                </div> */}
                    <div className="input-cont">
                      <label>Account No.:</label>
                      <div className="input-right">
                        <input
                          type="text"
                          name="accountId"
                          onChange={this.inputChange.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="input-cont">
                      <label>Invoice No.: </label>
                      <div className="input-right">
                        <input
                          type="text"
                          name="invoice_num"
                          onChange={this.inputChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="search-container-right">
                    <div className="input-cont">
                      <label>Pay Date End: </label>
                      <div className="input-right">
                        <DatePicker
                          dateFormat="MM/dd/yyyy"
                          selected={this.state.endDate}
                          onChange={this.handleChangeEnd}
                        />
                      </div>
                    </div>
                    <div className="input-cont">
                      <label>Transaction Tag: </label>
                      <div className="input-right">
                        <input
                          type="text"
                          name="transaction_tag"
                          onChange={this.inputChange.bind(this)}
                        />
                      </div>
                    </div>
                    {/* <div className="input-cont">
                  <label>Mode:</label>
                    <div className="input-right">
                      <select name="mode" onChange={this.inputChange.bind(this)} >
                        <option value=""></option>
                        <option value="Online">Online</option>
                        <option value="Phoned-In">Phoned-In</option>
                      </select>
                    </div>
                </div> */}
                    <div className="input-cont">
                      <label>Payeezy Sequence No.:</label>
                      <div className="input-right">
                        <input type="text" name="sequence_no" />
                      </div>
                    </div>
                  </div>
                  <div>
                    {this.state.buttonIsDisabled ? (
                      <button
                        type="submit"
                        disabled
                        className="btn btn-change-ok font-bold btn-primary"
                      >
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        onClick={this.searchReports.bind(this)}
                        className="btn btn-change-ok font-bold btn-primary"
                      >
                        Search
                      </button>
                    )}
                  </div>
                </div>

                <div className="total-cont">
                  <div className="total-left">
                    <p>
                      <label>Total No of Online Payments</label>
                      <span>{this.state.totalOnline}</span>
                    </p>
                    {/* <p><label>Total No of Phoned-In Payments</label><span>{this.state.totalPhonedIn}</span></p> */}
                  </div>
                  <div className="total-right">
                    <p>
                      <label>Total Amount of Online Payments</label>
                      <span>{this.state.sumOnline}</span>
                    </p>
                    {/* <p><label>Total Amount of Phoned-In Payments</label><span>{this.state.sumPhonedin}</span></p> */}
                  </div>
                </div>

                {this.state.isLoading ? (
                  <div style={{ marginTop: "50px" }}>
                    <center>
                      <Spinner
                        as="span"
                        animation="border"
                        size="md"
                        role="status"
                        aria-hidden="true"
                      />
                    </center>
                  </div>
                ) : (
                  this.renderList()
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default AdminReports;
