import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { forgotUsername } from '../actions/loginAndSignUpActions';
import axios from "axios";
import { 
  showMessage
} from '../actions/popupMessageActions';

const baseURL = process.env.REACT_APP_LOGIN_SIGNUP_API_URL;

class ForgotUsername extends Component {
    constructor(props, context) {
      super(props, context);
  
      this.handleShow = this.handleShow.bind(this);
      this.handleClose = this.handleClose.bind(this);
  
      this.state = {
        personId: "",
        accountId: "",
        emailAddress: "",
        isAccountId: true,
        isEmail: true,
        isQuestion: true,
        isAnsValid: true,
        resultMessage: "Your username has been sent to your email.",
        show: false,
        showMessage: false,
        isLoading: false,
        question: "",
        ans: "",
        inputAns: "",
        showQuestion: false,
        isAccountIdValid: true,
        isEmailValid: true
      };
    }
    
    onChange = (e) => {
      if(e.target.name === "accountId"){
        this.setState({
          ...this.state,
          [e.target.name]: e.target.value,
          isAccountId: true,
          isAccountIdValid: true
        })
      }
      else if(e.target.name === "emailAddress"){
        this.setState({
          ...this.state,
          [e.target.name] : e.target.value
        },
        () => {
          let email = String(this.state.emailAddress);
          this.setState({isEmailValid: true });
          // eslint-disable-next-line
          if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
            this.setState({ isEmail: true });
          }
          else{
            this.setState({ isEmail: false });
          }
        })
      }else if (e.target.name === "inputAns") {  
        this.setState({
          ...this.state,
          [e.target.name]: e.target.value,
          isAnsValid: true
        });
      }
    }

    handleClose() {
      this.setState({ show: false });
      this.setState({ 
        accountId: '',
        emailAddress: '',
        isAccountId: true,
        isEmail: true,
      });
    }
    
    handleShow() {
      this.setState({ show: true });
    }
    
    handleMessageShow = () => {
      this.setState({ isAccountIdValid: true, isEmailValid: true });
      if (this.state.accountId === "" && this.state.emailAddress === "") {
        this.setState({ isAccountId: false, isEmail: false });
      } else if (this.state.accountId === "") {
        this.setState({ isAccountId: false });
      }else if (this.state.emailAddress === "") {
        this.setState({ isEmail: false });
      }
      else{
        this.setState({
          isLoading: true
        })
        const postData = {
          accountId: this.state.accountId,
          emailAddress: this.state.emailAddress
        }
        this.props.forgotUsername(postData)
        .then((response) => {
          if (response.result.status === "Failed") {
            if(response.result.description == "Account number doesn't exist") {
              this.setState({
                isAccountIdValid: false,
                isLoading: false
              });
            }else if(response.result.description == "Email address does not match") {
              this.setState({
                isEmailValid: false,
                isLoading: false
              });
            }else {
              this.handleClose();
              this.setState({
                resultMessage: response.result.description,
                showMessage: true,
                isLoading: false
              });
            }
          } else {
            let personId = "";
            let ques = "";
            let ans = "";
            response.result.description.map((item, i) => {
              personId = item.PersonID;
              if (item.CharacteristicType == "CMSCQUES") {
                ques = item.CharValueDescription;
              }
              if (item.CharacteristicType == "CMSCAN") {
                ans = item.AdhocCharacteristicValue;
              }
            });
            this.setState({
              showQuestion: true,
              personId: personId,
              question: ques,
              ans: ans,
              isLoading: false,      
              isAccountIdValid: true,
              isEmailValid: true
            });
          }
        })
        .catch(error => {
          this.props.showMessage(true, "Server error! Please try again later!");
          this.setState({
            isLoading: false
          });
        });
    }
  };

  handleSubmitFormForgotUsername = () => {
    this.setState({ isLoading: true, isQuestion: true, isAnsValid: true });
    if (this.state.inputAns === "") {
      this.setState({ isQuestion: false, isLoading: false });
    } else {
      if (this.state.ans.toUpperCase() == this.state.inputAns.toUpperCase()) {
        return axios
          .post(
            baseURL+"/api/v1/send-email-forgot-username",
            {
              personId: this.state.personId
            },
            {
              headers: {
                "Content-Type": "application/json"
              }
            }
          )
          .then(response => {
            this.handleClose();
            this.setState({
              accountId: "",
              emailAddress: "",
              isAccountId: true,
              isEmail: true,
              resultMessage: this.state.resultMessage,
              showMessage: true,
              isLoading: false,
              showQuestion: false,
              isAnsValid: true,
              isAccountIdValid: true,
              isEmailValid: true
            });
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        this.setState({
          isQuestion: true,
          isAnsValid: false,
          isLoading: false
        });
      }
    }
  };

    handleMessageHide = () => {
      this.setState({ showMessage: false });
    }

    render() {
      const emailAddErrorMsg = (this.state.emailAddress === '') ? 'Please enter a your Email Address.' : 'Please enter a valid Email Address.'
      return (
        <>
          <label className="btn-forgot-password" onClick={this.handleShow}>
            Username
          </label>
          <Modal show={this.state.show} onHide={this.handleClose} dialogClassName="forgot-modal" aria-labelledby="contained-modal-title-vcenter" centered>
              <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter"><h5 className="primary-font-color">Forgot Username</h5></Modal.Title>
              </Modal.Header>
              <Modal.Body>
              {this.state.showQuestion ? (
              <div>
                <div className="row pt-3">
                  {this.state.isQuestion === false ? (
                    <label className="secondary-font-color font-bold">
                      Please provide the answer for security question below.
                    </label>
                  ) : (
                    ""
                  )}
                  <div className="col-lg-6 pl-0">
                    <p className="pt-2">{this.state.question}?</p>
                  </div>
                  <div className="col-lg-6 pr-0">
                    <input
                      name="inputAns"
                      onChange={this.onChange.bind(this)}
                    />
                    {this.state.isAnsValid === false ? (
                      <label className="secondary-font-color font-bold">
                        Security Answer is not valid.
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="row">
                  {
                    this.state.isAccountIdValid === false ? 
                    (
                      <label className="secondary-font-color font-bold">
                        Account number doesn't exist.
                      </label>
                    ):
                    ""
                  }
                  {this.state.isAccountId === false ? (
                    <label className="secondary-font-color font-bold">
                      Please enter your Account Number.
                    </label>
                  ) : (
                    ""
                  )}
                  <input
                    name="accountId"
                    type="number"
                    onChange={this.onChange.bind(this)}
                    placeholder="Account Number"
                  /> 
                </div>
                <div className="row pt-3">
                  {
                    this.state.isEmailValid === false ? 
                    (
                      <label className="secondary-font-color font-bold">
                        Email address does not match.
                      </label>
                    ):
                    ""
                  }
                  {this.state.isEmail === false ? (
                    <label className="secondary-font-color font-bold">
                      {emailAddErrorMsg}
                    </label>
                  ) : (
                    ""
                  )}
                  <input
                    name="emailAddress"
                    onChange={this.onChange.bind(this)}
                    placeholder="Email Address"
                  />
                  
                </div>
              </div>
            )}
              </Modal.Body>
              <Modal.Footer>
                    {this.state.showQuestion ? (
                    <div>
                      <a
                        id="cancel"
                        href="/#"
                        className="regular-font-size dark-font-color"
                        onClick={this.handleClose}
                      >
                        Cancel
                      </a>
                      <button
                        type="submit"
                        className="btn btn-primary secondary-back-color regular-font-size "
                        onClick={this.handleSubmitFormForgotUsername}
                        disabled={this.state.isLoading}
                      >
                        {this.state.isLoading ? "Loading..." : "Submit"}
                      </button>
                    </div>
                  ) : (
                    <div>
                      <a
                        id="cancel"
                        href="/#"
                        className="regular-font-size dark-font-color"
                        onClick={this.handleClose}
                      >
                        Cancel
                      </a>
                      <button
                        type="submit"
                        className="btn btn-primary secondary-back-color regular-font-size "
                        onClick={this.handleMessageShow}
                        disabled={this.state.isLoading}
                      >
                        {this.state.isLoading ? "Loading..." : "Submit"}
                      </button>
                    </div>
                  )}
              </Modal.Footer>
          </Modal>

          <Modal show={this.state.showMessage} onHide={this.handleMessageHide} dialogClassName="forgot-modal" aria-labelledby="contained-modal-title-vcenter" centered>
              <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter"><h5 className="primary-font-color">Username Request Processed</h5></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <div className="row">
                      <p className="pt-2 regular-font-size">{this.state.resultMessage}</p>
                  </div>
              </Modal.Body>
          </Modal>
        </>
      );
    }
  }

const mapStateToProps = state => ({
    users: state.users
});

export default connect(mapStateToProps, { forgotUsername, showMessage })(ForgotUsername);