import React, { Component } from 'react';
import Stepper from '../../components/PaymentArrangement/stepper';
import Header from '../../components/PaymentArrangement/header';
import { Table, Form, Spinner } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { 
    showMessage,
} from '../../actions/popupMessageActions';
import { 
    createCase,
    stopDeposit
} from '../../actions/paymentArrangementActions';

class ApplyForPaymentArrangement extends Component {
    state = { 
        accountsResponse: [],
        paBalance: 0,
        totalReqExtBalance: 0,
        isFetching: false
    }
    componentDidMount(){
            const {paAccountsResponse} = this.props.location.state;
            //check all balance
            const validAccountsForPA = paAccountsResponse.filter(({balance}) => balance > 0);
            if(validAccountsForPA.length == 0){
                this.props.showMessage(true,"You don't have valid accounts for payment arrangement! \n Redirecting to your dashboard.");
                setInterval(() => {
                    this.setState({displayDashboard: true});
                }, 2000);
            }
            else{
                this.setState({
                    accountsResponse: validAccountsForPA
                }, () => {
                    const arrPaBalance = this.state.accountsResponse.map((item, index) => item.applyDeposit ? parseFloat(item.balance - item.depAmount) : parseFloat(item.balance));
                    const paBalanceSum = arrPaBalance.reduce((a, b) => { return a + b; }, 0); // ian noob! use 0 for addition and 1 for multiplication
                    this.setState({
                        paBalance: paBalanceSum,
                    })
                })
            }
            
    }
    setTotalReqExtBalance = () => {
        const arrPaBalance = this.state.accountsResponse.filter(({requestExtension}) => requestExtension == true).map((item, index) => item.applyDeposit ? parseFloat(item.balance - item.depAmount) : parseFloat(item.balance));
        const paBalanceSum = arrPaBalance.reduce((a, b) => { return a + b; }, 0); // ian noob! use 0 for addition and 1 for multiplication
        this.setState({
            totalReqExtBalance: paBalanceSum
        })
    }

    async checkIfReqExt() {
        const arrReqExt = this.state.accountsResponse.map(({ requestExtension }) => requestExtension);
        if(!arrReqExt.includes(true)){
            this.props.showMessage(true,'Please check at least 1 account number')
        }else{
            this.setState({
                isFetching: true,
            })
            if(await this.executeRequests()){
                this.setState({
                    displayCaseForm: true
                })
            }else{
                this.setState({
                    isFetching: false,
                })
            }
        }
    }

    async executeRequests() {
        let validCreateCaseAccIds = [];
        // let validCreateCaseAccIds = ["6124753098"];
        //stop deposit
        try {
            const arraAccountIdsWithReAd = this.state.accountsResponse.filter(({requestExtension, applyDeposit}) => requestExtension && applyDeposit).map(({ accountId }) => accountId),
                success = [],
                resultMsg = [];
            if ( arraAccountIdsWithReAd.length > 0 ) {
                const stopDepositRequests = arraAccountIdsWithReAd.map((item, index) => this.props.stopDeposit(item)),
                    arrayOfResponse = await Promise.all(stopDepositRequests);
                console.log('arrayOfResponse',arrayOfResponse);
                arrayOfResponse.forEach( ({ response, status, accountId }) => {
                    if ( status != undefined && status == 'Success' ){
                        success.push(true);
                        validCreateCaseAccIds.push(accountId);
                    } else {
                        success.push(false);
                        resultMsg.push(response.errorMessage);
                    }
                });
                if ( success.includes(false) ) {
                    this.props.showMessage(true, resultMsg.join('\n'));
                    return false;
                }
                console.log('Stop deposit results', { success, resultMsg });
            }
        }
        catch (error) {
            this.props.showMessage(true, 'Server Error!');
        }
        console.log('validCreateCaseAccIds', validCreateCaseAccIds);
        //create case
        let success = [];
        console.log('accountsResponse', this.state.accountsResponse);
        try {
            const arraAccountIdsWithRe = validCreateCaseAccIds.length > 0 ? this.state.accountsResponse.filter((item) => validCreateCaseAccIds.includes(item.accountId)).map((item) => item) : this.state.accountsResponse.filter(({requestExtension}) => requestExtension).map((item) => item),
                resultMsg = [];
            console.log('arraAccountIdsWithRe', arraAccountIdsWithRe);
            if ( arraAccountIdsWithRe.length > 0 ) {
                const stopDepositRequests = arraAccountIdsWithRe.map((item, index) => this.props.createCase(item)),
                    arrayOfResponse = await Promise.all(stopDepositRequests);

                console.log('arrayOfResponse',arrayOfResponse);
                arrayOfResponse.forEach( ({ errorMessage, status, accountId, response }) => {
                    if ( status != undefined && status == 'Success' ){
                        response.accountId = accountId;
                        success.push(true);
                    } 
                    else {
                        success.push(false);
                        resultMsg.push(`(${accountId}) ${errorMessage.errorMessage}`);
                    }
                });
    
                if ( success.includes(false) ) {
                    this.props.showMessage(true, resultMsg.join('\n'));
                    return false;
                }
                console.log('Create case results', { success, resultMsg });
            }
        }
        catch (error) {
            this.props.showMessage(true, 'Server Error! \n'+error);
            return false
        }

        if(success.includes(false)){
            return false
        }
        
        return true;
    }

    reqextOnChange = (item) => {
        let {accountsResponse} = this.state;
        for(let count=0; count<accountsResponse.length; count++){
            if(accountsResponse[count].accountId == item.accountId){
                accountsResponse[count].requestExtension = !accountsResponse[count].requestExtension;
                break;
            }
        }
        this.setState({
            accountsResponse: accountsResponse
        }, () => {
            this.setTotalReqExtBalance();
        })
    }
    render() { 
        const priceSplitter = (number) => (number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
        const formatAmount = (amount) => {
            amount = parseFloat(Math.round(amount * 100) / 100).toFixed(2);
            return priceSplitter(amount);
        }
        if(this.state.displayDashboard) {
            return <Redirect to='/dashboard' />
        }
        if(this.state.displayCaseForm) {
            return <Redirect to={{
                pathname: "/payment-arrangement/case-form", 
                state: {
                    accountSummary: this.props.location.state.accountSummary,
                    paAccountsResponse: this.state.accountsResponse,
                    download: true
                }
            }} />
        }
        return (  
            <React.Fragment>
                <Header />
                <section id="payment-arrangement-apply" className="pt-5">
                    <Stepper />
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center mt-5">
                                <p className="font-bold m-0">Step 3: Request for Payment Arrangement</p>
                                <p>You may request for the total remaining balance of <span className="font-bold">$ {formatAmount(this.state.paBalance)}</span> to be spread across <span className="font-bold">6 billing months.</span> <br/>Please select the account(s) that you wish to include in the payment extension program.</p>
                            </div>
                            <div className="col-lg-6 text-center mt-5 mx-auto">
                            {
                                this.state.accountsResponse.length == 0 ?
                                <Spinner animation="border" size="md" variant="primary" />
                                :
                                <Table id="payment-arrangement-step-table" bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Account #</th>
                                            <th>Balance</th>
                                            <th>Request Extension</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.accountsResponse.map((item, index) => (
                                            <tr>
                                                <td>{item.accountId}</td>
                                                <td>$ {item.applyDeposit ? formatAmount(item.balance - item.depAmount) : formatAmount(item.balance)}</td>
                                                <td>
                                                    <Form.Check
                                                        type={"checkbox"}
                                                        checked={item.requestExtension}
                                                        onChange={() => {this.reqextOnChange(item)}}
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    }     
                                    </tbody>
                                </Table>
                            }
                                <div className="float-left">
                                    <p>Fixed Term: <span className="font-bold">6 Months</span></p>
                                </div>
                                <div className="float-right">
                                    <p>Balance to Apply: <span className="font-bold">$ {formatAmount(this.state.totalReqExtBalance)}</span></p>
                                </div>
                            </div>
                            {
                                this.state.totalReqExtBalance > 0 ?
                                    <div className="col-lg-12 text-center mt-4">
                                        <p>An average monthly amount of <span className="font-bold">$ {formatAmount(this.state.totalReqExtBalance / 6)} will be added to your monthly electric bill for the next 6 billing months.</span></p>
                                    </div>
                                :
                                    null
                            }
                            <div className="col-lg-12 text-center mt-4">
                                <p>By clicking the "I AGREE" button below, you acknowledge consent to the terms and conditions stipulated in the regulations governing the payment extension being offered by the Guam Power Authority.</p>
                            </div>
                            <div className="col-lg-12 mt-5 mb-5 text-center">
                                <div className="row">
                                    <div className="col-lg-3 ml-auto">
                                        <button 
                                            className="btn pa-btn-secondary"
                                            onClick={() => {this.setState({
                                                displayDashboard: true
                                            })}}
                                        >
                                            No Thank you
                                        </button>
                                    </div>
                                    <div className="col-lg-3 mr-auto">
                                        <button 
                                            className="btn pa-btn-primary"
                                            onClick={() => {this.checkIfReqExt()}}
                                            disabled={this.state.isFetching}
                                        >
                                            {this.state.isFetching ? 'Please wait...' : 'I Agree'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    paymentArrangement: state.paymentArrangement
});

export default connect(mapStateToProps, {
    showMessage,
    stopDeposit,
    createCase
})(ApplyForPaymentArrangement);