import React, { Component } from 'react';
import Header from '../partials/header';
import Footer from '../partials/footer';
import MyAccNumber from '../components/myAccNumber'
class Signup extends Component {
    state = {  }
    render() { 
        return (
            <React.Fragment>
                <Header />
                <MyAccNumber />
                <Footer />
            </React.Fragment>
        );
    }
}
 
export default Signup;