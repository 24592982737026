import React, { Component } from 'react';
import Header from '../../components/PaymentArrangement/header';
import { connect } from 'react-redux';
import { 
    getLatestBalance,
} from '../../actions/paymentArrangementActions';
import { 
    showMessage
} from '../../actions/popupMessageActions';
import { Spinner } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import axios from 'axios';

const baseURL = process.env.REACT_APP_PAYNOW_API_URL;

class PaymentSuccess extends Component {
    state = {  
        accountId: '',
        remainingBal: 0,
        paAccounts: [],
        accountSummary: [],
        isFetching: true
    }
    componentDidMount(){
        const arrayURL = window.location.pathname.split("/");
        const invoiceNumber = arrayURL[arrayURL.length-1];
        this.getPaymentResult(invoiceNumber);
    }

    getPaymentResult = (invoiceNumber) => {
        axios.post(baseURL+'/api/payment-details',
        {
            invoice_num: invoiceNumber
        },
        {
            headers: {
            'Content-Type': 'application/json',
            },
        })
        .then(response => {
            console.log(response.data)
            const {accountId, bank_message, paymentId, ccb_status} = response.data.paymentResults[0];
            console.log('bank_messagebank_messagebank_messagebank_messagebank_message',bank_message)
            //call api using account id from url
            this.setState({
                accountId: accountId,
                paymentResult: bank_message,
                paymentId: paymentId,
                ccb_status: ccb_status
            }, () => {
                
                this.props.getLatestBalance(this.state.accountId)
                .then((response) => {
                    if(response){
                        this.setState({
                            remainingBal: response.balance,
                            paAccounts: JSON.parse(localStorage.getItem('paAccounts')),
                            accountSummary: JSON.parse(localStorage.getItem('accountSummary'))
                        }, () => {
                            let {paAccounts, accountSummary, accountId, remainingBal} = this.state;
                            for(let count = 0; count < paAccounts.length; count++){
                                if(paAccounts[count].accountId == accountId){
                                    paAccounts[count].balance = remainingBal; 
                                    paAccounts[count].depAmount = response.depAmount;
                                    paAccounts[count].depCredit = response.depCredit
                                    this.setState({
                                        paAccounts: paAccounts,
                                        isFetching: false
                                    }, () => {
                                        // console.log('this.state.paAccounts payment success',this.state.paAccounts)
                                    });
                                    break;
                                }
                            }
                        })
                    }
                })
                .catch((error) => {
                    this.props.showMessage(true, 'Server Error!');
                });
            
            })
        })
        .catch(error => {
            this.props.showMessage(true, "Server Error! ");
        });
    }

    render() { 
        const priceSplitter = (number) => (number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
        const formatAmount = (amount) => {
            amount = parseFloat(Math.round(amount * 100) / 100).toFixed(2);
            return priceSplitter(amount);
        }
        if(this.state.isFetching){
            return (
                <React.Fragment>
                    <center>
                        <Spinner className="mt-5" animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </center>
                </React.Fragment>
            )
        }
        if(this.state.displayApplyForPA) {
            return <Redirect to={{
                pathname: "/payment-arrangement/apply-for-payment-arrangement", 
                state: {
                    accountSummary: this.state.accountSummary,
                    paAccountsResponse: this.state.paAccounts
                }
            }} />
        }
        if(this.state.displayPayToday) {
            return <Redirect to={{
                pathname: "/payment-arrangement/pay-today", 
                state: {
                    accountSummary: this.state.accountSummary,
                    paAccountsResponse: this.state.paAccounts
                }
            }} />
        }
        if(this.state.displayDashboard) {
            return <Redirect to='/dashboard' />
        }
        return (  
            <React.Fragment>
                <Header />
                <section id="payment-arrangement-thank-you">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center mt-5">
                                {
                                    this.state.paymentResult == "Approved" && this.state.ccb_status != "1" ? 
                                    <img className="gpa_logo" width="100" src={process.env.PUBLIC_URL + '/images/Exclamation.jpg'} alt="gpa-logo"></img>
                                    :
                                    this.state.paymentResult == "Approved" ?
                                    <img className="gpa_logo" src={process.env.PUBLIC_URL + '/images/thank-you.png'} alt="gpa-logo"></img>
                                    :
                                    <img className="gpa_logo" src={process.env.PUBLIC_URL + '/images/pa-payement-failed.png'} alt="gpa-logo"></img>
                                }
                                
                            </div>
                            <div className="col-lg-12 text-center mt-5">
                                {   
                                    this.state.paymentResult == "Approved" && this.state.ccb_status != "1" ? 
                                        <React.Fragment>
                                            <h1 className="font-extra-bold">Oops! Something went wrong.</h1>
                                            <p className="font-bold">We encountered a problem while posting your payment.</p>
                                            <p className="font-bold">Please call <a href="tel:(671) 647-5787/8/9">(671) 647-5787/8/9</a> during regular business hours, or send an email to <a href="mailto:paygpainfo@gpagwa.com">paygpainfo@gpagwa.com</a> make sure that your payment is posted as soon as possible.</p>
                                        </React.Fragment>
                                    :
                                    this.state.paymentResult == "Approved" ?
                                        <React.Fragment>
                                            <h1 className="font-extra-bold">Thank you for your payment!</h1>
                                            <p className="font-bold">Payment Id: {this.state.paymentId}. The remaining balance for account {this.state.accountId} is now $ {formatAmount(this.state.remainingBal)}.</p>
                                        </React.Fragment>
                                    :
                                        <React.Fragment>
                                            <h1 className="font-extra-bold">Sorry, your payment was not processed.</h1>
                                            <p className="font-bold">There was a problem processing your credit card information.</p>
                                            <p className="font-bold">If you repeatedly see this message, complete your payment by contacting GPA directly.</p>
                                        </React.Fragment>
                                }
                                
                            </div>
                            <div className="col-lg-12 mt-5 text-center">
                                <div className="row">
                                    <div className="col-lg-4 ml-auto">
                                        <button 
                                            style={{padding: "10px 30px", width: "100%"}}
                                            className="btn pa-btn-primary"
                                            onClick={() => {this.setState({
                                                displayPayToday: true
                                            })}}
                                        >
                                            Make another payment
                                        </button>
                                    </div>
                                    <div className="col-lg-4 mr-auto">
                                        <button 
                                            style={{padding: "10px 30px"}}
                                            className="btn pa-btn-primary"
                                            onClick={() => {this.setState({
                                                displayApplyForPA: true
                                            })}}
                                        >
                                            Proceed to Payment Arrangement
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 text-center mt-5">
                                <p 
                                    className="font-bold" 
                                    style={{cursor:"pointer"}}
                                    onClick={() => {this.setState({
                                        displayDashboard: true
                                    })}}
                                >
                                    <u>Back to Dashboard</u>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
 
const mapStateToProps = state => ({
    paymentArrangement: state.paymentArrangement
});

export default connect(mapStateToProps, { 
    getLatestBalance,
    showMessage
})(PaymentSuccess);