import { combineReducers } from 'redux';
import userReducer from './userReducer';
import dashboardReducer from './dashboardReducer';
import paynowReducer from './paynowReducer';
import popupMessageReducer from './popupMessageReducer';
import paymentArrangementReducer from './paymentArrangementReducer';

export default combineReducers({
    users: userReducer,
    dashboard: dashboardReducer,
    paynow: paynowReducer,
    popupMessage: popupMessageReducer,
    paymentArrangement: paymentArrangementReducer
})