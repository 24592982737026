import React, { Component } from 'react';

class Header extends Component {
    state = {  }
    render() { 
        return ( 
            <React.Fragment>
                <section id="payment-arrangement-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <img className="gpa_logo" src={process.env.PUBLIC_URL + '/images/GPA-Logo.png'} alt="gpa-logo"></img>
                            </div>
                            <div className="col-lg-6 text-right my-auto">
                                <h3 className="primary-font-color">Request for Payment Arrangement</h3>
                                <p className="font-bold">OUR HOME, OUR ISLAND, OUR POWER COMPANY</p>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
 
export default Header;