import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import $ from 'jquery';
class Loading extends Component {
    componentDidMount(){
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        $('html, body').css({
            overflowY: 'hidden',
        });
    }
    componentWillUnmount() {
        $('html, body').css({
            overflowY: 'auto',
        });
    }
    render() { 
        return ( 
            <div id="loading">
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>
        );
    }
}
 
export default Loading;