import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Loading from "./loading";
import { Form, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import {
  saveOrderData,
  savePaymentData,
  validateVisaPayment,
} from "../actions/dashboardActions";
import { showMessage } from "../actions/popupMessageActions";
import _ from "lodash";
import $ from "jquery";
class PayNowModal extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      showHint: false,
      isLoading: true,
      accountSummary: "",
      subtotal: "",
      showErrMsg: false,
      isPaymentProcessing: false,
      username: "",
      userFullName: "",
      cardDetails: {
        cardHolderName: "",
        cardNumber: "",
        expDate: "",
        validExpDate: "",
        cvv: "",
        billingZipCode: "",
        confirmationEmail: "",
      },
      isVisaChecked: "",
      isMasterCardChecked: "",
    };
  }
  componentWillMount() {
    window.addEventListener("storage", function (e) {
      if (localStorage.getItem("reload") === "true") {
        localStorage.removeItem("reload");
        window.location.reload();
      }
    });
    //session key search
    let username = "";
    for (var count = 0; count < Object.keys(localStorage).length; count++) {
      if (
        Object.keys(localStorage)[count] === "baseUrl" ||
        Object.keys(localStorage)[count] === "ruulzIndex"
      ) {
      } else {
        try {
          if (Object.keys(localStorage)[count] === "username") {
            username = localStorage.getItem(Object.keys(localStorage)[count]);
          }
        } catch (ex) {
          console.log("error");
        }
      }
    }
    console.log("username ", username);
    this.setState({
      userFullName:
        this.props.dashboard.userAccountDetails.fullName === undefined
          ? ""
          : this.props.dashboard.userAccountDetails.fullName,
      username: username,
      mode: this.props.mode === undefined ? "" : this.props.mode,
    });
  }
  showModal = (e) => {
    if (this.props.dashboard.orderData.subTotal === 0) {
      this.setState({
        showErrMsg: true,
        isVisaChecked: false,
        isMasterCardChecked: false,
      });
    } else {
      this.setState({
        show: true,
        isVisaChecked: false,
        isMasterCardChecked: false,
      });
    }
  };
  hideModal = (e) => {
    this.setState({ show: false });
  };
  showCvvHint = (e) => {
    this.setState({ showHint: true });
  };
  hideCvvHint = (e) => {
    this.setState({ showHint: false });
  };
  ccSelectionOnClick = (e) => {
    console.log(this.props.dashboard.orderData.accountSummary);
    if (e.target.name === "visa") {
      this.setState(
        {
          isVisaChecked: true,
          isMasterCardChecked: false,
          accountSummary: this.props.dashboard.orderData.accountSummary,
          subtotal: this.props.dashboard.orderData.subTotal,
        },
        () => {}
      );
    } else {
      this.setState(
        {
          isVisaChecked: false,
          isMasterCardChecked: true,
        },
        () => {}
      );
    }
  };
  inputOnChange = (e) => {
    if (e.target.name === "expDate") {
      this.setState({
        ...this.state,
        cardDetails: {
          ...this.state.cardDetails,
          [e.target.name]: e.target.value,
          validExpDate:
            e.target.value.split("-")[1] +
            e.target.value.split("-")[0].charAt(2) +
            e.target.value.split("-")[0].charAt(3),
        },
      });
    } else {
      this.setState({
        ...this.state,
        cardDetails: {
          ...this.state.cardDetails,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  async savePayment(e) {
    this.setState({
      isPaymentProcessing: true,
    });
    e.preventDefault();
    const accountSummary = this.props.dashboard.orderData.accountSummary;
    const subtotal = this.props.dashboard.orderData.subTotal;
    let arrIsAmountValid = [];
    for (let count = 0; count < accountSummary.length; count++) {
      accountSummary[count].validAmountToBePaid = true;
    }
    arrIsAmountValid.push(true);

    const postData = {
      subTotal: parseFloat(Math.round(subtotal * 100) / 100).toFixed(2),
      accountSummary: accountSummary,
    };
    console.log(
      "arrIsAmountValidarrIsAmountValidarrIsAmountValidarrIsAmountValidarrIsAmountValid",
      arrIsAmountValid.includes(false)
    );
    console.log(
      "arrIsAmountValid.includes(false)arrIsAmountValid.includes(false)arrIsAmountValid.includes(false)",
      arrIsAmountValid.includes(false)
    );
    if (arrIsAmountValid.includes(false)) {
      this.props.saveOrderData(postData);
      this.setState({
        show: false,
        isPaymentProcessing: false,
      });
    } else {
      this.props.saveOrderData(postData);
      this.validUserInputs(subtotal, accountSummary);
    }
  }
  validUserInputs(subtotal, accountSummary) {
    const cardNumber = this.state.cardDetails.cardNumber;
    const usedCC =
      cardNumber.charAt(0) === "4"
        ? "visa"
        : parseInt(cardNumber.charAt(0) + "" + cardNumber.charAt(1)) > 50 &&
          parseInt(cardNumber.charAt(0) + "" + cardNumber.charAt(1)) < 56
        ? "master"
        : "invalid";
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let expDate = new Date(this.state.cardDetails.expDate);
    expDate.setHours(0, 0, 0, 0);
    console.clear();
    console.log("todaytodaytodaytoday", today);
    console.log("expDateexpDateexpDate", expDate);
    if (usedCC === "invalid") {
      this.setState({
        isPaymentProcessing: false,
      });
      this.props.showMessage(true, "Invalid Card Number Format!");
    } else if (this.state.isVisaChecked && usedCC != "visa") {
      this.setState({
        isPaymentProcessing: false,
      });
      this.props.showMessage(true, "Please enter a valid Visa Card Number!");
    } else if (this.state.isMasterCardChecked && usedCC != "master") {
      this.setState({
        isPaymentProcessing: false,
      });
      this.props.showMessage(true, "Please enter a valid Master Card Number!");
    } else if (
      (this.state.cardDetails.cardNumber.length < 16 ||
        this.state.cardDetails.cardNumber.length > 16) &&
      usedCC === "visa"
    ) {
      this.setState({
        isPaymentProcessing: false,
      });
      this.props.showMessage(true, "Please enter a valid Visa Card Number!");
    } else if (
      (this.state.cardDetails.cardNumber.length < 16 ||
        this.state.cardDetails.cardNumber.length > 16) &&
      usedCC === "master"
    ) {
      this.setState({
        isPaymentProcessing: false,
      });
      this.props.showMessage(true, "Please enter a valid Master Card Number!");
    } else if (today > expDate) {
      this.setState({
        isPaymentProcessing: false,
      });
      this.props.showMessage(true, "Invalid card expriry date!");
    } else {
      this.setState(
        {
          subtotal: subtotal,
          accountSummary: accountSummary,
        },
        () => {
          this.executeRequests();
        }
      );
    }
  }

  newTabOpen = () => {
    window.open("/payment-result", "_blank");
  };

  executeRequests = () => {
    localStorage.setItem("reload", "false");

    this.props
      .savePaymentData(this.state)
      .then((result) => {
        localStorage.setItem(
          "accountSummary",
          JSON.stringify(this.props.dashboard.orderData.accountSummary)
        );
        localStorage.setItem("paymentResult", JSON.stringify(result));
        console.log("RESULT", JSON.stringify(result));
        this.setState({
          isPaymentProcessing: false,
        });
        if (result.Transaction_Approved === "true") {
          // localStorage.removeItem('reload')
          // window.location.reload()
          this.setState({
            show: false,
            isVisaChecked: false,
            isMasterCardChecked: false,
          });
        } else {
        }
        this.newTabOpen();
      })
      .catch((error) => {
        let result = {
          Transaction_Approved: "serverFailed",
        };
        localStorage.setItem(
          "accountSummary",
          JSON.stringify(this.state.accountSummary)
        );
        localStorage.setItem("paymentResult", JSON.stringify(result));
        this.setState({
          isPaymentProcessing: false,
        });

        this.newTabOpen();
      });
  };
  showCardType = (cardNumber) => {
    if (cardNumber.charAt(0) === "4") {
      return (
        <img
          className="float-right"
          src={process.env.PUBLIC_URL + "/images/visa-logo.png"}
        />
      );
    } else if (
      parseInt(cardNumber.charAt(0) + "" + cardNumber.charAt(1)) > 50 &&
      parseInt(cardNumber.charAt(0) + "" + cardNumber.charAt(1)) < 56
        ? true
        : false
    ) {
      return (
        <img
          className="float-right"
          src={process.env.PUBLIC_URL + "/images/master-logo.png"}
        />
      );
    } else {
      return "";
    }
  };
  render() {
    var fullName = this.state.userFullName;
    var firstName = fullName.substr(0, fullName.indexOf(" "));
    var lastName = fullName.substr(fullName.indexOf(" ") + 1);
    if (fullName.includes(",")) {
      firstName = fullName.substr(fullName.indexOf(",") + 1);
      lastName = fullName.substr(0, fullName.indexOf(","));
    }
    const userFullname = firstName + " " + lastName;
    return this.props.dashboard.orderData.subTotal === 0 ? (
      <React.Fragment>
        <Modal
          id="my-bills-modal"
          show={this.state.showErrMsg}
          onHide={() => {
            this.setState({ showErrMsg: false });
          }}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <React.Fragment>
              <center>
                <label>
                  <strong>
                    Please select an Account ID and enter an amount to be paid!
                  </strong>
                </label>
              </center>
              <br />
            </React.Fragment>
          </Modal.Body>
        </Modal>
        <div className="text-right-lg">
          <button
            type="submit"
            className="btn btn-primary regular-font-size font-bold"
            onClick={this.showModal.bind(this)}
          >
            Check Out
          </button>
        </div>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Modal
          id="cvv-hint"
          show={this.state.showHint}
          onHide={this.hideCvvHint.bind(this)}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="pl-0">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-lg-6">
                  <img
                    src={process.env.PUBLIC_URL + "/images/cvv-hint.png"}
                    alt="cvv-hint"
                  />
                </div>
                <div className="col-lg-6">
                  <div className="hint-content">
                    <h5>CVV Code</h5>
                    <p>
                      The CVV Code is a security code and is the last 3-digits
                      printed on the signature panel on the back of your credit
                      card.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          id="pay-now-modal"
          show={this.state.show}
          onHide={this.hideModal.bind(this)}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            {
              <React.Fragment>
                <section id="review-order">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="section-title">
                          <h3>Review Your Order</h3>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4">
                        <p>
                          <strong>Account Number</strong>
                        </p>
                        <p>
                          {_.map(
                            this.props.dashboard.orderData.accountSummary,
                            (item, index) => {
                              return item.checked &&
                                parseInt(item.amountToBePaid) > 0
                                ? item.accID + ", "
                                : "";
                            }
                          )}
                        </p>
                      </div>
                      {userFullname.replace(/\s/g, "").length ? (
                        <div className="col-lg-4">
                          <p>
                            <strong>Account Name</strong>
                          </p>
                          <p>{userFullname}</p>
                        </div>
                      ) : (
                        ""
                      )}
                      {/* <div className="col-lg-4">
                                            <p><strong>Total Amount</strong></p>
                                            <p>{this.props.dashboard.orderData.subTotal} USD</p>
                                        </div> */}
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="pt-3">
                          <hr />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section id="payment-options">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="section-title">
                          <h3>Total Amount</h3>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <br />
                        <h5>
                          {"$ " +
                            parseFloat(
                              Math.round(
                                this.props.dashboard.orderData.subTotal * 100
                              ) / 100
                            ).toFixed(2)}
                        </h5>
                      </div>
                    </div>
                    {/* <div className="row" style={
                                        {
                                            backgroundImage: `url(${process.env.PUBLIC_URL + '/images/cc-types.png'})`,
                                            width: "200px",
                                            height: "30px",
                                            marginLeft: "0px",
                                            backgroundPosition: "center",
                                            backgroundSize: "auto",
                                            backgroundRepeat: "no-repeat",
                                            marginTop: "15px"
                                        }
                                    }>
                                        <div className="col-lg-12 p-0">
                                            <Form.Check
                                                type="radio"
                                                label=""
                                                className="float-left"
                                                name="visa"
                                                id="btn-visa"
                                                onClick={this.ccSelectionOnClick.bind(this)}
                                                checked={this.state.isVisaChecked}
                                            />
                                            <Form.Check
                                                type="radio"
                                                label=""
                                                className="float-left"
                                                style={{marginLeft: "80px"}}
                                                name="master-card"
                                                id="btn-master-card"
                                                onClick={this.ccSelectionOnClick.bind(this)}
                                                checked={this.state.isMasterCardChecked}
                                            />
                                           
                                        </div>
                                    </div> */}
                  </div>
                </section>
                <section id="credit-card-info">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="section-title">
                          <h3>Pay with Your Credit Card</h3>
                        </div>
                      </div>
                    </div>
                    <Form onSubmit={this.savePayment.bind(this)}>
                      <div className="row">
                        <div className="col-lg-6">
                          <Form.Group as={Row}>
                            <Form.Label column sm={4}>
                              <strong>Card Holder Name</strong>
                            </Form.Label>
                            <Col sm={8}>
                              <Form.Control
                                type="text"
                                name="cardHolderName"
                                onChange={this.inputOnChange.bind(this)}
                                value={this.state.cardDetails.cardHolderName}
                                required
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label column sm={4}>
                              <strong>Card Number</strong>
                            </Form.Label>
                            <React.Fragment>
                              <Col
                                sm={
                                  this.state.cardDetails.cardNumber.length > 0
                                    ? 6
                                    : 8
                                }
                                className={
                                  this.state.cardDetails.cardNumber.length > 0
                                    ? "pr-0"
                                    : ""
                                }
                              >
                                <Form.Control
                                  type="text"
                                  name="cardNumber"
                                  onChange={this.inputOnChange.bind(this)}
                                  value={this.state.cardDetails.cardNumber}
                                  required
                                />
                              </Col>
                              {this.state.cardDetails.cardNumber.length > 0 ? (
                                <Col sm={2}>
                                  {this.showCardType(
                                    this.state.cardDetails.cardNumber
                                  )}
                                </Col>
                              ) : (
                                ""
                              )}
                            </React.Fragment>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label column sm={4}>
                              <strong>Expiration Date</strong>
                            </Form.Label>
                            <Col sm={4}>
                              <Form.Control
                                type="date"
                                name="expDate"
                                style={{ padding: "5px" }}
                                onChange={this.inputOnChange.bind(this)}
                                value={this.state.cardDetails.expDate}
                                required
                              />
                            </Col>
                            <Form.Label column sm={1}>
                              <strong>CVV</strong>
                            </Form.Label>
                            <Col sm={2}>
                              <Form.Control
                                type="text"
                                name="cvv"
                                onChange={this.inputOnChange.bind(this)}
                                value={this.state.cardDetails.cvv}
                                required
                              />
                            </Col>
                            <Col sm={1} className="p-0">
                              <i
                                class="fas fa-info-circle"
                                style={{
                                  fontSize: "25px",
                                  verticalAlign: "middle",
                                  margin: "5px 0px",
                                }}
                                onClick={this.showCvvHint.bind(this)}
                              ></i>
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label column sm={4}>
                              <strong>Billing Zip Code</strong>
                            </Form.Label>
                            <Col sm={4}>
                              <Form.Control
                                type="text"
                                name="billingZipCode"
                                onChange={this.inputOnChange.bind(this)}
                                value={this.state.cardDetails.billingZipCode}
                                required
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label column sm={4}>
                              <strong>Confirmation Email</strong>
                            </Form.Label>
                            <Col sm={8}>
                              <Form.Control
                                type="text"
                                name="confirmationEmail"
                                onChange={this.inputOnChange.bind(this)}
                                value={this.state.cardDetails.confirmationEmail}
                                required
                              />
                            </Col>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="pt-4">
                            <button
                              type="submit"
                              className="btn btn-primary regular-font-size font-bold"
                              disabled={this.state.isPaymentProcessing}
                            >
                              {this.state.isPaymentProcessing
                                ? "Please wait..."
                                : "Pay Now"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="pt-3">
                          <hr />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </React.Fragment>
            }
          </Modal.Body>
        </Modal>
        <div className="text-right-lg">
          <button
            type="submit"
            className="btn btn-primary regular-font-size font-bold"
            onClick={this.showModal.bind(this)}
          >
            Check Out
          </button>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, {
  saveOrderData,
  savePaymentData,
  validateVisaPayment,
  showMessage,
})(PayNowModal);
