import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { forgotPassword } from '../actions/loginAndSignUpActions';
import axios from "axios";

const baseURL = process.env.REACT_APP_LOGIN_SIGNUP_API_URL;

class ForgotPassword extends Component {
    constructor(props, context) {
      super(props, context);
      this.handleShow = this.handleShow.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.state = {
        personId: '',
        accountId: '',
        username: '',
        isAccountId: true,
        isUsername: true,
        resultMessage: 'Your password has been sent to your email.',
        show: false,
        showMessage: false,
        showErrorMessage: false,
        isLoading: false,
        showQuestion: false,
        isQuestion: true,
        isAnsValid: true,
        question: '',
        ans: '',
        inputAns: '',
        isAccountIdValid: true,
        isUsernameValid: true
      };
    }
  
    handleClose(e) {
      try{e.preventDefault();}catch(ex){}
      this.setState({ show: false });
    }
  
    handleShow() {
      this.setState({ show: true });
    }

    onChange = (e) => {
      if(e.target.name === "username"){
        this.setState({isUsernameValid: true});
        if(e.target.value === ""){
          this.setState({
            isUsername: false
          })
        }
        else{
          this.setState({
            isUsername: true,
            username: e.target.value
          })
        }
      }
      else if(e.target.name === "accountId"){
        this.setState({isAccountIdValid: true});
        if(e.target.value === ""){
          this.setState({
            isAccountId: false
          })
        }
        else{
          this.setState({
            isAccountId: true,
            accountId: e.target.value
          })
        }
      }else if(e.target.name === "inputAns"){
        this.setState({
          ...this.state,
          [e.target.name]: e.target.value,
          isAnsValid: true
        })
      }
    }

    handleMessageShow = () => {
      this.setState({ isAccountIdValid: true, isUsernameValid: true });
      if(this.state.accountId === "" && this.state.username === ""){
        this.setState({
          isAccountId: false,
          isUsername: false
        })
      }else if(this.state.accountId === ""){
        this.setState({
          isAccountId: false
        })
      }else if(this.state.username === ""){
        this.setState({
          isUsername: false
        })
      }else{
          this.setState({
            isLoading: true
          })
          const postData = {
            accountId: this.state.accountId,
            username: this.state.username
          }
          this.props.forgotPassword(postData)
          .then((response) => {

            if(response?.result === undefined || response?.result === null){
              this.setState({
                isLoading: false,
                show: false,
                showErrorMessage: true
              })
            }

            if(response.result.status === "Failed"){
              if(response.result.description == "Account number doesn't exist") {
                this.setState({
                  isAccountIdValid: false,
                  isLoading: false
                });
              }else if(response.result.description == "Username doesn't exist") {
                this.setState({
                  isUsernameValid: false,
                  isLoading: false
                });
              }else {
                this.handleClose();
                this.setState({ 
                  resultMessage: response.result.description,
                  showMessage: true, 
                  show: false,  
                  isLoading: false
                });
              }
            }
            else{
              let personId = "";
              let ques = "";
              let ans = "";
              response.result.description.map((item, i ) => {
                personId = item.PersonID;
                if(item.CharacteristicType == "CMSCQUES"){
                  ques = item.CharValueDescription;
                }
                if(item.CharacteristicType == "CMSCAN"){
                  ans = item.AdhocCharacteristicValue;
                }
              });
              this.setState({ 
                showQuestion: true,
                personId: personId,
                question: ques,
                ans: ans,
                isLoading: false,
                isAccountIdValid: true,
                isUsernameValid: true
              });
            }
            
          })
          .catch((error) => {
            console.log(error)
          })
        }
      }

    handleMessageHide = () => {
      this.setState({ showMessage: false });
    }

    handleSubmitFormForgotPassword = () => {
      this.setState({ isLoading: true, isQuestion: true, isAnsValid: true });
      if (this.state.inputAns === "") {
        this.setState({ isQuestion: false, isLoading: false });
      } else {
        if (this.state.ans.toUpperCase() == this.state.inputAns.toUpperCase()) {
          return axios
            .post(
              baseURL+"/api/v1/send-email-forgot-password",
              {
                personId: this.state.personId
              },
              {
                headers: {
                  "Content-Type": "application/json"
                }
              }
            )
            .then(response => {
              this.handleClose();
              this.setState({
                accountId: "",
                username: "",
                isAccountId: true,
                isEmail: true,
                resultMessage: this.state.resultMessage,
                showMessage: true,
                isLoading: false,
                showQuestion: false,
                isAnsValid: true
              });
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          this.setState({
            isQuestion: true,
            isAnsValid: false,
            isLoading: false
          });
        }
      }
    }

    render() {
      return (
        <React.Fragment>
            <label className="btn-forgot-password" onClick={this.handleShow}>
              Password
            </label>
            <Modal show={this.state.show} onHide={this.handleClose} dialogClassName="forgot-modal" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter"><h5 className="primary-font-color">Forgot Password</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {
                    this.state.showQuestion ? 
                    <div>
                      <div className="row pt-3">
                          {(this.state.isQuestion === false) ? 
                            <label className="secondary-font-color font-bold" >Please provide the answer for security question below.</label>
                            : 
                            ''
                          }
                          <div className="col-lg-6 pl-0">
                            <p className="pt-2">{this.state.question}?</p>
                          </div>
                          <div className="col-lg-6 pr-0">
                            <input name="inputAns"  onChange={this.onChange.bind(this)}  /> 
                            {(this.state.isAnsValid === false) ? 
                              <label className="secondary-font-color font-bold" >Security Answer is not valid.</label>
                            : 
                            ''
                            }
                            
                          </div>
                      </div>
                    </div>
                    :
                    <div>
                      <div className="row">
                        {
                          this.state.isAccountIdValid === false ? 
                          (
                            <label className="secondary-font-color font-bold">
                              Account number doesn't exist.
                            </label>
                          ):
                          ""
                        }
                        {(this.state.isAccountId === false) ? 
                          <label className="secondary-font-color font-bold" >Please enter your Account Number.</label>
                          : 
                          ''
                        }
                        <input type="number" name="accountId" placeholder="Account Number" onChange={this.onChange.bind(this)}/>
                      </div>
                      <div className="row pt-3">
                        {
                          this.state.isUsernameValid === false ? 
                          (
                            <label className="secondary-font-color font-bold">
                              Username doesn't exist.
                            </label>
                          ):
                          ""
                        }
                        {(this.state.isUsername === false) ? 
                          <label className="secondary-font-color font-bold" >Please enter your Username.</label>
                          : 
                          ''
                        }
                        <input name="username" placeholder="Username" onChange={this.onChange.bind(this)}/>
                      </div>
                    </div>
                }
                </Modal.Body>
                <Modal.Footer>
                {
                  this.state.showQuestion ?
                    <div>
                      <a href="/#" onClick={this.handleClose.bind(this)} className="regular-font-size dark-font-color">Cancel</a>
                      <button type="submit" className="btn btn-primary secondary-back-color regular-font-size " onClick={this.handleSubmitFormForgotPassword} disabled={this.state.isLoading}>{this.state.isLoading ? "Loading..." : "Submit"}</button>
                    </div>
                    :
                    <div>
                      <a href="/#" onClick={this.handleClose.bind(this)} className="regular-font-size dark-font-color">Cancel</a>
                      <button type="submit" className="btn btn-primary secondary-back-color regular-font-size " onClick={this.handleMessageShow} disabled={this.state.isLoading}>{this.state.isLoading ? "Loading..." : "Submit"}</button>
                    </div>
                }
                </Modal.Footer>
            </Modal>
            <Modal show={this.state.showMessage} onHide={this.handleMessageHide} dialogClassName="forgot-modal" aria-labelledby="contained-modal-title-vcenter" centered>
              <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter"><h5 className="primary-font-color">Password Request Processed</h5></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <div className="row">
                      <p className="pt-2 regular-font-size">{this.state.resultMessage}</p>
                  </div>
              </Modal.Body>
            </Modal>
            <Modal show={this.state.showErrorMessage} onHide={this.handleMessageHide} dialogClassName="forgot-modal" aria-labelledby="contained-modal-title-vcenter" centered>
              <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter"><h5 className="primary-font-color">Password Request Failed</h5></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <div className="row">
                      <p className="pt-2 regular-font-size">No Response form the Server, Please try again later.</p>
                  </div>
              </Modal.Body>
            </Modal>
        </React.Fragment>
      );
    }
  }

const mapStateToProps = state => ({
  users: state.users
});

export default connect(mapStateToProps, { forgotPassword })(ForgotPassword);