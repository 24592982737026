import React, { Component } from 'react';
import { Form, Col, Button, Spinner } from 'react-bootstrap';
import MyAccDetails from './myAccDetails';
import SuccessMessage from './signupSuccessMsg'
import { connect } from 'react-redux';
import _ from 'lodash';
import { checkUsername,signUpUser } from '../actions/loginAndSignUpActions';
import Loading from './loading';
import { 
    showMessage
} from '../actions/popupMessageActions';
import { Redirect } from 'react-router-dom';
class MyLoginDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSuccess: false,
            isGoBack: false,
            validated: false,
            isUsernameValidating: false,
            isUsernameExist: false,
            isNotPword: false,
            isEmail: true,
            isEmailNotMatch: false,
            isUserHasEmail: false,
            isLoading: false,
            pwErrMsg: "",
            isDeletePhones: {
                homePhone: false,
                mobilePhone: false,
                workPhone: false
            },
            loginDetails: {
                username: '',
                password: '',
                confirmPass: ''
            },
            contactDetails: {
                emailAddress: '',
                confirmEmailAdd: '',
                home: '',
                mobile: '',
                work: ''
            },
            securityDetails: {
                security_question_txt: '',
                security_question_val: '',
                answer: ''
            },
            displayLogin: false
        };
    }
    componentWillMount() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        const userBasicInfo = this.props.users.userBasicInfo;
        const userContactNumbers = this.props.users.userContactNumbers;
        let securityQuestions = this.props.users.securityQuestions;
        this.setState({
            ...this.state,
            isUserHasEmail: userBasicInfo.emailAddress === '' ? false : true,
            contactDetails: {
                ...this.state.contactDetails,
                emailAddress: userBasicInfo.emailAddress,
                home: userContactNumbers.homePhone,
                mobile: userContactNumbers.mobilePhone,
                work: userContactNumbers.workPhone
            },
            securityDetails: {
                ...this.state.securityDetails,
                security_question_txt: securityQuestions[0].description,
                security_question_val: securityQuestions[0].characteristicValue,
            }
        })
        console.log(this.state)
    }
    onSubmit = (e) => {
        const form = e.currentTarget;
        this.setState({
            isLoading: true
        })
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({
                validated: true,
                isLoading: false
            })
        }
        else{
            e.preventDefault();
            e.stopPropagation();
            console.log(this.state)

            if(this.state.isUsernameExist || this.state.isNotPword || !this.state.isEmail){
                this.setState({
                    isLoading: false
                })
            }
            else{
                // alert('pumasok')
                const postData = {
                    personId: this.props.users.userBasicInfo.personId,
                    accountId: this.props.accountId,
                    username: this.state.loginDetails.username,
                    password: this.state.loginDetails.password,
                    email_address: this.state.contactDetails.emailAddress,
                    // home_number: this.state.contactDetails.home,
                    // mobile_number: this.state.contactDetails.mobile,
                    // work_number: this.state.contactDetails.work,
                    // security_question_txt: this.state.userDetails.security_question_txt,
                    security_question_val: this.state.securityDetails.security_question_val,
                    security_answer: this.state.securityDetails.answer,
                    home_number: (this.state.contactDetails.home === this.props.users.userContactNumbers.homePhone) ? '' : this.state.contactDetails.home,
                    mobile_number: (this.state.contactDetails.mobile === this.props.users.userContactNumbers.mobilePhone) ? '' : this.state.contactDetails.mobile,
                    work_number: (this.state.contactDetails.work === this.props.users.userContactNumbers.workPhone) ? '' : this.state.contactDetails.work,
                    isDeletedHome: this.state.isDeletePhones.homePhone,
                    isDeletedMobile: this.state.isDeletePhones.mobilePhone,
                    isDeletedWork: this.state.isDeletePhones.workPhone,
                    homeSeq: (this.state.contactDetails.home === this.props.users.userContactNumbers.homePhone) ? '' : this.props.users.userContactNumbers.homePhoneSeq,
                    mobileSeq: (this.state.contactDetails.mobile === this.props.users.userContactNumbers.mobilePhone) ? '' : this.props.users.userContactNumbers.mobilePhoneSeq,
                    workSeq: (this.state.contactDetails.work === this.props.users.userContactNumbers.workPhone) ? '' : this.props.users.userContactNumbers.workPhoneSeq,
                }
                console.log(postData)
                this.props.signUpUser(postData)
                .then((response) => {
                    if(response.result.status === 'True'){
                        this.setState({
                            isSuccess: true,
                            isLoading: false
                        })
                    }
                    else{
                        this.props.showMessage(true, "Sign Up Failed! \nPlease try again!");
                        this.setState({
                            isLoading: false
                        })
                    }
                })
                .catch((error)=>{
                    this.props.showMessage(true, "Server failed! \nPlease try again!");
                })
            }
        }
    }
    checkUsername = (e) => {
        if(this.state.loginDetails.username !== ''){
            this.setState({...this.state,isUsernameExist:false,isUsernameValidating:true})
            this.props.checkUsername(this.state.loginDetails.username)
            .then((response) => {
            console.log('check user name response')
            console.log(response.status)
                if(response.status === "True"){
                    this.setState({...this.state,isUsernameExist:true,isUsernameValidating:false})
                } 
                else{
                    this.setState({...this.state,isUsernameExist:false,isUsernameValidating:false})
                }
            })
        }
    }
    checkEmail = (e) => {
        let emailAddress = String(this.state.contactDetails.emailAddress);
        let confirmEmailAdd = String(this.state.contactDetails.confirmEmailAdd);
        // eslint-disable-next-line
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress)){
            this.setState({ isEmail: true });
        }
        else{
            this.setState({ isEmail: false });
        }
    }
    onChangeLoginDetails = (e) => {
        if(e.target.name === "password" || e.target.name === "confirmPass"){
            this.setState({
                ...this.state,
                pwErrMsg: "",
                loginDetails: {
                    ...this.state.loginDetails,
                    [e.target.name] : e.target.value
                }
            },
            () => {
                let pword = String(this.state.loginDetails.password);
                let confirm_pword = String(this.state.loginDetails.confirmPass);
                if(pword.length < 8){
                    this.setState({ 
                        isNotPword: true,
                        pwErrMsg: "Your password must be between 8 – 15 characters long"
                    });
                }
                else{
                    if(pword===confirm_pword){
                        this.setState({ isNotPword: false });
                    }else{
                        this.setState({ isNotPword: true, pwErrMsg: "Password doesn't match!" });
                    }
                }
            })
        }
        else{
            this.setState({
                ...this.state,
                loginDetails: {
                    ...this.state.loginDetails,
                    [e.target.name]: e.target.value
                }
            })
        }
    }
    onChangeContactDetails = (e) => {
        if(this.state.isUserHasEmail === false){
            if(e.target.name === "emailAddress" || e.target.name === "confirmEmailAdd"){
                this.setState({
                    ...this.state,
                    contactDetails: {
                        ...this.state.contactDetails,
                        [e.target.name] : e.target.value
                    }
                },
                () => {
                    let email = String(this.state.contactDetails.emailAddress);
                    let confirm_email = String(this.state.contactDetails.confirmEmailAdd);
                    if(email===confirm_email){
                        this.setState({ isEmailNotMatch: false });
                    }else{
                        this.setState({ isEmailNotMatch: true });
                    }
                })
            }
        }
        else if(e.target.name === "mobile" || e.target.name === "work" || e.target.name === "home"){
            if(e.target.value === ""){
                this.setState({
                    ...this.state,
                    contactDetails: {
                        ...this.state.contactDetails,
                        [e.target.name]: e.target.value
                    },
                    isDeletePhones: {
                        ...this.state.isDeletePhones,
                        [e.target.name+"Phone"] : true
                    }
                })
            }
            else{
                this.setState({
                    ...this.state,
                    contactDetails: {
                        ...this.state.contactDetails,
                        [e.target.name]: e.target.value
                    },
                    isDeletePhones: {
                        ...this.state.isDeletePhones,
                        [e.target.name+"Phone"] : false
                    }
                })
            }
        }
        else{
            this.setState({
                ...this.state,
                contactDetails: {
                    ...this.state.contactDetails,
                    [e.target.name]: e.target.value
                }
            })
        }
    }
    onChangeSecurityDetails = (e) => {
        const value = (e.target.value).replace(/[^a-zA-Z0-9 ]/g, "")
        this.setState({
            ...this.state,
            securityDetails: {
                ...this.state.securityDetails,
                [e.target.name]: value
            }
        })
    }
    securityQuestionOnChange = (e) => {
        var select = document.getElementById("secu-ques")
        this.setState({ 
            ...this.state,
            securityDetails: { 
                ...this.state.securityDetails,
                security_question_txt: select[select.selectedIndex].text,
                security_question_val: e.target.value
            } 
        }) 
        console.log(this.state.securityDetails)
    }
    showLogin = () => {
        this.setState({
            displayLogin: true
        })
    }
    goBack = (e) => {
        this.setState({
            isGoBack: true
        })
    }
    
    render() { 
        const errUsernameMsg = this.state.isUsernameExist === true ? 'Username already exists. Kindly choose again.' : 'Please choose your Username!';
        const errUsernameInpClass = this.state.isUsernameExist === true ? 'invalid form-control' : 'valid form-control';
        const errUsernameMsgDisp = this.state.isUsernameExist === true ? 'invalid_msg invalid-feedback' : 'invalid-feedback';
        
        const errPwordMsg = this.state.isNotPword === true ? this.state.pwErrMsg+" " : 'Please enter your Password!';
        const errPwordInpClass = this.state.isNotPword === true ? 'invalid form-control' : 'valid form-control';
        const errPwordMsgDisp = this.state.isNotPword === true ? 'invalid_msg invalid-feedback' : 'invalid-feedback';

        let errEmailMsg = this.state.isEmail === true ? "Please enter your Email Address! " : "Please enter a valid Email Address!";
        let errEmailInpClass = this.state.isEmail === true ? 'valid form-control' : 'invalid form-control';
        let errEmailMsgDisp = this.state.isEmail === true ? ' invalid-feedback' : 'invalid_msg invalid-feedback';
       
        if(this.state.isEmail === true){
            errEmailMsg = this.state.isEmailNotMatch === true ? "Email doesn't match!" : "Please enter your Email Address!";
            errEmailInpClass = this.state.isEmailNotMatch === true ? "invalid form-control" : 'valid form-control';
            errEmailMsgDisp = this.state.isEmailNotMatch === true ? "invalid_msg invalid-feedback" : 'invalid-feedback';
        }

        if(this.state.displayLogin) {
            return <Redirect to="/" />
        }
        return ( 
            (this.state.isSuccess === true) ? 
            <SuccessMessage />
            :
            (this.state.isGoBack === true) ? 
            <MyAccDetails billAddressSource={this.props.billAddressSource}/>
            :
            <React.Fragment>
                <section id="sign-up-title">
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-lg-12 text-center">
                                <h4 className="primary-font-color">Create Account</h4>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="sign-up-stage">
                    <div className="container">
                        <div className="row justify-content-md-center rounded-left rounded-right border-style">
                            <div className="col-lg-4 p-0">
                                <div class="content done rounded-left">
                                    <label>
                                        <span><img src={process.env.PUBLIC_URL + '/images/sign-up-check.png'} alt="sign-up-check"/></span> 
                                        My Account Number
                                    </label>
                                </div>
                            </div>
                            <div className="col-lg-4 p-0">
                                <div class="content done border-left-right">
                                    <label>
                                        <span><img src={process.env.PUBLIC_URL + '/images/sign-up-check.png'} alt="sign-up-check"/></span> 
                                        My Account Details
                                    </label>
                                </div>
                            </div>
                            <div className="col-lg-4 p-0">
                                <div className="content selected rounded-right">
                                    <label>
                                        <span><img src={process.env.PUBLIC_URL + '/images/sign-up-3-light.png'} alt="sign-up-3-light"/></span> 
                                        My Login Details
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Form autocomplete="off" noValidate validated={this.state.validated} onSubmit={this.onSubmit.bind(this)}>
                {/* Login Details */}
                <section id="sign-up-form">
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-lg-12">
                                <div className="form-wrap">
                                    <h5 className="primary-font-color">Login Details</h5>
                                    <br/>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId="username">
                                            <Form.Label className="font-bold">Username <span className="secondary-font-color font-bold mr-1">*</span> (Must differ from PayGWA)</Form.Label>
                                            {(this.state.isUsernameValidating === true) ? <Spinner animation="border" size="sm" /> : ''}
                                            <Form.Control
                                                name="username"
                                                className={"form-input "+errUsernameInpClass}
                                                required
                                                type="text"
                                                // style={{textTransform: "lowercase"}}
                                                onChange={this.onChangeLoginDetails.bind(this)}
                                                onBlur={this.checkUsername.bind(this)}
                                            />
                                            <Form.Control.Feedback id="feedback" className={"font-bold "+errUsernameMsgDisp} type="invalid">
                                                {errUsernameMsg}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId="password">
                                            <Form.Label className="font-bold">Password <span className="secondary-font-color font-bold">*</span> (Must be 8-15 characters)</Form.Label>
                                            <Form.Control
                                                name="password"
                                                className={"form-input "+errPwordInpClass}
                                                required
                                                type="password"
                                                maxlength="15"
                                                onChange={this.onChangeLoginDetails.bind(this)}
                                            />
                                            <Form.Control.Feedback id="feedback" className={"font-bold "+errPwordMsgDisp} type="invalid">
                                                {errPwordMsg}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId="confirm-password">
                                            <Form.Label className="font-bold" >Confirm Password <span className="secondary-font-color font-bold">*</span></Form.Label>
                                            <Form.Control
                                                name="confirmPass"
                                                className={"form-input "+errPwordInpClass}
                                                required
                                                type="password"
                                                maxlength="15"
                                                onChange={this.onChangeLoginDetails.bind(this)}
                                            />
                                            <Form.Control.Feedback className="font-bold" type="invalid">Please confirm your Password!</Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Contact Details */}
                <section id="sign-up-form">
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-lg-12">
                                <div className="form-wrap">
                                    <h5 className="primary-font-color">Contact Details</h5>
                                    <br/>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId="email-add">
                                            <Form.Label className="font-bold">Email Address </Form.Label>
                                            <Form.Control
                                                name="emailAddress"
                                                className={"form-input "+errEmailInpClass}
                                                required
                                                type="text"
                                                defaultValue={this.state.contactDetails.emailAddress}
                                                onChange={this.onChangeContactDetails.bind(this)}
                                                onBlur={this.checkEmail.bind(this)}
                                            />
                                            <Form.Control.Feedback id="feedback" type="invalid" className={"font-bold " + errEmailMsgDisp}>
                                                {errEmailMsg}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    {
                                        this.state.isUserHasEmail === false ?
                                        <Form.Row>
                                            <Form.Group as={Col} md="12" controlId="confirm-email">
                                                <Form.Label className="font-bold">Confirm Email Address </Form.Label>
                                                <Form.Control
                                                    name="confirmEmailAdd"
                                                    className={"form-input "+errEmailInpClass}
                                                    required
                                                    type="text"
                                                    onChange={this.onChangeContactDetails.bind(this)}
                                                />
                                                <Form.Control.Feedback className="font-bold" type="invalid">Please confirm your Email Address!</Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                        :
                                        ''
                                    }
                                    
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId="home-phone">
                                            <Form.Label className="font-bold" >Home Phone </Form.Label>
                                            <Form.Control
                                                name="home"
                                                className="form-input"
                                                type="text"
                                                defaultValue={this.state.contactDetails.home}
                                                onChange={this.onChangeContactDetails.bind(this)}
                                            />
                                            <Form.Control.Feedback className="font-bold" type="invalid">Please enter your Home Phone!</Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId="mobile-phone">
                                            <Form.Label className="font-bold" >Mobile Phone </Form.Label>
                                            <Form.Control
                                                name="mobile"
                                                className="form-input"
                                                type="text"
                                                defaultValue={this.state.contactDetails.mobile}
                                                onChange={this.onChangeContactDetails.bind(this)}
                                            />
                                            <Form.Control.Feedback className="font-bold" type="invalid">Please enter your Mobile Phone!</Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId="work-phone">
                                            <Form.Label className="font-bold" >Work Phone </Form.Label>
                                            <Form.Control
                                                name="work"
                                                className="form-input"
                                                type="text"
                                                defaultValue={this.state.contactDetails.work}
                                                onChange={this.onChangeContactDetails.bind(this)}
                                            />
                                            <Form.Control.Feedback className="font-bold" type="invalid">Please enter your Work Phone!</Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Security Details */}
                <section id="sign-up-form">
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-lg-12">
                                <div className="form-wrap">
                                    <h5 className="primary-font-color">Security Details</h5>
                                    <br/>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId="secu-ques">
                                            <Form.Label className="font-bold">Security Question <span className="secondary-font-color font-bold">*</span></Form.Label>
                                            <Form.Control as="select" name="security_question_txt" ref="sec_ques"  className="form-input"
                                                onChange={ this.securityQuestionOnChange.bind(this) }>
                                                { 
                                                    _.map(this.props.users.securityQuestions, (item, index) => {
                                                        return(
                                                        <option key={index} value={item.characteristicValue}>{item.description}</option>
                                                        )
                                                    })
                                                }
                                            </Form.Control>
                                            <Form.Control.Feedback className="font-bold" type="invalid">Please select your Security Question!</Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId="secu-ans">
                                            <Form.Label className="font-bold">Security Answer <span className="secondary-font-color font-bold">*</span></Form.Label>
                                            <Form.Control
                                                name="answer"
                                                className="form-input"
                                                required
                                                type="text"
                                                onChange={this.onChangeSecurityDetails.bind(this)}
                                            />
                                            <Form.Control.Feedback className="font-bold" type="invalid">Please enter your Security Answer!</Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId="terms-condi">
                                            <Form.Control
                                                name="terms"
                                                className="form-checkbox"
                                                required
                                                type="checkbox"
                                                autocomplete="off"
                                            />
                                            <Form.Label className="font-bold custom-label-terms">I agree to the <a href="/terms-and-conditions" target="_blank">Terms and Conditions</a><span className="secondary-font-color font-bold"> *</span></Form.Label>
                                            <Form.Control.Feedback className="font-bold custom-error-terms" type="invalid">Please check the box.</Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Buttons */}
                <section id="signup-form-buttons" className="mb-0">
                    <div className="container">
                        <div className="row buttons-wrap">
                            <div className="col-lg-12">
                                <div className="left">
                                    <Button className="btn-back" onClick={this.goBack.bind(this)}>Back</Button>
                                    <Button className="btn-cancel" onClick={this.showLogin.bind(this)}>Cancel</Button>
                                </div>
                                <div className="right">
                                    {
                                        !this.state.isLoading ? 
                                            <Button className="btn-submit" type="submit" disabled={this.state.isUsernameValidating}>Submit</Button>
                                        :  
                                            <Button variant="primary" disabled>
                                                <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                />
                                                Loading...
                                            </Button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                </Form>
                <section id="sign-up-form-bottom">
                    <div className="container">
                        <div className="row content-wrap">
                            <div className="col-lg-6 left">
                                <label>Fields marked as * are mandatory</label>
                            </div>
                            <div className="col-lg-6 right">
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
 
const mapStateToProps = state => ({
    dashboard: state.dashboard,
    users: state.users
});

export default connect(mapStateToProps, { checkUsername, signUpUser, showMessage })(MyLoginDetails);