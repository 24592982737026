import React, { Component } from "react";
import { Navbar, Nav } from "react-bootstrap";
import PaymentSuccess from "../components/paymentSuccess";
import PaymentUserFailed from "../components/paymentUserFailed";
import PaymentServerFailed from "../components/paymentServerFailed";
import axios from "axios";
import { connect } from "react-redux";
import { showMessage } from "../actions/popupMessageActions";

const baseURL = process.env.REACT_APP_PAYNOW_API_URL;

class PaymentResult extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      paymentResult: "",
      accountSummary: "",
      isFetchingData: true,
    };
  }
  componentDidMount() {
    const arrayURL = window.location.href.split("/");
    const invoiceNumber = arrayURL[arrayURL.length - 1];

    //AXIOS CALL
    this.getPaymentResult(invoiceNumber);

    // this.setState({
    //     paymentResult: JSON.parse(localStorage.getItem('paymentResult')),
    //     accountSummary: JSON.parse(localStorage.getItem('accountSummary'))
    // }, () => {
    //     if(this.state.paymentResult.status === undefined){
    //         // window.close();
    //         alert(this.state.paymentResult.status)
    //     }
    //     else{
    //         localStorage.removeItem('paymentResult')
    //         localStorage.removeItem('accountSummary')
    //     }
    // })
  }

  saveLogs = async (transaction_tag, code_response) => {
    return axios
      .post(
        process.env.REACT_APP_LOGIN_SIGNUP_API_URL + `/api/save-log`,
        {
          transaction_tag: transaction_tag,
          code_response: JSON.stringify(code_response),
        },
        {
          headers: {
            "Content-Type": "application/json",
            origin: "https://paygpa.com",
          },
        }
      )
      .then(function (response) {
        return response.data;
      })
      .catch((error) => {
        console.log("saveLogs error", error);
        return error;
      });
  };

  getPaymentResult = (invoiceNumber) => {
    axios
      .post(
        baseURL + "/api/payment-details",
        {
          invoice_num: invoiceNumber,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        const paymentResult = response.data.paymentResults[0];
        const accountSummary = response.data.accountSummary[0];
        // this.saveLogs(paymentResult.transaction_tag, {
        //   type: "WEB",
        //   paymentResult: paymentResult,
        //   accountSummary: accountSummary,
        // });
        this.setState(
          {
            paymentResult: response.data.paymentResults[0],
            accountSummary: response.data.accountSummary[0],
          },
          () => {
            console.log("this.state.paymentResult", this.state.accountSummary);
            this.setState({
              isFetchingData: false,
            });
          }
        );
      })
      .catch((error) => {
        this.props.showMessage(true, "Server Error! ");
      });
  };

  render() {
    return this.state.isFetchingData ? (
      <center>Loading...</center>
    ) : (
      <React.Fragment>
        <section id="payment-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 text-right">
                <br />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 nav-main">
                <div
                  className="nav-logo text-center"
                  style={{ padding: "60px", paddingTop: "0px" }}
                >
                  <img
                    className="gpa_logo"
                    src={process.env.PUBLIC_URL + "/images/GPA-Logo.png"}
                    alt="gpa-logo"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="payment-content">
          {this.state.paymentResult.bank_message === "Approved" ? (
            <PaymentSuccess
              result={this.state.paymentResult}
              accountSummary={this.state.accountSummary}
            />
          ) : (
            <PaymentUserFailed
              result={this.state.paymentResult}
              accountSummary={this.state.accountSummary}
            />
          )}
        </section>
        <div id="footer-bottom" style={{ border: "1px solid #ebebeb" }}>
          <div className="container">
            <div className="row">
              <div className="footer-bottom-content">
                <div className="dark-font-color footer-bottom-text">
                  <p>All Rights Reserved © 2020 Guam Power Authority</p>
                </div>
                <div className="dark-font-color footer-bottom-icons">
                  <img
                    src={process.env.PUBLIC_URL + "/images/guam-logo-small.png"}
                    alt="guam-logo-small"
                  ></img>
                  <img
                    src={process.env.PUBLIC_URL + "/images/guam-waterworks.png"}
                    alt="guam-waterworks"
                  ></img>
                  <img
                    src={process.env.PUBLIC_URL + "/images/guam-ccu.png"}
                    alt="guam-ccu"
                  ></img>
                  <img
                    src={process.env.PUBLIC_URL + "/images/guam-me-energy.png"}
                    alt="guam-me-energy"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, {
  showMessage,
})(PaymentResult);
