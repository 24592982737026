import React, { Component } from 'react';
import Header from '../../components/PaymentArrangement/header';
import { Table,Spinner,Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { 
    paArrList,
    getFilePath
} from '../../actions/paymentArrangementActions';
import { 
    showMessage
} from '../../actions/popupMessageActions';
import { Redirect } from 'react-router-dom';

class PaymentArrangementInfo extends Component {
    state = { 
        paAccountsResponse: [],
        totalOweBal: 0,
        displayUseDeposit: false,
        displayDashboard: false,
        isFetching: true,
        isLoading: false,
        showError: false
    }
    componentDidMount(){
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        
        const accountSummary = this.props.location.state.accountSummary;
        const customerName = this.props.location.state.customerName;
        const emailAddress = this.props.location.state.emailAddress;
        //get all account ids from redirect props and this will be used on next page
        const residAccountIds = accountSummary.filter(({className}) => className == "RESID").map((item, index) => item);
        console.log('residAccountIds',residAccountIds)
        const accountIds = residAccountIds.filter(({amount}) => amount > 0).map(({ accID }) => accID);
        this.props.paArrList(accountIds, customerName, emailAddress)
        .then((response) => {
            if(response){
                this.setState({
                    paAccountsResponse: this.props.paymentArrangement.paAccounts,
                    isFetching: false,
                    isLoading: false,
                }, () => {
                    const {paAccounts} = this.props.paymentArrangement
                    if(paAccounts.length > 0){
                        const arrAccountBalance = paAccounts.filter(({balance}) => balance > 0).map(({ balance }) => parseFloat(balance));
                        const accountSummarySum = arrAccountBalance.reduce((a, b) => { return a + b; }, 0); // ian noob! use 0 for addition and 1 for multiplication
                        this.setState({
                            ...this.state,
                            totalOweBal: accountSummarySum
                        });
                    }
                    else{
                        // this.props.showMessage(true, "You don't have any eligible accounts for Payment Arrangment! \n Redirecting to your dashboard.");
                        this.setState({
                            showError: true
                        });
                    }
                })
            }
        })
        .catch((error) => {
            this.props.showMessage(true, 'Error occured while processing your request for payment arrangement!');
            this.setState({
                displayDashboard: true
            });
        });

        
    }
    hideErrorPopup = (e) => {
        this.setState({
            showError: false,
            displayDashboard: true
        })
    }
    render() { 
        const priceSplitter = (number) => (number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
        const formatAmount = (amount) => {
            amount = parseFloat(Math.round(amount * 100) / 100).toFixed(2);
            return priceSplitter(amount);
        }

        if(this.state.displayUseDeposit) {
            return <Redirect to={{
                pathname: "/payment-arrangement/use-deposit", 
                state: {
                    paAccountsResponse: this.state.paAccountsResponse,
                    accountSummary: this.props.location.state.accountSummary
                }
            }} />
        }
        if(this.state.displayDashboard) {
            return <Redirect to='/dashboard' />
        }
        if(this.state.isFetching){
            return (
                <React.Fragment>
                    <br/>
                    <center><Spinner animation="border" size="md" variant="primary" /></center>
                    <section style={{width: '100%', margin: 'auto', height: '2000px', padding: '0px 40px', backgroundColor: '#fff', zIndex: '1111', position: 'absolute'}}>

                    </section>
                </React.Fragment>
            );
        }
        return (  
            <React.Fragment>
                <Modal
                    id="popup-message"
                    show={this.state.showError}
                    onHide={this.hideErrorPopup.bind(this)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    centered
                >
                    <Modal.Header closeButton>
                        <h5 className="mb-0"><img src={process.env.PUBLIC_URL + '/images/danger.png'} /> Sorry</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <center><label style={{whiteSpace: 'pre-line'}}>Our records show that none of your accounts meet the criteria to apply for a Payment Arrangement. Please send an email to <a href="mailto:paygpainfo@gpagwa.com">paygpainfo@gpagwa.com</a> or call <a href="tel:(671) 647-5787/8/9">(671) 647-5787/8/9</a> if you still wish to make further inquiries. Thank you.</label></center>
                        </div>
                    </Modal.Body>
                </Modal>
                {
                    this.state.showError ?
                    null
                    :
                    <React.Fragment>
                        <Header />
                <section id="payment-arrangement-info">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center mt-5">
                                <h3 className="font-bold mb-3">
                                    You currently owe <span className="secondary-font-color">$ {formatAmount(this.state.totalOweBal)}</span> on your combined accounts. 
                                </h3>
                                <p>
                                <strong>Commercial accounts</strong>, please email <a href="mailto:businessfirst@gpagwa.com">businessfirst@gpagwa.com</a> to set up your payment arrangement.
                                </p>
                                <p>
                                In order to help stem the economic impact of the Covid-19 pandemic on your household, the Guam Power Authority would like to give you the opportunity to apply for a 6-month payment arrangement on your outstanding electric bills to date.   
                                </p>
                            </div>
                            <div className="col-lg-8 mt-5 mx-auto">
                                <div className="table-header">
                                    <h4 className="font-bold">How the Payment Arrangement Works:</h4>
                                </div>
                                <Table>
                                    <tbody>
                                        <tr>
                                            <td className="dark-table-data">Current Balance to Date</td>
                                            <td className="light-table-data">$ {formatAmount(this.state.totalOweBal)}</td>
                                        </tr>
                                        <tr>
                                            <td className="dark-table-data">Applied Deposit (if applicable)</td>
                                            <td className="light-table-data">0.00</td>
                                        </tr>
                                        <tr>
                                            <td className="dark-table-data">Additional Payment Today (if applicable)</td>
                                            <td className="light-table-data">0.00</td>
                                        </tr>
                                        <tr>
                                            <td className="dark-table-data">Eligible for Payment Arrangement</td>
                                            <td className="light-table-data">$ {formatAmount(this.state.totalOweBal)}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                            <div className="col-lg-12 mt-4 text-center">
                                <p className="m-0"><span className="font-bold">Fixed Payment</span> Arrangement Term = <span className="font-bold">6 Months</span></p>
                                <p className="m-0">Payment Arrangement Amount = $ {formatAmount(this.state.totalOweBal)} / 6 = <span className="font-bold">$ {formatAmount(this.state.totalOweBal / 6)}</span></p>
                                <p className="m-0">The amount of <span className="font-bold">$ {formatAmount(this.state.totalOweBal / 6)} will be added</span> and included as part of your <span className="font-bold">next six (6)</span> electric statements.</p>
                            </div>
                            <div className="col-lg-8 mt-5 mx-auto">
                                <div className="text-center mb-2">
                                    <p className="font-bold m-0">Example:</p>
                                    <p>If your electric billed consumption is $200.00</p>
                                </div>
                                <div className="table-header">
                                    <h4 className="font-bold">Calculation will be as follows:</h4>
                                </div>
                                <Table>
                                    <tbody>
                                        <tr>
                                            <td className="dark-table-data" style={{width: "115px"}}>Total Due</td>
                                            <td className="light-table-data text-left">Incurred Electric Consumption Amount + Payment Arrangement Amount</td>
                                        </tr>
                                        <tr>
                                            <td className="dark-table-data">Total Due</td>
                                            <td className="light-table-data text-left">$ 200.00 + $ {formatAmount(this.state.totalOweBal / 6)}</td>
                                        </tr>
                                        <tr>
                                            <td className="dark-table-data font-bold" style={{fontWeight: "700"}}>Total Due</td>
                                            <td className="light-table-data text-left" style={{fontWeight: "700"}}>$ {formatAmount((this.state.totalOweBal / 6) + 200)}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                            <div className="col-lg-12 mt-4 text-center">
                            Failure to abide by payment arrangement terms may result in service disconnection.

To arrange a customized payment plan, contact GPA at <a href="mailto:customersfirst@gpagwa.com ">customersfirst@gpagwa.com </a> or call <a href="tel:647-5787">647-5787</a>.
                                {/* <p>The total amount due will be <span className="font-bold">subject to late payment charges</span> as well as service disconnection if not paid on time.</p> */}
                            </div>
                            <div className="col-lg-12 mt-5 mb-5 text-center">
                                <div className="row">
                                    <div className="col-lg-3 ml-auto">
                                        <button 
                                            className="btn pa-btn-secondary"
                                            onClick={() => {this.setState({displayDashboard: true})}}
                                        >
                                            Not interested
                                        </button>
                                    </div>
                                    <div className="col-lg-3 mr-auto">
                                        <button 
                                            className="btn pa-btn-primary"
                                            onClick={() => {
                                                if(!this.state.isFetching){
                                                    this.setState({displayUseDeposit: true})
                                                }else{
                                                    this.setState({isLoading: true, redirectAfterFetch: true})
                                                }
                                            }}
                                        >
                                            {this.state.isLoading ? 'Please wait...' : 'I want to apply!'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                    </React.Fragment>
                }
                
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    paymentArrangement: state.paymentArrangement
});

export default connect(mapStateToProps, { 
    paArrList,
    showMessage,
    getFilePath
})(PaymentArrangementInfo);