import React, { Component } from 'react';
import { Provider } from 'react-redux'; 
import store from './store';

import Body from './pages/body';

import './AdminControl.css';
import './less-content-footer.css';
import './style.css';
import './dashboard.css';

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <React.Fragment>
          <Body />
        </React.Fragment>
      </Provider>
    );
  }
}

export default App;