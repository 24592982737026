import React, { Component } from 'react';
import Header from '../partials/header';
import Footer from '../partials/footer';
import CreateAccount from './signUpCreateAccount';
import MyAccDetails from '../components/myAccDetails'
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { checkAccountNumber,getOtherDetails } from '../actions/loginAndSignUpActions';

class SignInAccountNumber extends Component {
    constructor(){
        super()
        this.state = {
            accountId: '',
            showMessage: 'none',
            resultMessage: '',
            showSample: false,
            isLoading: false,
            isSignInSuccess: false
        }
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.onChange.bind(this)
        this.onSubmit.bind(this)
    }

    onChange = (e) => {
        if(e.target.value.length > 10 || e.target.value.length < 10)
        {
            this.setState({ 
                showMessage: 'block', 
                resultMessage: 'Account number is invalid.', 
            })
        }
        else
        {
            this.setState({
                [e.target.name]: e.target.value,
                showMessage: 'none',
                resultMessage: ''
            })
        }
    }
    onSubmit = (e) => {
        e.preventDefault();
        // if(this.state.showMessage !== 'block' && this.state.resultMessage !== 'Account number is invalid.') {
        //     this.setState({ isLoading: true })
        //     const postData = {
        //         accountId: this.state.accountId
        //     }
        //     this.props.checkAccountNumber(postData)
        //     .then((response) => {
        //         if(response.status === "False"){
        //             this.props.getOtherDetails(response.data.personId)
        //             .then(() => {
        //                 this.setState({
        //                     isSignInSuccess: true
        //                 })
        //             })
        //         }
        //         else{
        //             this.setState({ showMessage: 'block', resultMessage: response.description, isLoading: false })
        //         }
        //     })
        //     .catch((error)=>{
        //         console.log(error);
        //     })
        // }
        this.setState({
            isSignInSuccess: true
        })
    }

    handleClose() {
        this.setState({ show: false });
    }
    
    handleShow(e) {
        e.preventDefault();
        this.setState({ showSample: true });
    }
    
    render() { 
        return ( 
            <React.Fragment>
                <Header />
                {(this.state.isSignInSuccess === true) ?
                    <MyAccDetails />
                :
                    <section id="account_number">
                        <div className="container">
                            <div id="title" className="row justify-content-md-center pt-4">
                                <div className="col-lg-12 text-center">
                                    <h4 className="primary-font-color">Create Account</h4>
                                </div>
                            </div>
                            <div id="sign-up-stage" className="row justify-content-md-center mt-4 rounded-left rounded-right">
                                <div className="col-lg-4 p-0">
                                    <div class="content selected rounded-left">
                                        <label>
                                            <span><img src={process.env.PUBLIC_URL + '/images/sign-up-1-light.png'} alt="sign-up-1-light"/></span> 
                                            My Account Number
                                        </label>
                                    </div>
                                </div>
                                <div className="col-lg-4 p-0">
                                    <div class="content border-left-right">
                                        <label className="dark-font-color">
                                            <span><img src={process.env.PUBLIC_URL + '/images/sign-up-2-dark.png'} alt="sign-up-2-dark"/></span> 
                                            My Account Details
                                        </label>
                                    </div>
                                </div>
                                <div className="col-lg-4 p-0">
                                    <div className="content rounded-right">
                                        <span><img src={process.env.PUBLIC_URL + '/images/sign-up-3-dark.png'} alt="sign-up-3-dark"/></span> 
                                        <label className="dark-font-color">
                                            My Login Details
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-md-center">
                                <div className="col-lg-7 custom-margin">
                                    <div id="message" style={{ display: this.state.showMessage }}>
                                        <div className="col-lg-12 text-center">
                                            <label className="secondary-font-color font-bold">{ this.state.resultMessage }</label>
                                        </div>
                                    </div>
                                    <div className="gpa-form">
                                        <div>
                                            <label className="dark-font-color font-bold">Enter the 10-digit account number</label>
                                        </div>
                                        <div>
                                            <input name="accountId" type="text" className="gpa-form-input" onChange={this.onChange} required/>
                                            <button type="submit" onClick={this.onSubmit}className="btn btn-primary gpa-form-button regular-font-size font-bold" disabled={this.state.isLoading}>{(this.state.isLoading) ? 'Verifying' : 'Continue'}</button>
                                        </div>
                                        <div>
                                            <a href="/" onClick={this.handleShow} className="secondary-font-color regular-font-size">Where can I find my account number?</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="quick_links1" className="row justify-content-md-center">
                                <div className="col-lg-12">
                                    <div className="text-center mobile-view">
                                        <p className="dark-font-color no-line-height">Already registered?</p>
                                        <a href="/"><p className="primary-font-color no-line-height">Login Here</p></a>
                                    </div>
                                </div>
                            </div>
                            <div id="quick_links2" className="row justify-content-md-center pt-2 pb-4">
                                <div className="col-lg-12">
                                    <div className="text-center mobile-view">
                                        <a href="/"><p className="secondary-font-color">Need Help?</p></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Modal show={this.state.showSample} onHide={this.handleClose} dialogClassName="hint-modal" aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Body>
                                <div className="hint">
                                    <img src={process.env.PUBLIC_URL + '/images/find-account-number.png'} alt="find-account-number"/>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </section>
                }
                <Footer />
            </React.Fragment> 
        );
    }
}

const mapStateToProps = state => ({
    users: state.users
});

export default connect(mapStateToProps, { checkAccountNumber,getOtherDetails })(SignInAccountNumber);