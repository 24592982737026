import React, { Component } from 'react';
import Header from '../../components/PaymentArrangement/header';
import Stepper from '../../components/PaymentArrangement/stepper';
import { Table, Form, Col, Button } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { 
    uploadPaForm,
} from '../../actions/paymentArrangementActions';
import { 
    showMessage
} from '../../actions/popupMessageActions';

class PrintSignSend extends Component {
    state = { 
        paFormData: [],
        isProcessing: false,
    }

    componentDidMount() {
        const {paFormData} = this.props.location.state;
        this.setState({
            paFormData: paFormData
        });
    }
    
    executeFileUpload = () => {
        window.onbeforeunload = function (event) {
            return true;
        };
        this.setState({
            isProcessing: true
        }, async () => {
            const {paFormData} = this.state;
            const pendingFormUpload = paFormData.filter(({customerContactStatus}) =>  customerContactStatus == '10').map(item => item);
            const emptyFiles = pendingFormUpload.filter(({fileData}) => fileData == '').map(item => item);
            console.log('asdsadasdsad paFormDatapaFormDatapaFormDatapaFormData',paFormData)
            if(emptyFiles.length != 0){
                this.props.showMessage(true, 'Please upload all of your signed \nPayment Arrangement Agreement form!')
                this.setState({
                    isProcessing: false
                })
            }
            else{
                let success = [], resultMsg = [];
                try {
                   
                    const uploadPaFormRequests = pendingFormUpload.map((item, index) => this.props.uploadPaForm(item)),
                        arrayOfResponse = await Promise.all(uploadPaFormRequests);

                    console.log('arrayOfResponse',arrayOfResponse);
                    arrayOfResponse.forEach( ({status}) => {
                        if ( status != undefined && status == 'Success' ){
                            success.push(true);
                        } 
                        else {
                            success.push(false);
                        }
                    });
        
                    if ( success.includes(false) ) {
                        this.props.showMessage(true, 'File upload failed');
                    }

                    this.props.showMessage(false, 'File(s) successfully uploaded! \n Redirecting to your dashboard.');
                    localStorage.setItem('isPAPending', false);
                    console.log('uploadPaForm results', { success, resultMsg });
                    localStorage.setItem('isPaymentArrangement', true);

                    window.onbeforeunload = function (event) {
                        return null;
                    };

                    setInterval(() => {
                        this.setState({displayDashboard: true});
                    }, 2000);
                    
                }
                catch (error) {
                    this.props.showMessage(true, 'Server Error! \n '+error);
                }
            }
        })
    } 

    saveFormFilePath = (e) => {
        let {paFormData} = this.state;
        console.log('dsadsadsadsadsadsadsa',e.target.files);
        for(let count=0; count<paFormData.length; count++){
            if(e.target.id == `file-${paFormData[count].accountId}` && paFormData[count].customerContactStatus == '10'){
                paFormData[count].fileName = e.target.files[0].name;
                paFormData[count].fileData = e.target.files[0];
                break;
            }
        }
        this.setState({
            paFormData: paFormData
        });
    }

    render() { 
        const {paFormData} = this.state;
        const {paAccountsResponse, caseData, accountSummary} = this.props.location.state;
        if(this.state.displayCaseForm) {
            return <Redirect to={{
                pathname: "/payment-arrangement/case-form", 
                state: {
                    caseData: caseData,
                    accountSummary: accountSummary,
                    paAccountsResponse: paAccountsResponse,
                    download: true
                }
            }} />
        }
        if(this.state.displayDashboard) {
            return <Redirect to='/dashboard' />
        }
        return (  
            <React.Fragment>
                <Header />
                <section id="payment-arrangement-print-sign-send" className="pt-5">
                    <Stepper />
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center mt-3">
                                <p className="font-bold m-0 secondary-font-color">Step 4:  PRINT, SIGN and SEND Agreement Form within Ten (10) Business Days</p>
                            </div>
                            <div className="col-lg-12 text-center mt-4">
                                <h5 className="font-bold">Your application WILL BE APPROVED UPON RECEIPT of your signed application.</h5>
                            </div>
                            {
                                paFormData.length > 5 ? 
                                <div className="col-lg-12 text-left mt-4">
                                    <p>
                                        Your case {paFormData.length > 1 ? 'numbers are:' : 'number is'} 
                                        <br /> 
                                        <div className="text-left">
                                            <ul style={{columns: '5'}}>
                                                {paFormData.map((item, index) => item.customerContactStatus == '10' ? <ol>{(index + 1)+') '+item.caseId}</ol> : null)}
                                            </ul> 
                                        </div>
                                        <br/> 
                                        A confirmation email was sent to {paAccountsResponse[0].emailAddress}, please contact us if you do not receive a confirmation email within 2 business days.</p>
                                </div>
                                :
                                <div className="col-lg-12 text-left mt-4">
                                    <p>
                                        Your case {paFormData.length > 1 ? 'numbers are' : 'number is'}  {paFormData.map((item, index) => item.customerContactStatus == '10' ? `${ item.caseId + ( (index != (paFormData.length-1)) ? ' ' : '') }` : '')}. A confirmation email was sent to {paAccountsResponse[0].emailAddress}, please contact us if you do not receive a confirmation email within 2 business days.</p>
                                </div>
                            }
                            <div className="col-lg-12 text-left mt-4">
                                <p>Please print and sign the Payment Arrangement Agreement form. (<a href="#" onClick={(e) => {e.preventDefault();this.setState({displayCaseForm: true});}}>Click here to download if the form does not pop-up automatically</a>).</p>
                            </div>
                            
                            <div className="col-lg-12 text-left mt-4">
                                <p className="font-extra-bold">You have Ten (10) Business days to send the signed document via:</p>
                                <ol>
                                    <li>Online upload (<a href="#" onClick={(e) => {e.preventDefault();window.scrollTo(0,document.body.scrollHeight);}}>Click here to upload signed document</a>)</li>
                                    <li>Post / Mail</li>
                                    <li>GPA Dropbox (Fadian, Julale, Upper Tumon)</li>
                                </ol>
                            </div>
                            <div className="col-lg-12 text-left mt-4">
                                <p><span className="font-bold">FAILURE TO COMPLETE</span> this step will <span className="font-bold">NULLIFY</span> your Payment Arrangement application and will cause the <u>entire arrangement balance to be included on your next electric statement</u>. Please do let us know if you find extreme difficulty accomplishing this task.</p>
                            </div>
                            <div className="col-lg-12 text-left mt-4">
                                <p>For questions, concerns, or <span className="font-bold">to cancel or make changes to your Payment Arrangement</span>, please call 647-5787/8/9 or send an email to <a href="mailto:customersfirst@gpagwa.com">customersfirst@gpagwa.com</a></p>
                            </div>
                            <div className="col-lg-12 text-center mt-4">
                                <h5 className="font-bold">Payment Arrangement Upload</h5>
                                <p className="mt-3">This upload accepts .pdf and most image type files. <br/>Be sure to include the barcode printed on document is part of your image.</p>
                            </div>
                            <div className="col-lg-6 text-center mt-4 mx-auto">
                                <Table id="payment-arrangement-step-table" bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Account ID</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            paFormData.map((item, index) => (
                                                item.customerContactStatus == '10' ?
                                                <tr>
                                                    <td style={{backgroundColor: '#fff'}}>
                                                        {item.accountId}
                                                    </td>
                                                    <td id="pa-file-upload-field" style={{backgroundColor: '#fff'}}>
                                                        <input type="text" value={item.fileName} />
                                                        <label for={`file-${item.accountId}`}>BROWSE</label>
                                                        <input id={`file-${item.accountId}`} type="file" onChange={this.saveFormFilePath.bind(this)}/>
                                                    </td>
                                                </tr>
                                                :
                                                null
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                            <div className="col-lg-12 text-center mt-4">
                                <button 
                                    className="btn pa-btn-primary"
                                    onClick={() => {this.executeFileUpload()}}
                                    disabled={this.state.isFetching}
                                >
                                    {this.state.isProcessing ? 'Please wait...' : 'Submit'}
                                </button>
                            </div>
                            <div className="col-lg-12 text-center mt-4 mb-5">
                                <p style={{cursor: 'pointer'}} onClick={() => {localStorage.setItem('isPAPending', true); this.setState({displayDashboard: true})}}>
                                    <u>Cancel</u> 
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    paymentArrangement: state.paymentArrangement
});

export default connect(mapStateToProps, {
    uploadPaForm, 
    showMessage
})(PrintSignSend);