import React, { Component } from 'react';
import { Modal, Table, Col, Container, Row, Form, Spinner, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { 
    getFilePath,
    updateFilePath
} from '../../actions/paymentArrangementActions';
import { 
    showMessage
} from '../../actions/popupMessageActions';

class PaymentArrangmentPath extends Component {
    state = {  
        currentFilePath: '',
        newFilePath: '',
        isUpdating: false
    }
    componentDidMount(){
        this.props.getFilePath()
        .then((response) => {
            this.setState({
                currentFilePath: response.data[0].path
            })
        })
    }
    onChangeNewFilePath = (e) => {
        this.setState({
            newFilePath: e.target.value
        })
    }
    onSubmitNewFilePath = () => {
        if(this.state.newFilePath != ''){
            this.setState({
                isUpdating: true
            }, () => {
                this.props.updateFilePath(this.state.newFilePath)
                .then((response) => {
                    this.props.getFilePath()
                    .then((response) => {
                        this.setState({
                            currentFilePath: response.data[0].path,
                            isUpdating: false
                        })
                        this.props.showMessage(false, 'File path successfully updated!')
                    })
                })
                .catch((error) => {
                    this.setState({
                        isUpdating: false
                    })
                    this.props.showMessage(true, 'Error occured on updating the file path. \n Please try again later!')
                })
            })
        }else{
            this.props.showMessage(true, 'Please enter a file path!')
        }
    }
    render() { 
        return (  
            <React.Fragment>
                <h5>Update Payment Arrangement File Path</h5>
                <p>Current File Path: <strong>{this.state.currentFilePath}</strong></p>
                <div className="search-container">
                  <div className="search-container-left">
                    <div className="input-cont">
                      <label>Enter new file path:</label><div className="input-right"><input type="text" autocomplete="off" name="accountId" onChange={this.onChangeNewFilePath.bind(this)} value={this.state.newFilePath}/></div>
                    </div>
                  </div>
                  <div className="button-inblock">
                    {
                      this.state.isUpdating ? 
                      <button
                          type="submit"
                          disabled
                          className="btn btn-change-ok font-bold "
                        >
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Loading...
                      </button>
                      :
                      <button
                          type="submit"
                          onClick={() => {this.onSubmitNewFilePath()}}
                          className="btn btn-change-ok font-bold btn-primary"
                        >
                          Submit
                      </button>
                    }
 
                  </div>
                </div>
            </React.Fragment>
        );
    }
}
 
const mapStateToProps = state => ({
    paymentArrangement: state.paymentArrangement
});

export default connect(mapStateToProps, { 
    getFilePath,
    updateFilePath,
    showMessage
})(PaymentArrangmentPath);