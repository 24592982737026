import React, { Component } from 'react';

class Header extends Component {

    render() { 
        return (  
            <header id="header-main">
                <nav className="navbar nav-ian primary-back-color">
                    <img className="navbar-brand mx-auto" src={process.env.PUBLIC_URL + '/images/GPA-Logo.png'} alt="gpa-logo"></img>
                </nav>
            </header>
        );
    }
}
 
export default Header;