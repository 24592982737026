import React, { Component } from 'react';
import {Bar} from 'react-chartjs-2';
import EditAccount from '../components/editAccount';
import LogoutButton from '../components/logoutButton';
import { connect } from 'react-redux';
import { 
    saveAccountId,
    savePremiseAddress,
    fetchMultipleAddOpptyRequest 
} from '../actions/dashboardActions';
import _ from 'lodash';
import Loading from './loading';
import { CRYPTR_KEY } from 'react-native-dotenv';

class AccountOverview extends Component {
    constructor(props) {
        super(props)
        this.showEdit = this.showEdit.bind(this);
        this.state = {
            userAccDetails: {
                personId: '',
                accountId: '',
                fullName: '',
                addressLine1: '',
                city: '',
                state: '',
                postal: '',
                country: '',
                homePhone: '',
                mobilePhone: '',
                workPhone: '',
                emailAddress: '',
            },
            userLatestBill: {
                billDate: '',
                totalAmount: '',
                dueDate: ''
            },
            consumptionViewBy: 'monthlyKWH',
            isHaveConsumption: false,
            kwhsOptions: {},
            amountsOptions: {},
            kwhsChartData:{},  
            amountsChartData: {},
            isEdit: false,  
            validated: false,
            selectedPremiseAddress:'',
            selectedAccountId: '',
            isLoading: true
        }
    }

    componentWillMount() {
        const Cryptr = require('cryptr');
        const cryptr = new Cryptr('GL!@mp0ʍƐ|2o2riTyU$ey');
        //session storage key search
        let sessionAccountId, sessionPersonId;
        for(var count = 0; count < Object.keys(localStorage).length; count++){
            if(Object.keys(localStorage)[count] === "baseUrl" || Object.keys(localStorage)[count] === "ruulzIndex"){
                
            }
            else {
                if(cryptr.decrypt(Object.keys(localStorage)[count]) === 'accountId'){
                    var accountId = [];
                    var arrAccountId = localStorage.getItem(Object.keys(localStorage)[count]).split(",")
                    accountId.push([cryptr.decrypt(arrAccountId[0]), cryptr.decrypt(arrAccountId[1])])
                    sessionAccountId = accountId
                }
                else if(cryptr.decrypt(Object.keys(localStorage)[count]) === 'accountIds'){
                    var evenElements = [], oddElements = [], accountIds = [];
                    var arrAccountIds = localStorage.getItem(Object.keys(localStorage)[count]).split(",")
                    for(var count = 0; count < arrAccountIds.length; count++){
                        if(count % 2 === 0){
                            evenElements.push(cryptr.decrypt(arrAccountIds[count]))
                        }
                        else{
                            oddElements.push(cryptr.decrypt(arrAccountIds[count]))
                        }
                    }
                    for(var count = 0; count < evenElements.length; count++){
                        accountIds.push([evenElements[count], oddElements[count]])
                    }
                    sessionAccountId = accountIds;
                }
            }
        }
        for(var count = 0; count < Object.keys(localStorage).length; count++){
            if(!(Object.keys(localStorage)[count] === "baseUrl" || Object.keys(localStorage)[count] === "ruulzIndex")){
                if(cryptr.decrypt(Object.keys(localStorage)[count]) === 'personId'){
                    sessionPersonId = cryptr.decrypt(localStorage.getItem(Object.keys(localStorage)[count]));
                }
            }
        }
        
        this.setState({
            ...this.state,
            selectedAccountId: this.props.dashboard.selectedAccountId,
            selectedPremiseAddress: this.props.dashboard.selectedPremAdd,
            userAccDetails: {
                ...this.state.userAccDetails,
                accountId: sessionAccountId,
                personId: sessionPersonId
            }
        },() => {
            const accountId = this.state.selectedAccountId
            const personId = sessionPersonId
            this.props.saveAccountId(accountId)
            this.props.fetchMultipleAddOpptyRequest(accountId, personId)
            .then((status) => {
                this.updateState(status)
                if(this.props.viewAccountInfo){
                    var elmnt = document.getElementById("info-wrap");
                    elmnt.scrollIntoView();
                }
            })
        })
    }
    
    updateState = (status) => {
        const consumptionDetails = this.props.dashboard.consumptionDetails;
        this.setState({
            ...this.state,
            consumptionViewBy: 'monthlyKWH',
            userAccDetails: {
                ...this.state.userAccDetails,
                fullName:     this.props.dashboard.userAccountDetails.fullName,
                addressLine1: this.props.dashboard.userAccountDetails.addressLine1,
                city:         this.props.dashboard.userAccountDetails.city,
                state:        this.props.dashboard.userAccountDetails.state,
                postal:       this.props.dashboard.userAccountDetails.postal,
                country:      this.props.dashboard.userAccountDetails.country,
                homePhone:    this.props.dashboard.userAccountDetails.homePhone,
                mobilePhone:  this.props.dashboard.userAccountDetails.mobilePhone,
                workPhone:    this.props.dashboard.userAccountDetails.workPhone,
                emailAddress: this.props.dashboard.userAccountDetails.emailAddress,
            },
            userLatestBill: {
                ...this.state.userLatestBill,
                billDate:    this.props.dashboard.latestBill.billDate,
                totalAmount: this.props.dashboard.latestBill.totalAmount,
                dueDate:     this.props.dashboard.latestBill.dueDate
            },
           
        }, () => {
            if(status !== "False"){
                this.setState({
                    ...this.state,
                    kwhsOptions: {
                        tooltips: {
                            callbacks: {
                                title: function(tooltipItem, data) {
                                    const startDate =  data['labels'][tooltipItem[0]['index']][0];
                                    const endDate =  data['labels'][tooltipItem[0]['index']][2];
                                    return startDate + ' - ' + endDate;
                                },
                            }
                        },
                        legend: {
                            display: false
                        },
                        scales: {
                            xAxes: [{
                                stacked: true,
                                gridLines: { display: false },
                                ticks: {
                                    fontFamily: "Montserrat",
                                    fontStyle: "bold",
                                    fontColor: '#333333',
                                    fontSize: 14,
                                }
                            }],
                            yAxes: [{
                                ticks: {
                                    steps: 7,
                                    // max: highestKwh,
                                    beginAtZero: true, 
                                    fontFamily: "Montserrat",
                                    fontStyle: "bold",
                                    fontColor: '#333333',
                                    fontSize: 14,
                                }
                            }]
                        },
                        responsive: true,
                    },
                    amountsOptions: {
                        tooltips: {
                            callbacks: {
                                title: function(tooltipItem, data) {
                                    const startDate =  data['labels'][tooltipItem[0]['index']][0];
                                    const endDate =  data['labels'][tooltipItem[0]['index']][2];
                                    return startDate + ' - ' + endDate;
                                },
                            }
                        },
                        legend: {
                            display: false
                        },
                        scales: {
                            xAxes: [{
                                stacked: true,
                                gridLines: { display: false },
                                ticks: {
                                    fontFamily: "Montserrat",
                                    fontStyle: "bold",
                                    fontColor: '#333333',
                                    fontSize: 14,
                                }
                            }],
                            yAxes: [{
                                ticks: {
                                    steps: 7,
                                    beginAtZero: true, 
                                    fontFamily: "Montserrat",
                                    fontStyle: "bold",
                                    fontColor: '#333333',
                                    fontSize: 14,
                                    userCallback: function(value, index, values) {
                                        return '$ ' + value;
                                    }
                                }
                            }]
                        },
                        responsive: true,
                    },
                    kwhsChartData:{
                        labels: this.props.dashboard.consumptionDetails.months,
                        datasets:[
                            {
                                label: 'Electric Consumption',
                                data: this.props.dashboard.consumptionDetails.totalKwhs,
                                backgroundColor:[
                                    '#00469e','#00469e','#00469e', '#00469e','#00469e','#00469e', '#00469e','#00469e','#00469e','#00469e','#00469e','#00469e',
                                ]
                            }
                        ],
                    },
                    amountsChartData:{
                        labels: this.props.dashboard.consumptionDetails.months,
                        datasets:[
                            {
                                label: 'Bill Amount',
                                data: this.props.dashboard.consumptionDetails.amounts,
                                backgroundColor:[
                                    '#00469e','#00469e','#00469e', '#00469e','#00469e','#00469e', '#00469e','#00469e','#00469e','#00469e','#00469e','#00469e',
                                ]
                            }
                        ],
                    }
                }, () => {
                    this.setState({
                        isHaveConsumption: true,
                        isLoading: false
                    })
                })
            }
            else{
                this.setState({
                    isLoading: false
                })
            }
            this.setState({
                isLoading: false
            })
        })
        
    }

    showEdit = e =>{
        e.preventDefault();
        this.setState({ isEdit: true })
    }

    onChangeSelect = (e) => {
        const selectedAccId   = e.target.value.split("@-@")[0] 
        const selectedPremAdd = e.target.value.split("@-@")[1] 
        const personId = this.state.userAccDetails.personId
        this.props.saveAccountId(selectedAccId)
        this.props.savePremiseAddress(selectedPremAdd)
        this.setState({
            ...this.state,
            selectedAccountId: selectedAccId,
            selectedPremiseAddress: selectedPremAdd,
            isLoading: true
        }, () => {
            this.props.fetchMultipleAddOpptyRequest(selectedAccId, personId)
            .then((status) => {
                this.updateState(status)
            })
            .catch((error)=>{
                if(!alert(error+'\n\nTry Again Later!')){
                    window.location.reload();
                }
            })
        })   
    }
    
    changeDataSet(e){
        this.setState({
            ...this.state,
            consumptionViewBy: e.target.value
        })
    }

    getAverage = () => {
        if(this.state.consumptionViewBy === 'monthlyBill'){
            const totalAmounts = this.props.dashboard.consumptionDetails.amounts
            let sum         = 0;
            let average     = 0;
            for(var count = 0; count < totalAmounts.length; count++){
                sum += parseInt(totalAmounts[count])                  
            }
            average = sum / totalAmounts.length
            return '$ '+String(Math.round(average))
        }
        else if(this.state.consumptionViewBy === 'monthlyKWH'){
            const totalKwhs = this.props.dashboard.consumptionDetails.totalKwhs
            let sum         = 0;
            let average     = 0;
            for(var count=0; count < totalKwhs.length; count++){
                sum += totalKwhs[count]                      
            }
            average = sum / totalKwhs.length
            return String(Math.round(average))+' KWH'
        }
    }
    
    render() { 
        //customer name modification
        var fullName   = this.state.userAccDetails.fullName;
        var firstName  = fullName.substr(fullName.indexOf(',')+1)
        var lastName   = fullName.substr(0,fullName.indexOf(','))
        if(lastName === ""){
            firstName  = fullName.substr(fullName.indexOf(' ')+1)
            lastName   = fullName.substr(0,fullName.indexOf(' '))
        }
        //user details
        const userAccountId     = this.state.userAccDetails.accountId;
        const selectedAccountId = String(this.state.selectedAccountId).substr(0, 10)+'';
        const userFullname      = firstName.substr(0,firstName.indexOf(' '))+' '+lastName;
        const addressLine1      = this.state.userAccDetails.addressLine1;
        const city              = this.state.userAccDetails.city;
        const state             = this.state.userAccDetails.state;
        const postal            = this.state.userAccDetails.postal;
        const country           = this.state.userAccDetails.country;
        const homePhone         = this.state.userAccDetails.homePhone;
        const mobilePhone       = this.state.userAccDetails.mobilePhone;
        const workPhone         = this.state.userAccDetails.workPhone;
        const emailAddress      = this.state.userAccDetails.emailAddress;
        const premiseAddress    = this.state.selectedPremiseAddress;
        //user latest bill
        const billDate    = this.state.userLatestBill.billDate;
        const totalAmount = this.state.userLatestBill.totalAmount;
        const dueDate     = this.state.userLatestBill.dueDate;
        
        return (
            this.state.isEdit === true ?
            <EditAccount accountId={selectedAccountId}/>
            :  
            this.state.isLoading === true ?
            <Loading />
            :
            <React.Fragment>
                <div id="welcome-wrap">
                    <div className="row pb-4">
                        <div className="col-lg-8">
                            <h3 className="primary-font-color">Hi {userFullname}!</h3>
                            <p className="font-bold">Welcome to your GPA member dashboard</p>
                        </div>
                        <div className="col-lg-4">
                            <LogoutButton />
                        </div>
                    </div>
                    <div className="row account-overview">
                        <div className="col-lg-12 bill-summary">
                            <div className="row">
                                <div className="col-lg-12 header">
                                    <p>Account Summary for Account  Number:</p>
                                    {
                                        (userAccountId.length > 1) ? 
                                        <select id="accountId" maxle onChange={this.onChangeSelect.bind(this)} defaultValue={selectedAccountId+ '@-@' +premiseAddress}>
                                            {
                                                _.map(userAccountId, (item, index) => {
                                                    return(
                                                        <option key={index} value={item[0] + '@-@' + item[1]}>
                                                        { 
                                                            (item[1] !== '') ? 
                                                                (item[0] + ' - ' + item[1]).substr(0, 42) + '...'
                                                            :
                                                            item[0]
                                                        }
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                        : 
                                        <input type="text" defaultValue={(userAccountId[0][0] + ' - ' + userAccountId[0][1]).substr(0, 46) + '...'} />
                                    }
                                </div>
                                <div className="col-lg-3 p-0"> 
                                    <div className="text-center border">
                                        <p>Bill Date:</p>
                                    </div>
                                    <div className="text-center border border-top-0">
                                        <h5>{billDate}</h5>
                                    </div>
                                </div>
                                <div className="col-lg-3 p-0">
                                    <div className="text-center border">
                                        <p>Total Amount Due:</p>
                                    </div>
                                    <div className="text-center border border-top-0">
                                        <h5 className="primary-font-color">$ {totalAmount}</h5>
                                    </div>
                                </div>
                                <div className="col-lg-3 p-0">
                                    <div className="text-center border">
                                        <p>Due Date:</p>
                                    </div>
                                    <div className="text-center border border-top-0">
                                        <h5>{dueDate}</h5>
                                    </div>
                                </div>
                                <div className="col-lg-3 p-0">
                                    <div className="text-center border pay-now">
                                        <a href="/paynow"><button type="submit" className="btn btn-primary regular-font-size font-bold">Pay Now</button></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                {this.state.isHaveConsumption === true ? 
                <div id="bar-chart-wrap" className="border">
                    <div className="row top">
                        <div className="col-lg-5 text-center">
                            <label className="primary-font-color font-bold">
                                {
                                    (this.state.consumptionViewBy === 'monthlyBill') ? 
                                    'Your Monthly Electricity Bills Chart' :
                                    (this.state.consumptionViewBy === 'monthlyKWH') ?
                                    'Your Monthly Electricity Consumption Chart' : ''
                                }
                            </label>
                        </div>
                        <div className="col-lg-5 text-center">
                            <p>View By:</p>
                            <select onChange={this.changeDataSet.bind(this)} defaultValue={this.state.consumptionViewBy}>
                                <option value="monthlyKWH">Monthly KWH Consumption</option>
                                <option value="monthlyBill">Monthly Bill Amount</option>
                            </select>
                        </div>
                    </div>
                    <div className="row bottom">
                        <div className="col-lg-1 graph-label">
                            <label className="font-bold">
                                {
                                    (this.state.consumptionViewBy === 'monthlyBill') ? 
                                    'USD' :
                                    (this.state.consumptionViewBy === 'monthlyKWH') ?
                                    'KWH' : ''
                                }
                            </label>
                        </div>
                        <div className="col-lg-8 scroll-hidden-mobile">
                            <div id="bar-scroll-mobile">
                                {
                                    <Bar
                                        data={  
                                                (this.state.consumptionViewBy === 'monthlyBill') ? 
                                                this.state.amountsChartData :
                                                (this.state.consumptionViewBy === 'monthlyKWH') ?
                                                this.state.kwhsChartData : ''
                                                }
                                        height={160}
                                        options={
                                                (this.state.consumptionViewBy === 'monthlyBill') ? 
                                                this.state.amountsOptions :
                                                (this.state.consumptionViewBy === 'monthlyKWH') ?
                                                this.state.kwhsOptions : ''
                                                }
                                        redraw
                                    />
                                }
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="text-center">
                                <label>
                                    {
                                        (this.state.consumptionViewBy === 'monthlyBill') ? 
                                        'Average Bill for 12 Months' :
                                        (this.state.consumptionViewBy === 'monthlyKWH') ?
                                        'Average Usage for 12 Months' : ''
                                    }
                                </label>
                                <h4>
                                    {
                                        this.getAverage()
                                    }
                                </h4>
                            </div>
                            
                        </div>
                        {/* <div className="content-right">
                        </div> */}
                    </div>
                </div>
                :
                <center>
                    <div className="p-5 m-5">
                        <h3>No consumption chart available</h3>
                    </div>
                </center>
                }
                <div id="absolute-holder">
                    <div id="info-wrap" className="row info-wrap">
                        <div className="col-lg-12">
                            <div className="account-info">
                                <div className="header">
                                    <label className="primary-font-color font-bold">Account Information</label>
                                    {/* <a href="/" className="secondary-font-color"><span><img src={process.env.PUBLIC_URL + '/images/add.png'} /></span> Add New Account</a> */}
                                    <a href="/" className="secondary-font-color" onClick={this.showEdit}><span><img src={process.env.PUBLIC_URL + '/images/edit-user.png'} alt="edit-user" /></span> Edit Account</a>
                                </div>
                                <div className="body">
                                    <p>Name: {userFullname}</p>
                                    <p>Account Number:  <strong>{selectedAccountId}</strong></p>
                                </div>
                            </div>
                            <br />
                            <div className="contact-info">
                                <div className="header">
                                    <label className="primary-font-color font-bold">Contact Information</label>
                                </div>
                                <div className="body">
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <p><strong>Premise Address</strong></p>
                                            <p>{premiseAddress}</p>
                                        </div>
                                        <div className="col-lg-3">
                                            <p><strong>Mailing Address</strong></p>
                                            <p>{addressLine1+' '+city+' '+state+' '+postal+' '+country}</p>
                                        </div>
                                        <div className="col-lg-3">
                                            <p><strong>Phone Numbers</strong></p>
                                            <p>Home: {homePhone}</p>
                                            <p>Mobile: {mobilePhone}</p>
                                            <p>Work: {workPhone}</p>
                                        </div>
                                        <div className="col-lg-3">
                                            <p><strong>Email Address</strong></p>
                                            <p>{emailAddress}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    dashboard: state.dashboard
});

export default connect(mapStateToProps, { 
    saveAccountId,
    savePremiseAddress,
    fetchMultipleAddOpptyRequest
})(AccountOverview);