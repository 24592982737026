import React, { Component } from 'react';
import Header from '../partials/header';
import Footer from '../partials/footer';

class Maintenance extends Component {
    state = {  }
    render() { 
        return ( 
            <React.Fragment>
                <Header />
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center mt-5 mb-5">
                                <h1>
                                    Sorry, but our site is currently <br /> undergoing maintenance.
                                </h1>
                                <p>
                                    Please try back in a few minutes.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </React.Fragment>
        );
    }
}
 
export default Maintenance;