import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { 
    fetchPaymentHistory 
} from '../actions/dashboardActions';
import _ from 'lodash';
import Loading from './loading';

import { 
    showMessage
} from '../actions/popupMessageActions';
import NumberFormat from 'react-number-format';

class ModalPaymentHistory extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
          show: false,
          isLoading: true
        };
    }
    showModal = (e) => {
        console.clear();
        this.setState({ show: true,isLoading: true }, () => {
            this.props.fetchPaymentHistory(this.props.accId)
            .then(() => {
                
                this.setState({ isLoading: false },()=>{
                    console.clear();
                    console.log("this.props.dashboard.payHistoryListthis.props.dashboard.payHistoryList",this.props.dashboard.payHistoryList)
                });
            })
            .catch((error)=>{
                this.props.showMessage(true, 'Server failed! Try again later!');
            })
        });
    }
    hideModal = (e) => {
        this.setState({ show: false });
    }
    formatDate = (date) => {
        let dateArray = date.split("-");
        let finalDate = dateArray[1]+'/'+dateArray[2]+'/'+dateArray[0]; //month, date, yr
        let digitMonth = new Date(Date.parse(finalDate)).getMonth()+1;
        digitMonth = digitMonth < 10 ? "0"+digitMonth : digitMonth;
        return digitMonth+'/'+dateArray[2]+'/'+dateArray[0];
    }
    render() { 
        return ( 
            <React.Fragment>
                <Modal
                    id="payment-history-modal"
                    show={this.state.show}
                    onHide={this.hideModal.bind(this)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    centered
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.isLoading === true ?
                            <center><h5>Please wait...</h5></center>
                            :
                            this.props.dashboard.payHistoryList === undefined || this.props.dashboard.payHistoryList.length <= 0 || this.props.dashboard.payHistoryList === null || this.props.dashboard.payHistoryList === "" ?
                                <React.Fragment>
                                    <center><h5>No Web Payments Made</h5></center>
                                    <br/>
                                </React.Fragment>
                            :
                            <React.Fragment>
                            <div className="my-bills-title" style={{margin: "0"}}>
                                <label className="light-font-color font-bold m-0">Payment History</label>
                            </div>
                            <div id="my-bills-table">
                                <div className="row">
                                    <div className="col-lg-12 mx-auto">
                                        <table className="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                <th scope="col" className="text-center">Payment Date</th>
                                                <th scope="col" className="text-center">Description</th>
                                                <th scope="col" className="text-center">Payment Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { 
                                                    _.map(this.props.dashboard.payHistoryList, (item, index) => {
                                                        return(
                                                            index < 18 ?
                                                                <tr key={index}>
                                                                    {/* <td>{(item.account_number)}</td> */}
                                                                    <td>{this.formatDate(item.payment_date)}</td>
                                                                    <td>{item.transType === "PS" ? "Payment" : "Payment Adjustment"}</td>
                                                                    <NumberFormat 
                                                                        value={parseFloat(item.billTotalAmount)} 
                                                                        displayType={'text'} 
                                                                        thousandSeparator={true} 
                                                                        prefix={'$ '} 
                                                                        decimalScale={2}
                                                                        fixedDecimalScale={true}
                                                                        renderText={value => <td className="text-right">{value}</td>} 
                                                                    />
                                                                </tr>
                                                            :
                                                                ""
                                                        )
                                                    })
                                                }    
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            </React.Fragment>
                        }
                    </Modal.Body>
                </Modal>
                <p style={{margin: "0"}} onClick={this.showModal.bind(this)}><img src={process.env.PUBLIC_URL + '/images/history-solid.svg'} width="15px"/> Payment History</p>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    dashboard: state.dashboard
});

export default connect(mapStateToProps, { 
    fetchPaymentHistory,  
    showMessage
})(ModalPaymentHistory);