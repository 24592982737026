import axios from 'axios';
import { 
    LOGIN_USER, 
    FETCH_ADDRESS_DETAILS, 
    FETCH_SECURITY_QUESTIONS, 
    FETCH_CONTACT_NUMBERS, 
    FETCH_BASIC_DETAILS, 
    FETCH_ACOV_ADDRESS_DETAILS, 
    FETCH_PREM_ADDRESS_DETAILS 
} from './types';

const baseURL = process.env.REACT_APP_LOGIN_SIGNUP_API_URL;

export const forgotPassword = (postData) => dispatch => {
    return new Promise((resolve, reject) => {
        axios
        .post(baseURL+'/api/v1/forgot-password',
        {
            accountId: postData.accountId,
            division: 'GPA',
            username: postData.username
        },
        {
            headers: { 'Content-Type':'application/json' }
        })
        .then(response => {
            resolve(response.data);
        })
        .catch( error => {
            resolve(error)
            reject(error);
        })
    })
}

export const forgotUsername = (postData) => dispatch => {
    return new Promise( (resolve, reject) => {
        axios
        .post(baseURL+'/api/v1/forgot-username',
            {
                accountId: postData.accountId,
                emailAddress: postData.emailAddress,
                division: 'GPA',
            },
            {
                headers: { 'Content-Type':'application/json' }
            })
            .then(response => {
                resolve(response.data);
            })
            .catch( error => {
                reject(error);
            })
    });
}

export const getOtherDetails = (personId) => dispatch => {
    return axios
    .post(baseURL+'/api/v1/other-details', 
        {
            transactionType: 'READ',
            PersonID: personId
        },
        {
            headers: {'Content-Type':'application/json'}
        })
        .then(response => {
            const result = response.data.result
            const custometDetails = response.data.result.customerDetails
            const basicDetails = {}, addressDetails = {}, contactNumbers = {}
            const strPostal = custometDetails.Postal || ''
            basicDetails.personId = custometDetails.PersonID || ''
            basicDetails.fullName = custometDetails.Name || ''
            basicDetails.emailAddress = custometDetails.EmailID || ''
            addressDetails.address1 = custometDetails.Address || ''
            addressDetails.city = custometDetails.City || ''
            addressDetails.country = custometDetails.Description || ''
            addressDetails.postal = strPostal.substr(0,strPostal.indexOf('-'))
            addressDetails.state = custometDetails.StateDescription || ''
            let resContactNumbers =  result.contactNumbers;
            for(var count=0; count < resContactNumbers.length; count++){
                if(resContactNumbers[count].PhoneType === 'MOBILE' && (resContactNumbers.mobilePhone === undefined || resContactNumbers.mobilePhone === "")){
                    contactNumbers.mobilePhone = resContactNumbers[count].PhoneNumber
                    contactNumbers.mobilePhoneSeq = resContactNumbers[count].Sequence
                }
                else if(resContactNumbers[count].PhoneType === 'HOME-PHONE' && (resContactNumbers.homePhone === undefined || resContactNumbers.homePhone === "")){
                    contactNumbers.homePhone = resContactNumbers[count].PhoneNumber
                    contactNumbers.homePhoneSeq = resContactNumbers[count].Sequence
                }
                else if(resContactNumbers[count].PhoneType === 'BUSN-PHONE' && (resContactNumbers.workPhone === undefined || resContactNumbers.workPhone === "")){
                    contactNumbers.workPhone = resContactNumbers[count].PhoneNumber
                    contactNumbers.workPhoneSeq = resContactNumbers[count].Sequence
                }
            }
            dispatch({
                type: FETCH_BASIC_DETAILS,
                payload: basicDetails
            })
            dispatch({
                type: FETCH_ADDRESS_DETAILS,
                payload: addressDetails
            })
            dispatch({
                type: FETCH_SECURITY_QUESTIONS,
                payload: response.data.result.securityQuestions
            })
            dispatch({
                type: FETCH_CONTACT_NUMBERS,
                payload: contactNumbers
            })
        }
    )
}

export const signUpUser = (postData) => dispatch => {
    return new Promise( (resolve, reject) => {
        axios
        .post(baseURL+'/api/v1/sign-up-user',
            {
                personId: postData.personId,
                first_name: postData.first_name,
                last_name: postData.last_name,
                IDNumber: postData.username,
                WebPassword: postData.password,
                emailAddress: postData.email_address,
                home: postData.home_number,
                mobile: postData.mobile_number,
                work: postData.work_number,
                CharacteristicValue: postData.security_question_val,
                Answer: postData.security_answer,
                isDeletedHome: postData.isDeletedHome,
                isDeletedMobile: postData.isDeletedMobile,
                isDeletedWork: postData.isDeletedWork,
                sequenceHome: postData.homeSeq,
                sequenceMobile:postData.mobileSeq,
                sequenceWork: postData.workSeq,
            },
            {
                headers: { 'Content-Type':'application/json' }
            })
            .then(response => {
                resolve(response.data);
            })
            .catch( error => {
                reject(error);
            })
    });
}

export const checkAccountNumber = (postData) => dispatch => {
    return new Promise( (resolve,reject) => {
        axios
        .post(
            baseURL+'/api/v1/check-account-number',
            {
                accountId: postData,
                division: 'GPA',
            },
            {
                headers: { 'Content-Type': 'application/json' }
            }
        )
        .then(function(response) {
            console.log('from check account number')
            console.log(response.data)
            resolve(response.data.result);
        })
        .catch( error => {
            reject(error);
        })
    });
}

export const checkUsername = (username) => dispatch => {
    return new Promise( (resolve,reject) => {
        axios
        .post(baseURL+'/api/v1/check-username' ,
            {
                username: username
            },
            {
                headers: { 'Content-Type': 'application/json' }
            }
        )
        .then(function(response) {
            resolve(response.data.result);
        })
        .catch( error => {
            reject(error);
        })
    });
}

export const loginUser = (postData) => dispatch => {
    localStorage.setItem('username', postData.username)
    return new Promise( (resolve,reject) => {
        axios
        .post(
            baseURL+'/api/v1/user-login',
            {
                webUserPersonIdTypeCode: 'WEB',
                userName: postData.username,
                password: postData.password,
                webAccessFlag: 'ALWD',
                wrongPasswordCountPersonCharTypeCode: 'WPCOUNT',
                cisDivision: 'GPA',
                wrongPasswordCountLimit: '5'
            },
            {
                headers: { 'Content-Type': 'application/json' }
            }
        )
        .then(function(response) {
            const postPaidAccounts = response.data.result.postpaid;
            const prePaidAccounts = response.data.result.prepaid;
            let isHavePrepaid = false, isHavePostpaid = false;
            try{
                localStorage.setItem('personId', postPaidAccounts[0].PersonID);
                isHavePostpaid = true;
            }catch(error){isHavePostpaid = false;}
            try{
                localStorage.setItem('personId', prePaidAccounts[0].PersonID);
                isHavePrepaid = true;
            }catch(error){isHavePrepaid = false;}
            localStorage.setItem("prepaid", isHavePrepaid);
            localStorage.setItem("postpaid", isHavePostpaid);
            localStorage.setItem("isPaymentArrangement", response.data.result.isPaymentArrngmnt)
            if(response.data.result.loginSuccessful !== 'false'){
                if(Array.isArray(response.data.result.payArrExistingIds)){
                    console.log('response.data.result.payArrExistingIds.length',response.data.result.payArrExistingIds.length)
                    const {payArrExistingIds} = response.data.result;
                    const isHavePending = payArrExistingIds.filter(({customerContactStatus}) => customerContactStatus == '10');
                    if(isHavePending.length > 0){
                        localStorage.setItem("isPAPending", true);
                    }else{
                        localStorage.setItem("isPAPending", false);
                    }
                }
                else{
                    if(response.data.result.payArrExistingIds.customerContactStatus == '10'){
                        localStorage.setItem("isPAPending", true)
                    }
                    else{
                        localStorage.setItem("isPAPending", false)
                    }
                }
                if(postPaidAccounts.length > 0){
                    if(postPaidAccounts.length > 1){
                        var postpaidAccountIds = []
                        for(var count = 0; count < postPaidAccounts.length; count++){
                            postpaidAccountIds.push([postPaidAccounts[count].AccountID, postPaidAccounts[count].PremiseInfo.replace(/,/g, ""), postPaidAccounts[count].customerClass])
                        }
                        localStorage.setItem('postpaidAccountIds', postpaidAccountIds)
                    }
                    else{
                        var postpaidAccountId = []
                        postpaidAccountId.push([postPaidAccounts[0].AccountID, postPaidAccounts[0].PremiseInfo.replace(/,/g, ""), postPaidAccounts[0].customerClass])
                        localStorage.setItem('postpaidAccountIds', postpaidAccountId)
                    }
                }
                if(prePaidAccounts.length > 0){
                    if(prePaidAccounts.length > 1){
                        var prepaidAccountIds = []
                        for(var count = 0; count < prePaidAccounts.length; count++){
                            prepaidAccountIds.push([prePaidAccounts[count].AccountID, prePaidAccounts[count].PremiseInfo.replace(/,/g, ""), prePaidAccounts[count].customerClass])
                        }
                        localStorage.setItem('prepaidAccountIds', prepaidAccountIds)
                    }
                    else{
                        var prepaidAccountId = []
                        prepaidAccountId.push([prePaidAccounts[0].AccountID, prePaidAccounts[0].PremiseInfo.replace(/,/g, ""), prePaidAccounts[0].customerClass])
                        localStorage.setItem('prepaidAccountIds', prepaidAccountId)
                    }
                }
                
            }
            resolve(response.data.result);
        })
        .catch( error => {
            reject(error);
        })
    });
}

export const getPremiseInfo = (accountId) => dispatch => {
    return new Promise((resolve,reject) => {
        axios
        .post(
            baseURL+'/api/v1/premise-info',
            {
                accountId: accountId,
            },
            {
                headers: { 'Content-Type': 'application/json' }
            }
        )
        .then(function(response) {
            const arrPremAddress = (response.data.result.PremiseInfo).split(",")
            const addressDetails = {
                address1: arrPremAddress[0],
                city: arrPremAddress[1].trim(),
                state: arrPremAddress[2].trim(),
                postal: arrPremAddress[3].trim(),
                country: "United States of America",
                fullName: response.data.result.Name
            }
            dispatch({
                type: FETCH_PREM_ADDRESS_DETAILS,
                payload: addressDetails
            })
            resolve("Success");
        })
        .catch( error => {
            reject(error);
        })
    });
}

export const getAcovInfo = (postData) => dispatch => {
    dispatch({
        type: FETCH_ACOV_ADDRESS_DETAILS,
        payload: postData
    })
}