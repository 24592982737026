import React, { Component } from 'react';
import { Form, Col, Button, Modal } from 'react-bootstrap';
import MyLoginDetails from './myLoginDetails';
import MyAccNumber from './myAccNumber';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { 
    validateZipCode,
} from '../actions/paynowActions';

class MyAccDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMessage: 'none',
            resultMessage: '',
            billingZipCode: '',
            isSuccess: false,
            isGoBack: false,
            validated: false,
            showModal: false,
            displayLogin: false,
            isValidating: false
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }
    componentWillMount() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    onFormSubmit = (e) => {
        this.setState({ showMessage: 'none', isValidating: true})
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({
                validated: true
            })
        }
        else{
            e.preventDefault();
            e.stopPropagation();
            this.props.validateZipCode(this.props.accountId, this.state.billingZipCode)
            .then((success) => {
                if(success){
                    const result = this.props.paynow.validateZipCodeRes.result
                    if(result.status === "Success"){
                        this.setState({
                            showModal: true,
                            isValidating: false
                        })
                    }else{
                        this.setState({ showMessage: 'block', resultMessage: result.details});
                        this.setState({
                            isValidating: false
                        })
                    }
                }
            })
            .catch((error) => {
                this.setState({ showMessage: 'block', resultMessage: "Server Error. Try again later!" });
                this.setState({
                    isValidating: false
                })
            })
        }
    }
    onModalSubmit = (e) => {
        this.setState({
            isSuccess: true
        })
    }
    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }
    showLogin = () => {
        this.setState({
            displayLogin: true,
        })
    }
    goBack = (e) => {
        this.setState({
            isGoBack: true
        })
    }
    handleClose() {
        this.setState({ showModal: false });
    }
    handleShow(e) {
        e.preventDefault();
        this.setState({ showModal: true });
    }
    render() { 
        let userAddressDetails = this.props.users.userAddressDetails, userPremAddressDetails = this.props.users.userPremAddressDetails, userAcovAddressDetails = this.props.users.userAcovAddressDetails,addressDetails;
        let fullName = "";
        if(this.props.billAddressSource === "PREM"){
            fullName  = userPremAddressDetails.fullName;
        }
        else {
            const userBasicInfo = this.props.users.userBasicInfo;
            fullName  = userBasicInfo.fullName;
        }

        // note masking --
        let firstName1 = fullName.substr(0,fullName.indexOf(' '))
        let lastName1  = fullName.substr(fullName.indexOf(' ')+1)
        if(fullName.includes(',')){
            firstName1 = fullName.substr(fullName.indexOf(',')+1)
            lastName1  = fullName.substr(0,fullName.indexOf(','))
        }
        
        if(firstName1.charAt(0).length > 0){
            firstName1 = firstName1.charAt(0).replace(/\s/g, '')+firstName1.slice(1);
        }

        const slice1FirstName = firstName1.slice(0, 2);
        const slice2FirstName = firstName1.slice(2).replace(/[\S]/g, "*");
        const slice1LastName = lastName1.slice(0, 2);
        const slice2LastName = lastName1.slice(2).replace(/[\S]/g, "*");
        const finalFullName =  slice1LastName+slice2LastName+', '+slice1FirstName+slice2FirstName;

        // --

        if(this.props.billAddressSource === "PER"){
            addressDetails = userAddressDetails.address1 + ", " + userAddressDetails.city + ", " + userAddressDetails.state + ", " + userAddressDetails.postal + ", " + userAddressDetails.country
        }
        else if(this.props.billAddressSource === "PREM"){
            addressDetails = userPremAddressDetails.address1 + ", " + userPremAddressDetails.city + ", " + userPremAddressDetails.state + ", " + userPremAddressDetails.postal + ", " + userPremAddressDetails.country
        }
        else if (this.props.billAddressSource === "ACOV"){
            addressDetails = userAcovAddressDetails.address1 + ", " + userAcovAddressDetails.city + ", " + userAcovAddressDetails.state + ", " + userAcovAddressDetails.postal + ", " + userAcovAddressDetails.country
        }
        const modalMessage = "";

        if(this.state.displayLogin) {
            return <Redirect to="/" />
        }
        return ( 
            (this.state.isSuccess === true) ? 
            <MyLoginDetails billAddressSource={this.props.billAddressSource} accountId={this.props.accountId}/>
            :
            (this.state.isGoBack === true) ? 
            <MyAccNumber />
            :
            <React.Fragment>
                <Modal show={this.state.showModal} onHide={this.handleClose.bind(this)} dialogClassName="premise-address-modal" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter"><h5 className="primary-font-color">Confirm Your Address</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="address-details-wrap">
                            <label>{addressDetails}</label>
                        </div>
                        <div className="modal-msg-wrap">
                            <label>Should the above address be incorrect, please contact our Customer Service Division at (671)647-5787/8/9 or let us know via email at <a href="mailto:paygpainfo@gpagwa.com">paygpainfo@gpagwa.com</a></label>
                        </div>
                        <div id="signup-form-buttons">
                            <div className="left">
                                <Button className="btn-back" onClick={this.handleClose.bind(this)}>Back</Button>
                                <Button className="btn-cancel" onClick={this.showLogin.bind(this)}>Cancel</Button>
                            </div>
                            <div className="right">
                                <Button className="btn-submit" onClick={this.onModalSubmit}>Yes, Proceed</Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <section id="sign-up-title">
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-lg-12 text-center">
                                <h4 className="primary-font-color">Create Account</h4>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="sign-up-stage">
                    <div className="container">
                        <div className="row justify-content-md-center rounded-left rounded-right border-style">
                            <div className="col-lg-4 p-0">
                                <div class="content done rounded-left">
                                    <label>
                                        <span><img src={process.env.PUBLIC_URL + '/images/sign-up-check.png'} alt="sign-up-check"/></span> 
                                        My Account Number
                                    </label>
                                </div>
                            </div>
                            <div className="col-lg-4 p-0">
                                <div class="content selected border-left-right">
                                    <label>
                                        <span><img src={process.env.PUBLIC_URL + '/images/sign-up-2-light.png'} alt="sign-up-2-light"/></span> 
                                        My Account Details
                                    </label>
                                </div>
                            </div>
                            <div className="col-lg-4 p-0">
                                <div className="content rounded-right">
                                    <span><img src={process.env.PUBLIC_URL + '/images/sign-up-3-dark.png'} alt="sign-up-3-dark"/></span> 
                                    <label className="dark-font-color">
                                        My Login Details
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="sign-up-form">
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-lg-12">
                                <div className="sign-up-result" style={{ display: this.state.showMessage }}>
                                    <div className="col-lg-12 text-center">
                                        <label className="secondary-font-color font-bold">{ this.state.resultMessage }</label>
                                    </div>
                                </div>
                                <div className="form-wrap">
                                    <Form 
                                        autocomplete="off"
                                        noValidate 
                                        validated={this.state.validated}
                                        onSubmit={this.onFormSubmit.bind(this)}
                                    >
                                        <Form.Row>
                                            <Form.Group as={Col} md="12" controlId="first-name">
                                                <Form.Label className="font-bold">Customer Name:</Form.Label>
                                                <Form.Control
                                                    className="form-input"
                                                    disabled    
                                                    type="text"
                                                    defaultValue={finalFullName}
                                                />
                                                <Form.Control.Feedback className="font-bold" type="invalid">Please enter your First Name!</Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} md="12" controlId="zip-code">
                                                <Form.Label className="font-bold" >Billing Zip Code:</Form.Label>
                                                <Form.Control
                                                    className="form-input"
                                                    required
                                                    name="billingZipCode"
                                                    type="text"
                                                    maxlength="5"
                                                    onChange={this.onChange.bind(this)}
                                                    defaultValue={this.state.billingZipCode}
                                                />
                                                <Form.Control.Feedback className="font-bold" type="invalid">Please enter your Zip Code!</Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                        <div id="signup-form-buttons">
                                            <div className="left">
                                                <Button className="btn-back" onClick={this.goBack.bind(this)}>Back</Button>
                                                <Button className="btn-cancel" onClick={this.showLogin.bind(this)}>Cancel</Button>
                                            </div>
                                            <div className="right">
                                                <Button className="btn-submit" type="submit" disable={this.state.isValidating}>{this.state.isValidating ? 'Verifying' : 'Continue'}</Button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="sign-up-form-bottom">
                    <div className="container">
                        <div className="row content-wrap">
                            <div className="col-lg-6 left">
                                <label>Fields marked as * are mandatory</label>
                            </div>
                            <div className="col-lg-6 right">
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    paynow: state.paynow,
    users: state.users
});

export default connect(mapStateToProps, { validateZipCode })(MyAccDetails);